export default function reduxPromiseMiddleware() {
  return (next) => (action) => {
    const { promise, type, ...rest } = action
    const logAndThrow = (error) => {
      if(error && error.stack){
        console.error(error.stack)
      }
      throw error
    }
    if (!promise) return next(action)
    next({ ...rest, payload: {requestPayload: rest.payload, request: promise}, type: `${type}.REQUEST` })
    return promise.then(
      (result) => {
        let payload = {requestPayload: rest.payload, result: result, request: promise}
        next({ ...rest, payload, type: `${type}.SUCCESS` })
        return result
      },
      (error)  => {
        let payload = {requestPayload: rest.payload, error: error, request: promise}
        next({ ...rest, payload,  type: `${type}.FAILURE` })
        logAndThrow(error)
      }
    ).catch(error => {
      logAndThrow(error)
    })
  }
}
