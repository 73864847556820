import React, {Component} from 'react'
import {connect} from 'react-redux'
import {SchemeActions, SnackbarActions} from 'actionsets'
import {Pagination, ErrorBanner} from 'components'
import Dependent from 'containers/shared/Dependent'
import LoadedCard from 'components/LoadedCard'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import SchemeIcon from '@material-ui/icons/Terrain'
import DeleteIcon from '@material-ui/icons/Delete'
import RequestApprovalIcon from '@material-ui/icons/AssignmentTurnedIn'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import Tooltip from '@material-ui/core/Tooltip'
import {compose, errorStringsFromError} from 'utils'
import {connectQueryString} from 'containers/shared'

export class List extends Component {

  constructor(props) {
    super(props)
    SchemeActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  dependsOn() {
    return this.loadSchemes()
  }

  loadSchemes = () => {
    return this.actions.index({
      page: this.props.page,
      fields: {schemes: 'name'}
    })
  }

  dependenciesMet() {
    return this.props.requests.length === 0
  }

  showScheme = id => () => {
    this.props.history.push(`/schemes/${id}`)
  }

  editScheme = id => event => {
    this.props.history.push(`/schemes/${id}/edit`)
    event.stopPropagation()
  }

  deleteScheme = id => event => {
    this.actions.destroy({id})
      .then(this.loadSchemes)
      .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  requestApproval = id => event => {
    this.props.history.push(`schemes/${id}/request_approval`)
    event.stopPropagation()
  }

  get schemes() {
    return this.props.schemes
  }

  handlePageChange = async page => {
    await this.props.onPageChange(page)
    this.loadSchemes()
  }

  get errors() {
    let errors = []
    if (this.props.errors.index) {
      errors = errors.concat(this.props.errors.index)
    }
    if (this.props.errors.destroy) {
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderSchemeListItem = ({id, name}) =>
    <Tooltip title="Edit scheme" key={`tooltip_${id}`}>
      <ListItem button onClick={this.showScheme(id)} key={id}>
        <ListItemIcon>
          <SchemeIcon/>
        </ListItemIcon>
        <ListItemText primary={name}/>
        <ListItemSecondaryAction>
          <Tooltip title="Go to Request approval page">
            <IconButton onClick={this.requestApproval(id)}><RequestApprovalIcon/></IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={this.deleteScheme(id)}><DeleteIcon/></IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    </Tooltip>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <PageContainer>
      <ActionHeader title='Schemes'>
        <Button variant='contained' color='primary' onClick={() => this.props.history.push('/schemes/new')}>
          Add
        </Button>
      </ActionHeader>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageChange={this.handlePageChange}
                  style={{}} linkStyle={{}}/>
      <LoadedCard>
        <MuiList dense>
          {this.schemes.map(this.renderSchemeListItem)}
        </MuiList>
      </LoadedCard>
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageChange={this.handlePageChange}
                  style={{}} linkStyle={{}}/>

    </PageContainer>
}

export default compose(
  Dependent({loader: true}),
  connectQueryString('schemes'),
  connect(({schemes}) => schemes)
)(List)
