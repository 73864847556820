import React, { Component } from 'react'
import { DatePicker as DPicker } from 'material-ui-pickers';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {theme} from 'styles'

export class DatePicker extends Component{

  handleChange = date => {
    this.props.onChange({
      target: {
        value: date ? date.format('YYYY-MM-DD hh:mm:ssZ') : date
      }
    })
  }


  get value(){
    return this.props.value ? new Date(this.props.value) : null
  }

  render = () => {
    const {minDate, maxDate, hideYear, ...props} = this.props
    return (
      <MuiThemeProvider theme={hideYear ? {...theme, overrides: {...theme.overrides, ...overrides}} : theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DPicker
            format="DD/MM/YYYY"
            {...props}
            onChange={this.handleChange}
            value={this.value}
            minDate={minDate || (hideYear && `${new Date(Date.now()).getFullYear()}-01-01`)}
            maxDate={maxDate || (hideYear && `${new Date(Date.now()).getFullYear()}-12-31`)}
            maxDateMessage={null}
            minDateMessage={null}
          />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    )
  }

}

const overrides = {
  MuiPickersToolbarButton: {
    toolbarBtn: {
      'h6&': {
        display: 'none'
      }
    }
  },
}

export default DatePicker