import React, {Component} from 'react'
import withStyles from 'styles'
import Button from '@material-ui/core/Button'

export class PromiseButton extends Component {

  state = {loading: false, transition: false, success: false, error: false}

  componentDidMount = () => {
    this._mounted = true
  }

  componentWillUnmount = () => {
    this._mounted = false
  }

  transition = () => {
    if (this._mounted && this.state.loading) {
      if (this.buttonRef)
        this.buttonRef.style.transition = 'background-color 2s'

      this.setState({transition: false}, () => {
        window.setTimeout(() => {
          if (this._mounted && this.state.loading) {
            this.buttonRef.style.transition = ''
            this.setState({transition: true})
            window.setTimeout(this.transition, 2000)
          }
        }, 50)
      })
    }
  }

  showStatus = (status) => {
    this.setState({[status]: true})
    setTimeout(() => {
      if (this._mounted)
        this.setState({[status]: false}, () => {
          this.buttonRef.style.transition = ''
        })
    }, 3000)
  }

  handleClick = async(event) => {
    this.setState({loading: true}, this.transition)
    try {
      await this.props.onClick(event)
      this.showStatus('success')
    }
    catch (error) {
      console.error(error)
      this.showStatus('error')
    }
    finally {
      this.setState({loading: false})
    }
  }

  render = () => {
    const {loading, transition, success, error} = this.state
    const {classes, className, ...props} = this.props
    return (
      <Button {...props} onClick={this.handleClick} className={classes(className, {loading, transition, success, error})} buttonRef={ref => this.buttonRef = ref} />
    )
  }

}

const styles = ({palette}) => ({
  loading: {
    pointerEvents: 'none',
    overflow: 'hidden',
    transition: 'transform 2s linear',
    '&::before': {
      content: "''",
      position: 'absolute',
      height: '100%',
      width: '400%',
      top: 0,
      right: 0,
      background: `linear-gradient(to right, ${palette.primary.main}, ${palette.primary.main} 25%, ${palette.secondary.main} 50%, ${palette.primary.main} 75%)`,

      transition: 'inherit',
    },
    '& span': {
      zIndex: 100,
    }
  },
  transition: {
    '&::before': {
      transform: 'translate(75%, 0)',
    }
  },
  success: {
    backgroundColor: `${palette.success.main} !important`,
  },
  error: {
    backgroundColor: `${palette.error.main} !important`,
  }
})

export default withStyles(styles, {name: 'PromiseButton'})(PromiseButton)