import React, {Component} from 'react'
import Select from 'components/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {connect} from 'react-redux'
import {SchemeActions} from 'actionsets'

export default ({path, include}) => Type => connect(({schemes: {schemes}}) => ({schemes}))(class extends Component{

  constructor(props) {
    super(props)
    SchemeActions.bindActions(this)
  }

  componentDidMount = () => {
    this.actions.index({include})
    this.redirectToDefaultScheme()
  }

  componentDidUpdate = () => {
    this.redirectToDefaultScheme()
  }

  redirectToDefaultScheme = () => {
    if (!this.schemeId && this.props.schemes.length === 1) {
      this.redirectToScheme(this.props.schemes[0])
    }
  }

  redirectToScheme = scheme => {
    this.props.history.push(`/schemes/${scheme.id}/${path}`)
  }

  handleChangeScheme = ({target: {value}}) => {
    this.redirectToScheme({id: value})
  }

  get schemeId() {
    return this.props.match.params.id
  }

  get scheme() {
    return this.props.schemes.find(scheme => scheme.id === this.schemeId) || {id: this.schemeId}
  }

  renderSelectScheme = () =>
    this.props.schemes.length > 1 &&
    <Select onChange={this.handleChangeScheme} label='Scheme' value={this.schemeId}
            className={this.props.classes && this.props.classes.schemeSelect}>
      {this.props.schemes.map(scheme =>
        <MenuItem key={scheme.id} value={scheme.id}>{scheme.name}</MenuItem>
      )}
    </Select>

  render = () => {
    const {schemes, ...props} = this.props
    return (
      <Type {...props} scheme={this.scheme} schemeSelect={this.renderSelectScheme()} />
    )
  }
})