import React, { Component } from 'react'
import withStyles from 'styles'
import {oneOf} from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Volume from 'components/units/Volume'
import {humanize} from 'utils'
import Timestamp from 'components/Timestamp'
import moment from 'moment'

class WaterLevel extends Component {
  static propTypes = {
    store: oneOf(['warehouse', 'accessible'])
  }

  get capacity() {
    return this.props.scheme[capacityKey[this.props.store]]
  }

  get level() {
    return this.props.scheme[levelKey[this.props.store]]
  }

  get normalWater() {
    if (normalWaterKey[this.props.store])
      return this.props.scheme[normalWaterKey[this.props.store]]

    return undefined
  }

  get percent() {
    if (this.capacity && (this.level || this.level === 0))
      return this.level * 100.0 / this.capacity
    else
      return this.level > 0 ? 100 : 0
  }

  get normalPercent() {
    return this.normalWater * 100.0 / this.capacity
  }

  get percentValueAlignment() {
    if (this.percent < 15)
      return 'left'
    if (this.percent > 85)
      return 'right'
    return ''
  }

  render = () => {
    const {classes, className} = this.props
    return (
      <div className={classes('root', className)}>
        <div className={classes.title}>
          <Typography variant="subtitle1">
            {humanize(this.props.store)} Water
            {this.props.scheme.entryDate ?
              <Typography varient='body2'>({moment(this.props.scheme.entryDate).format('DD/MM/YYYY')})</Typography>
            :
              <Timestamp timestamp={moment(this.props.scheme.timestamp).add(-1, 'day').endOf('day')} />
            }

          </Typography>
          {this.capacity &&
          <div className={classes.capacity}>
            <Typography variant="body1" className={classes.capacityTitle}>Capacity</Typography>
            <Volume>{this.capacity}</Volume>
          </div>
          }
        </div>
        <div className={classes.progressContainer}>
          <div className={classes.progress}>
            <span style={{width: `${this.percent}%`}}/>
            {!!this.normalWater &&
              <span style={{width: `${this.normalPercent}%`}} className={classes.normalWater}/>
            }
          </div>
          <span className={classes.pointer} style={{marginLeft: `${this.percent}%`}}/>
          <br/>
          <span className={`${classes.percentValue} ${this.percentValueAlignment}`}
                style={{marginLeft: `${this.percent}%`}}>
          <Volume>{this.level}</Volume>
        </span>
        </div>
      </div>
    )
  }
}

const capacityKey = {warehouse: 'maxWarehouseStoredWater', accessible: 'maxStoredWater'}
const levelKey = {warehouse: 'warehouseStoredWater', accessible: 'totalStoredWater'}
const normalWaterKey = {accessible: 'normalWater'}

const styles = ({palette}) => ({
  root: {
    marginBottom: 20,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
  },
  capacity: {
    textAlign: 'right',
    flex: 1,
  },
  capacityTitle: {
    color: palette.secondary.main,
    marginRight: 0,
    paddingBottom: 3,
    lineHeight: '1em'
  },
  progress: {
    height: 19,
    backgroundColor: '#F8F8F8',
    border: `1px solid ${palette.primary.main}`,
    display: 'inline-block',
    overflow: 'hidden',
    width: '100%',
    '& span': {
      transition: 'width 500ms',
      display: 'inline-block',
      height: '100%',
      backgroundColor: palette.primary.main,
      '&$normalWater': {
        backgroundColor: '#2d2b74'
      }
    }
  },
  progressContainer: {
    width: '100%',
  },
  pointer: {
    display: 'inline-block',
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderBottom: `8px solid ${palette.secondary.main}`,
    transform: 'translate(-50%, 0)',
    marginTop: 8,
    marginBottom: 3,
  },
  percentValue: {
    transition: 'margin 500ms',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    transform: 'translate(-50%, 0)',
    '&.left': {
      transform: 'translate(-8px, 0)',
    },
    '&.right': {
      transform: 'translate(calc(8px - 100%), 0)',
    }
  },
  normalWater: {}
})

export default withStyles(styles)(WaterLevel)
