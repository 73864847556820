import React, {Component} from 'react'
import withStyles from 'styles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import RightIcon from '@material-ui/icons/ChevronRight'
import ReactDOM from 'react-dom'
import HeaderLoader from 'components/HeaderLoader'
import {FormContext} from 'components'

export class CalendarForm extends Component {

  static defaultProps = {
    dateField: 'date'
  }

  constructor(props) {
    super(props)
    this.state = {
      centeredDate: moment(props.selectedDate),
      numberOfDaysShown: 5,
      translateX: -22.222222,
    }
    this.dateRefs = []
    this.requestedDates = []
  }

  get dates() {
    const results = []
    let requestDates = []
    const {dateField, minDate, maxDate} = this.props
    const {centeredDate} = this.state
    for (let i = -Math.floor(this.dayBufferSize / 2); i <= Math.floor(this.dayBufferSize / 2); i++) {
      const date = moment(centeredDate).add(i, 'd')
      const contextFromProps = (this.props.dateContexts || []).find(context => date.isSame(context[dateField], 'd'))
      const context = {...contextFromProps, [dateField]: moment(date), loading: !contextFromProps}
      if (!contextFromProps && (!minDate || !date.isBefore(minDate, 'd')) && (!maxDate || !date.isAfter(maxDate, 'd')))
        requestDates.push(date)
      results.push(context)
    }
    requestDates = requestDates.filter(date => !this.requestedDates.find(d => date.isSame(d, 'd')))
    if (requestDates.length > 0) {
      window.setTimeout(() => {
        this.props.onRequestDates(requestDates.map(date => date.format()))
      })
      this.requestedDates.push(...requestDates)
    }
    return results
  }

  get maxCenteredDate() {
    return moment(this.props.maxDate).subtract(this.daySlideAmount, 'd')
  }

  get minCenteredDate() {
    return moment(this.props.minDate).add(this.daySlideAmount, 'd')
  }

  get selectedContext() {
    const {dateContexts, selectedDate, dateField} = this.props
    return dateContexts.find(context => moment(selectedDate).isSame(context[dateField], 'd'))
  }

  get dayBufferSize() {
    return this.state.numberOfDaysShown + this.daySlideAmount * 2
  }

  get daySlideAmount() {
    return Math.floor(this.state.numberOfDaysShown / 2)
  }

  handleDateSelected = date => e => {
    this.props.onDateSelected && this.props.onDateSelected(date, e)
  }

  handleContextChange = context => {
    const {dateField} = this.props
    const dateContexts = this.props.dateContexts.map(c => moment(c[dateField]).isSame(context[dateField], 'd') ? context : c)
    this.props.onChange(dateContexts)
  }

  set transitionsEnabled(value) {
    const transitionValue = value ? '' : 'none'
    this.datesRef.style.transition = transitionValue
    this.dateRefs.forEach(ref => ref.style.transition = transitionValue)
  }

  handleShift = days => () => {
    this.setState({translateX: -(this.daySlideAmount + days) / this.dayBufferSize * 100}, () => {
      window.setTimeout(() => {
        this.transitionsEnabled = false
        this.setState({centeredDate: this.state.centeredDate.clone().add(days, 'd')}, () => {
          window.setTimeout(() => {
            this.transitionsEnabled = true
          }, 50)
        })
      }, 300)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!moment(this.props.selectedDate).isSame(prevProps.selectedDate, 'd') && Math.abs(this.state.centeredDate.diff(this.props.selectedDate, 'd')) > this.daySlideAmount) {
      this.setState({centeredDate: moment(this.props.selectedDate)})
    }

    if (prevState.numberOfDaysShown !== this.state.numberOfDaysShown || prevState.centeredDate !== this.state.centeredDate) {
      this.setState({translateX: -this.daySlideAmount / this.dayBufferSize * 100})
    }

    if (this.props.maxDate && this.state.centeredDate.isAfter(this.maxCenteredDate, 'd')) {
      this.setState({centeredDate: this.maxCenteredDate})
    }

    if (this.props.minDate && this.state.centeredDate.isBefore(this.minCenteredDate, 'd')) {
      this.setState({centeredDate: this.minCenteredDate})
    }

    if (!moment(this.props.selectedDate).isSame(prevProps.selectedDate, 'd')) {
      if (moment(this.props.selectedDate).diff(this.state.centeredDate, 'd') > this.daySlideAmount + 1)
        this.setState({centeredDate: moment(this.props.selectedDate)})
    }
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    const numberOfDaysShown = (this.domNode && this.domNode.offsetWidth < 750) ? 3 : 5
    if (numberOfDaysShown !== this.state.numberOfDaysShown) {
      this.setState({numberOfDaysShown})
    }
  }

  refHandler = (reactNode) => {
    this.domNode = reactNode && ReactDOM.findDOMNode(reactNode)
    this.handleResize()
  }

  renderContext = context => renderer =>
    (typeof renderer === 'function') ? renderer(context || {}) : renderer

  getSeasonName = date => {
    const seasons = this.props.seasons || []
    const season = seasons.find(season =>
      moment(date).isBetween(moment(season.startsOn).startOf('day'), moment(season.endsOn).endOf('day'))
    )
    return season && `${moment(season.startsOn).year()}-${moment(season.endsOn).year()}`
  }

  render = () => {
    this.dateRefs = []
    const {classes, selectedDate, dateField, dateSummary, children, maxDate, minDate, errorContext} = this.props
    const {translateX, centeredDate} = this.state

    return (
      <Card className={classes.root} ref={this.refHandler}>
        {(!minDate || this.minCenteredDate.isBefore(centeredDate, 'd')) &&
          <IconButton className={classes('arrowButton', 'leftButton')}
                      onClick={this.handleShift(-this.daySlideAmount)}><LeftIcon/></IconButton>
        }
        {(!maxDate || this.maxCenteredDate.isAfter(centeredDate, 'd')) &&
          <IconButton className={classes('arrowButton', 'rightButton')}
                      onClick={this.handleShift(this.daySlideAmount)}><RightIcon/></IconButton>
        }
        <div className={classes.dateWrapper}>
          <ul className={classes.dates} style={{transform: `translateX(${translateX}%`, width: `${this.dayBufferSize / this.state.numberOfDaysShown * 100}%`}}
              ref={ref => this.datesRef = ref}
          >
            {this.dates.map((context, i) =>
              <li key={moment(context[dateField]).format('YYYY-MM-DD')}
                  className={
                    classes({
                      date: true,
                      loading: !!context.loading,
                      selectedDate: moment(selectedDate).isSame(context[dateField], 'day'),
                      onSeason: !!this.getSeasonName(context[dateField]),
                      error: errorContext && !!errorContext[moment(context[dateField]).format('YYYY-MM-DD')]
                    })
                  }
                  onClick={this.handleDateSelected(context[dateField])} ref={ref => this.dateRefs[i] = ref}
              >
                <div className={classes.dateTitle}>
                  <Typography variant='body1' className={classes.dateText}>
                    {context[dateField].format('ddd DD MMM')}
                  </Typography>
                  <Typography variant='body2' className={classes.seasonText}>
                    {this.getSeasonName(context[dateField]) ? (
                      `${this.getSeasonName(context[dateField])} Season`
                    ) : (
                      [context[dateField].format('YYYY'), this.props.seasons && 'Off Season'].filter(t => t).join(' ')
                    )}

                  </Typography>
                </div>
                {context.loading ? <HeaderLoader /> : this.renderContext(context)(dateSummary)}
              </li>
            )}
          </ul>
        </div>
        <div className={classes.body}>
          <FormContext context={this.selectedContext} onChange={this.handleContextChange} errorContext={errorContext}>
            {this.renderContext(this.selectedContext)(children)}
          </FormContext>
        </div>
      </Card>
    )
  }
}

const styles = ({palette}) => ({
  root: {
    position: 'relative',
    overflow: 'visible',
    margin: '0 50px 0 40px',
    width: 'auto',
    '@media(min-width: 900px)': {
      margin: 0,
      marginRight: 10,
      width: 'auto'
    }
  },
  arrowButton: {
    position: 'absolute',
    top: 25,
  },
  leftButton: {
    left: -50,
  },
  rightButton: {
    right: -50
  },
  dateWrapper: {
    overflow: 'hidden',
    paddingBottom: 10,
    marginBottom: -10,
  },
  dates: {
    display: 'flex',
    margin: 0,
    padding: 0,
    transition: 'transform 300ms',
  },
  date: {
    flex: 1,
    padding: 6,
    display: 'block',
    position: 'relative',
    minHeight: 100,
    borderLeft: `1px solid rgba(180,180,180,0.3)`,
    backgroundColor: '#eee',
    transition: 'background-color 200ms, width 200ms, height 200ms, bottom 200ms',
    '&:not($selectedDate)': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#fafafa'
      }
    },
    '&$onSeason+&:not($onSeason), &:not($onSeason)+&$onSeason': {
      borderLeft: `1px solid ${palette.primary.main}`,
    },
    '&::after': {
      content: "''",
      position: 'absolute',

      bottom: -1,
      left: 'calc(50% - 5px)',
      width: 0,
      height: 0,
      transition: 'inherit',
      borderLeft: `1px solid ${palette.primary.main}`,
      borderBottom: `1px solid ${palette.primary.main}`,
      transform: 'rotate(-45deg)',
      backgroundColor: '#fff',
    },
    '@media(min-width: 600px)': {
      padding: 10,
    }
  },
  onSeason: {},
  loading: {
    textAlign: 'center',
    fontSize: '50px'
  },
  selectedDate: {
    backgroundColor: '#fff',
    '&::after': {
      width: 10,
      height: 10,
      bottom: -6,
    },
  },
  dateTitle: {
    display: 'block',
    textAlign: 'center',
    paddingBottom: 3,
    margin: '0 0 6px',
    borderBottom: '1px solid rgba(180,180,180,0.3)',
    whiteSpace: 'nowrap',
    '@media(min-width: 600px)': {
      fontSize: '1.125rem',
      paddingBottom: 5,
      margin: '0 0 10px',
    }
  },
  dateText: {
    fontSize: '1rem',
    marginRight: 0,
  },
  seasonText: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: '#666',
    marginRight: 0,
    '$onSeason &': {
      color: palette.primary.main
    }
  },
  body: {
    minHeight: 200,
    borderTop: `1px solid ${palette.primary.main}`,
    padding: '30px 10px 30px 20px'
  },
  error: {
    backgroundColor: '#FA0057',
    color: 'white',
    '&:not($selectedDate)': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#FA0057'
      }
    },
  }
})

export default withStyles(styles)(CalendarForm)
