import React, { Component } from 'react'
import Card from '@material-ui/core/Card';
import withStyles from 'styles'

export class CenteredCard extends Component{

  static defaultProps = {
    centerVertically: true
  }

  state = {mounted: false}

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({mounted: true})
    })
  }

  render = () =>
    <section className={this.props.classes('wrapper', {centerVertically: this.props.centerVertically, transition: !this.state.mounted})}>
      <div className={this.props.classes.logo} />
      <Card className={this.props.classes.card} style={this.props.style}>
        {this.props.children}
      </Card>
    </section>

}

const styles = ({palette}) => ({
  wrapper: {
    justifyContent: 'center',
    display: 'flex',
    background: `linear-gradient(135deg, ${palette.primary.main} 0%, ${palette.secondary.main} 50%, ${palette.secondary.dark} 100%)`,
    position: 'relative',
    '& $card': {
      transition: 'filter 1000ms',
    },
  },
  transition: {
    '& $card': {
      filter: 'opacity(0%)'
    },
  },
  centerVertically: {
    alignItems: 'center',
    minHeight: '100vh',
    flex: 1,
    '& $card': {
      '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
        //IE ONLY
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }
    }
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    minWidth: 300,
    margin: 0,
  },
  logo: {
    backgroundImage: 'url(/large_logo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',
    left: 20,
    top: 20,
    width: 180,
    height: 124,
    '&::after': {
      content: "'Stored\u00a0Water Information Management System'",
      display: 'block',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '22px',
      margin: '-6px 18px 0 200px',
      width: 0
    },
    '@media(min-width: 600px)': {
      left: 50,
      top: 50,
      width: 240,
      '&::after': {
        margin: '12px 22px 0 260px',
      }
    },
  }
})

export default withStyles(styles)(CenteredCard)
