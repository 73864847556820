import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { SchemeActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext }      from 'components'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Tooltip  from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/Settings'
import { compose } from 'utils'
import withStyles from 'styles'
import PageContainer from 'components/PageContainer'
import Table, {TableHead, TableBody, TableRow, TableCell} from 'components/CollapsibleTable'
import DatePicker from 'components/DatePicker'
import LoadedHeader from 'components/LoadedHeader'
import LoadedCard from 'components/LoadedCard'
import LoadedContent from 'components/LoadedContent'
import moment from 'moment'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    SchemeActions.bindActions(this)
  }

  fetchParams = {
    include: 'schemeSeasons'
  }

  get formObject(){
    return {...this.props.scheme, ...this.state.formAttributes}
  }

  configureSeason = (season) => {
    this.props.history.push(`/scheme_seasons/${season.id}/edit`)
  }

  render = () =>
    <PageContainer>
      <LoadedHeader name={this.formObject.name}>{this.editMode ? 'Edit Scheme' : 'Create Scheme'}</LoadedHeader>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        <LoadedContent>
          {this.renderErrorMessages()}
          <TextField fullWidth member='name'/>
          <DatePicker member='seasonStartsOnDefault' format="Do MMMM" hideYear />
          <DatePicker member='seasonEndsOnDefault' format="Do MMMM" hideYear/>
          {this.editMode &&
            <LoadedCard>
              {this.props.loaded &&
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Season</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell style={{width: 1}} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.formObject.schemeSeasons.map(season =>
                    <TableRow key={season.id}>
                      <TableCell mobileHeading>{moment(season.startsOn).year()}</TableCell>
                      <TableCell mobileLabel="Start Date">
                        {moment(season.startsOn) > moment() ?
                          <DatePicker member="nextSeasonStartsOn" format="Do MMMM YYYY" label="Start Date" fullWidth style={{marginBottom: 0}}/>
                          :
                          moment(season.startsOn).format("Do MMMM YYYY")
                        }
                      </TableCell>
                      <TableCell mobileLabel="End Date">
                        {moment(season.endsOn) > moment() ?
                          <DatePicker member="nextSeasonEndsOn" format="Do MMMM YYYY" label="End Date" fullWidth style={{marginBottom: 0}}/>
                          :
                          moment(season.endsOn).format("Do MMMM YYYY")
                        }
                      </TableCell>
                      <TableCell style={{width: 1, padding: 0}}>
                        <Tooltip title="Configure Season">
                          <IconButton onClick={() => this.configureSeason(season)}>
                            <SettingsIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              }
            </LoadedCard>
          }
        </LoadedContent>
        <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
      </FormContext>
    </PageContainer>
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
}

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({schemes}) => schemes),
)(Form)
