import React, {Component} from 'react'
import VolumeContext from './VolumeContext'
import withStyles from 'styles'
import {withContext} from 'utils/Context'

export class Volume extends Component {

  get displayValue() {
    const {children, native, display, units} = this.props
    if (!children && children !== 0) {
      return children
    }
    let convertedValue = Number(children) * units[native].factor / units[display].factor
    if (typeof units[display].dp === 'number')
      convertedValue = convertedValue.toFixed(units[display].dp)
    return convertedValue
  }

  get unit() {
    const {display, units} = this.props
    return units[display].display
  }

  render = () => {
    const {classes} = this.props
    return (
      <span className={classes.root}>
        {this.displayValue || '–'}
        {!!this.displayValue && <span className={classes.unit}>{this.unit}</span>}
      </span>
    )
  }
}

const styles = ({palette}) => ({
  root: {
    whiteSpace: 'nowrap',
    '& + $root': {
      marginLeft: 20,
    }
  },
  unit: {
    opacity: 0.8,
    fontSize: '0.8em'
  }
})

export default withContext(VolumeContext)(withStyles(styles)(Volume))