import React, {Component} from 'react'
import {connect} from 'react-redux'
import {TokenActions, SnackbarActions} from 'actionsets'
import {Link} from 'react-router-dom'
import {FormContext, CenteredCard, ErrorBanner} from 'components'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'

export class Forgot extends Component {

  constructor(props) {
    super(props)
    TokenActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {}

  submit = async () => {
    this.setState({submitting: true})
    await this.actions.forgot(this.state)
    this.actions.snackbar.show("Request Sent")
    this.setState({submitting: false})
  }

  render = () =>
    <CenteredCard>
      <FormContext context={this.state} onChange={state => this.setState(state)} onSubmit={this.submit}>
        <CardContent>
          <Typography variant='h6'>Forgot your password?</Typography>
          {this.props.error && <ErrorBanner>{this.props.error.title}</ErrorBanner>}
          <FormGroup>
            <TextField fullWidth member='email' autoFocus={true}/>
          </FormGroup>
        </CardContent>
        <CardActions>
          <Button variant='contained' color='secondary' fullWidth disabled={this.state.submitting}
                  type='submit'>Submit</Button>
        </CardActions>
        <Link className='link-small' to='/tokens/sign_in'>Sign in</Link>
      </FormContext>
    </CenteredCard>
}

export default connect(({tokens}) => tokens)(Forgot)
