import React, { Component } from 'react'
import { connect }          from 'react-redux'
import {IrrigatorActions, SchemeActions} from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import {FormContext, Select} from 'components'
import PageContainer        from 'components/PageContainer'
import LoadedHeader         from 'components/LoadedHeader'
import LoadedContent        from 'components/LoadedContent'
import TextField            from '@material-ui/core/TextField'
import { compose }          from 'utils'
import withStyles           from 'styles'
import MenuItem             from '@material-ui/core/MenuItem'
import PromiseButton from 'components/PromiseButton'
import PrioritiesTable from 'components/PrioritiesTable'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    IrrigatorActions.bindActions(this)
    SchemeActions.bindActions(this, 'schemes')
  }

  fetchParams = {
    include: 'priorities'
  }

  async dependsOn(){
    await Promise.all(
      [
        this.actions.schemes.index(),
        super.dependsOn()
      ]
    )
  }

  get formObject(){
    return {...this.props.irrigator, ...this.state.formAttributes}
  }

  render = () =>
    <PageContainer>
      <LoadedHeader name={this.formObject.name}>
        {this.editMode ? `Edit Irrigator` : 'New Irrigator'}
      </LoadedHeader>
      <LoadedContent>
        <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
          {this.renderErrorMessages()}
          <TextField member='name'/>
          <TextField member='code'/>
          <Select member='schemeId' label='Scheme'>
            {this.props.schemes.map(({id, name}) =>
              <MenuItem key={id} value={id}>{name}</MenuItem>)
            }
          </Select>
          <PrioritiesTable member="priorities" errors={this.errorContext} />
          <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
        </FormContext>
      </LoadedContent>
    </PageContainer>
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
}

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({irrigators, schemes}) => ({...schemes, ...irrigators})),
)(Form)
