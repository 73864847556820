import React, {Component} from 'react'
import withStyles from 'styles'
import DataList, {DataValue} from 'components/DataList'
import {humanize} from 'utils'
import Volume from 'components/units/Volume'

class Balances extends Component {
  render = () => {
    const {classes, className, irrigator} = this.props
    return (
      !(irrigator.accounts && irrigator.accounts.length)  ? null :
      <table className={classes('root', className)}>
        <tbody>
          <tr>
            {irrigator.accounts.map(account =>
              <th key={account.id}>{humanize(account.accountType)} ({account.priority})</th>
            )}
            <th>Total</th>
          </tr>
          <tr>
            {irrigator.accounts.map(account =>
              <td key={account.id}>
                <DataList direction='vertical'>
                  <DataValue title='Contracted'><Volume>{account.contractedBalance}</Volume></DataValue>
                  <DataValue title='Available'><Volume>{account.availableBalance}</Volume></DataValue>
                  <DataValue title='Released'><Volume>{account.seasonRelease}</Volume></DataValue>
                </DataList>
              </td>
            )}
            <td>
              <DataList direction='vertical'>
                <DataValue title='Contracted'>
                  <Volume>
                    {irrigator.accounts.reduce((sum, account) => sum + account.contractedBalance, 0)}
                  </Volume>
                </DataValue>
                <DataValue title='Available'>
                  <Volume>
                    {irrigator.accounts.reduce((sum, account) => sum + account.availableBalance, 0)}
                  </Volume>
                </DataValue>
                <DataValue title='Released'>
                  <Volume>
                    {irrigator.accounts.reduce((sum, account) => sum + account.seasonRelease, 0)}
                  </Volume>
                </DataValue>
              </DataList>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}

const styles = {
  width: '100%',
  root: {
    '& th': {
      textAlign: 'left',
      verticalAlign: 'bottom',
      width: '25%',
      fontSize: '1.125rem',
      paddingBottom: 7,
      paddingRight: '5%'
    },
    '& td': {
      width: '25%',
    }
  }
}

export default withStyles(styles)(Balances)
