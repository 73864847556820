import ActionSet from './ActionSet'
import {TokenActions} from './'
import * as Actions from '.'

export class ResetActionSet extends ActionSet{

  static initialState = {}

  static resetState(creator, reducer, constants){
    creator(() => dispatch => {
      dispatch({type: constants.ACTION})
      Object.values(Actions).forEach(action => {
        if (action !== TokenActions && action.constructor !== ResetActionSet)
          action.resetState && dispatch(action.resetState())
      })
    })

    reducer({})
  }
}

export default new ResetActionSet()