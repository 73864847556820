import { ProtectedJsonAPI } from '.'

export const IrrigatorDailyEntries = ProtectedJsonAPI.extend(
  '/irrigator_daily_entries',
  {
    index:   endpoint => endpoint,
    create:  endpoint => endpoint.method('post'),
    update:  endpoint => endpoint.method('put').path(({id}) => id),
    bulkUpdate:  endpoint => endpoint.method('put'),
    destroy: endpoint => endpoint.method('delete').path(({id}) => id),
    show:    endpoint => endpoint.path(({id}) => id)
  }
)