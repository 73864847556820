import React, { Component } from 'react'
import { SnackbarActions } from 'actionsets'
import { connect } from 'react-redux'
import MuiSnackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

export class Snackbar extends Component{

  state = {
    visible: false
  }

  constructor(props){
    super(props)
    SnackbarActions.bindActions(this)
  }

  render = () =>
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={!!this.props.message}
      autoHideDuration={3000}
      onClose={this.actions.clear}
      message={<span id="message-id">{this.props.message}</span>}
      action={
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={this.actions.clear}
        >
          <CloseIcon />
        </IconButton>
      }
    />
}

export default connect(({snackbar}) => snackbar)(Snackbar)