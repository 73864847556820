import { ProtectedJsonAPI } from '.'

export const Schemes = ProtectedJsonAPI.extend(
  '/schemes',
  {
    index:       endpoint => endpoint,
    create:      endpoint => endpoint.method('post'),
    update:      endpoint => endpoint.method('put').path(({id}) => id),
    destroy:     endpoint => endpoint.method('delete').path(({id}) => id),
    dailyChange: endpoint => endpoint.path(({id}) => `${id}/daily_change/`),
    latestDaily: endpoint => endpoint.path(({id}) => `${id}/latest_daily/`),
    show:        endpoint => endpoint.path(({id}) => id),
    dailySummaries: endpoint => endpoint.path(({id}) => `${id}/daily_summaries`),
  }
)