import React, { Component } from 'react'
import { connect } from 'react-redux'
import { WashupEntryActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import FileIcon from '@material-ui/icons/InsertDriveFile'
import LoadedCard from 'components/LoadedCard'
import { compose, errorStringsFromError, userFriendlyDate } from 'utils'
import { connectQueryString } from 'containers/shared'
import Table, {TableHead, TableBody, TableRow, TableCell} from 'components/CollapsibleTable'
import withStyles from 'styles'
import {APIResource} from "../../services";

export class List extends Component{

  constructor(props){
    super(props)
    WashupEntryActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  dependsOn(){
    return this.loadWashupEntries()
  }

  loadWashupEntries = () => {
    return this.actions.index({page: this.props.page, include: 'scheme'})
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  deleteWashupEntry = id => event => {
    this.actions.destroy({id})
                .then(this.loadWashupEntries)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get washupEntries(){
    return this.props.washupEntries
  }

  handlePageChange = async page =>{
    await this.props.onPageChange(page)
    this.loadWashupEntries()
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  handleDownload = url => () =>
    window.open(`${APIResource.DEFAULT_BASE}${url}`, "_blank")

  render = () =>
    <PageContainer>
      <ActionHeader title="Washup Files">
        <Button color="primary" variant="contained" onClick={() => this.props.history.push('/washup_entries/new')}>Add</Button>
      </ActionHeader>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageChange={this.handlePageChange} style={{}} linkStyle={{}}/>
      <LoadedCard>
        {this.props.washupEntries &&
              <Table className={this.props.classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Filename</TableCell>
                    <TableCell>Scheme</TableCell>
                    <TableCell>Uploaded At</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.washupEntries.map(washupEntry =>
                    <TableRow className={this.props.classes.row} key={washupEntry.id}>
                      <TableCell mobileHeading><FileIcon/>  {washupEntry.washupFileName}</TableCell>
                      <TableCell mobileLabel="Scheme">{washupEntry.scheme && washupEntry.scheme.name}</TableCell>
                      <TableCell mobileLabel="Uploaded At">{userFriendlyDate(washupEntry.createdAt)}</TableCell>
                      <TableCell mobileLabel="Download" style={{padding: 0}}>
                        <IconButton onClick={this.handleDownload(washupEntry.washupFileUrlPath)}>
                          <DownloadIcon/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              }
      </LoadedCard>
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageChange={this.handlePageChange} style={{}} linkStyle={{}}/>
    </PageContainer>
}

const styles = {
  table: {
    '& svg': {
      color: 'rgba(0, 0, 0, 0.54)',
      verticalAlign: 'middle'
    },
    '& td,th': {
      borderLeft: 'none !important'
    },
    '& > thead > tr': {
      height: 'auto',
      backgroundColor: '#fff',
      '& > th': {
        paddingBottom: '7px',
        fontSize: '0.75rem'
      }
    },
  }
}

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connectQueryString('washupEntries'),
  connect(({washupEntries}) => washupEntries)
)(List)
