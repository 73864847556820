import React, {Component, Fragment} from 'react'
import withStyles from 'styles'
import Typography from '@material-ui/core/Typography'
import ExpandIcon from '@material-ui/icons/ExpandMore'
import ExpandButton from 'components/ExpandButton'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import ResizeCard from 'components/ResizeCard'
import Volume from 'components/units/Volume'
import Table, {TableBody, TableCell, TableHead, TableRow} from 'components/CollapsibleTable'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'

class ApprovalHistory extends Component {
  get dailyEntriesByDate() {
    const {irrigators, irrigatorDailyEntries} = this.props
    const entries = irrigatorDailyEntries.map(entry =>
      ({...entry, irrigator: irrigators.find(ir => `${ir.id}` === `${(entry.irrigator || {}).id}`)})
    )
    const entriesByDate = []
    entries.forEach(entry => {
      let entryDate = entriesByDate.find(({date}) => moment(date).isSame(entry.entryDate, 'd'))
      if (!entryDate) {
        entryDate = {date: entry.entryDate, entries: []}
        entriesByDate.push(entryDate)
      }
      entryDate.entries.push(entry)
    })
    return entriesByDate.filter(({date, entries}) => {
      const irrigatorCount = irrigators.filter(({createdAt}) => !moment(createdAt).isAfter(date, 'd')).length
      return entries.length >= irrigatorCount && !moment(date).isAfter(moment(), 'd')
    })
  }

  handleMore = () => {
    const {dailyEntriesByDate} = this
    const lastDate = dailyEntriesByDate[dailyEntriesByDate.length - 1].date
    this.props.onMore(moment(lastDate).subtract(1, 'd'))
  }

  render = () => {
    const {classes, className, expanded, onExpand} = this.props
    return (
      <div className={classes(className, 'root')}>
        <Typography variant='h6'>
          <ExpandButton expanded={expanded} onClick={onExpand} />
          History
        </Typography>
        <Collapse in={expanded}>
          <div className={classes.tableWrapper}>
            <ResizeCard className={classes.tableCard}>
              <Table breakpoint={300} className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Irrigator</TableCell>
                    <TableCell>Request</TableCell>
                    <TableCell>Approved</TableCell>
                    <TableCell>Actual</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.dailyEntriesByDate.map(({date, entries}) =>
                    <Fragment key={date}>
                      {entries.map((entry, i) =>
                        <TableRow key={entry.id}>
                          {i === 0 &&
                            <TableCell mobileHeading rowSpan={entries.length}>
                              {moment(entry.entryDate).format('DD MMM')}
                            </TableCell>
                          }
                          <TableCell mobileLabel="Request">{(entry.irrigator || {}).name}</TableCell>
                          <TableCell mobileLabel="Request"><Volume>{entry.requestedRelease}</Volume></TableCell>
                          <TableCell mobileLabel="Confirmed"><Volume>{entry.confirmedRelease}</Volume></TableCell>
                          <TableCell mobileLabel="Actual"><Volume>{entry.actualRelease}</Volume></TableCell>
                          <TableCell mobileLabel="Status"><Tooltip title={entry.releaseStatusText}><span>{entry.releaseStatus || '–'}</span></Tooltip></TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  )}
                </TableBody>
              </Table>
            </ResizeCard>
            <Button color='primary' onClick={this.handleMore}><ExpandIcon/> more</Button>
          </div>
        </Collapse>
      </div>
    )
  }

}

const styles = {
  root: {
    maxWidth: 1000
  },
  tableWrapper: {
    textAlign: 'center'
  }
}

export default withStyles(styles)(ApprovalHistory)