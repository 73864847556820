import React, { Component } from 'react'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import {SchemeSeasonActions} from 'actionsets'
import LoadedHeader from 'components/LoadedHeader'
import LoadedContent from 'components/LoadedContent'
import {FormContext} from 'components'
import MultiSelect from 'components/MultiSelect'
import PromiseButton from 'components/PromiseButton'
import PageContainer from 'components/PageContainer'
import moment from 'moment'
import {compose} from 'utils'
import Dependent from 'containers/shared/Dependent'
import {connect} from 'react-redux'
import {humanize, basicComparison} from 'utils'
import {Switch, Collapse, Typography} from '@material-ui/core'
import withStyles from 'styles'

class Form extends InstanceFormMixin(Component){
  constructor(props){
    super(props)
    SchemeSeasonActions.bindActions(this)
  }

  fetchParams = {
    include: 'scheme.irrigators.accounts,contractedEntitlementIrrigator'
  }

  get formObject(){
    return {...this.props.schemeSeason, ...this.state.formAttributes}
  }

  get accounts() {
    const {scheme} = this.props.schemeSeason
    if (!scheme)
      return []
    return scheme.irrigators.map(i =>
      i.accounts.map(a => ({...a, name: `${humanize(a.accountType)} – ${i.code}`}))
    ).flat().sort((a, b) => basicComparison(a.accountType, b.accountType))
  }

  get entitlementIrrigator() {
    return this.props.schemeSeason.contractedEntitlementIrrigator
  }

  getAccountIds(type) {
    return this.accounts.filter(a =>
      a.accountType === type && !this.entitlementIrrigator?.accounts.some(({id}) => id === a.id)
    ).map(a => a.id)
  }

  handleContractChange = ({target: {checked}}) => {
    this.handleFormObjectChange({
      useContractedEntitlement: checked,
      ...checked && {
        ...!(this.formObject.contractedAccountIds || []).length && { contractedAccountIds: this.getAccountIds('ctop') },
        ...!(this.formObject.contractedReleaseAccountIds || []).length && { contractedReleaseAccountIds: this.getAccountIds('refill') },
      }
    })
  }

  render = () => {
    const {classes} = this.props
    const {startsOn, endsOn} = this.formObject
    return (
      <PageContainer>
        <LoadedHeader name={`${moment(startsOn).year()}-${moment(endsOn).year()}`}>
          Configure Season
        </LoadedHeader>
        <LoadedContent>
          <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
            {this.renderErrorMessages()}
            <Typography variant="body1" style={{marginLeft: -12}} paragraph>
              <Switch member="useContractedEntitlement" onChange={this.handleContractChange} color="primary" type="checkbox"/>Use RRIA Entitlement
            </Typography>
            <Collapse in={this.formObject.useContractedEntitlement}>
              <div className={classes.accountsContainer}>
                <div>
                  <Typography variant="body1">CW on Contract</Typography>
                  <MultiSelect className={classes.accountSelect} member="contractedAccountIds"
                               options={this.accounts} equalityProvider={(a, b) => `${a}` === `${b}`} />
                </div>
                <div>
                  <Typography variant="body1" style={{whiteSpace: 'nowrap'}}>CW on Release</Typography>
                  <MultiSelect className={classes.accountSelect} member="contractedReleaseAccountIds"
                               options={this.accounts} equalityProvider={(a, b) => `${a}` === `${b}`} />
                </div>
              </div>
            </Collapse>
            <br/>
            <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
          </FormContext>
        </LoadedContent>
      </PageContainer>
    )
  }
}

const styles = {
  accountSelect: {
    '& li': {
      width: '100%',
      whiteSpace: 'nowrap'
    }
  },
  accountsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > div': {
      flex: '1 0 50%',
      paddingRight: 20,
      paddingBottom: 20,
      boxSizing: 'border-box'
    }
  }
}

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({schemeSeasons}) => ({...schemeSeasons})),
)(Form)
