import React, { Fragment, Component } from 'react'
import { connect }          from 'react-redux'
import { UserActions, TokenActions, SchemeActions, IrrigatorActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext, Select }      from 'components'
import Typography from '@material-ui/core/Typography'
import PromiseButton from 'components/PromiseButton'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { compose } from 'utils'
import withStyles from 'styles'
import MenuItem from '@material-ui/core/MenuItem';
import { Authorization } from 'utils'
import PageContainer from 'components/PageContainer'
import LoadedHeader from 'components/LoadedHeader'
import Collapse from '@material-ui/core/Collapse'
import LoadedContent from 'components/LoadedContent'
import PasswordStrength from 'components/PasswordStrength'
import MultiSelect from 'components/MultiSelect'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    UserActions.bindActions(this)
    TokenActions.bindActions(this, 'tokens')
    SchemeActions.bindActions(this, 'schemes')
    IrrigatorActions.bindActions(this, 'irrigators')
  }

  fetchParams = {
    include: 'schemeRoles'
  }

  get formObject(){
    return {...this.props.user, ...this.state.formAttributes}
  }

  dependsOn() {
    return Promise.all([
      super.dependsOn(),
      this.actions.schemes.index(),
      this.actions.irrigators.index()
    ])
  }

  afterSave = () => {
    if (this.objectId === Authorization.userId) {
      this.actions.tokens.verify()
    }
  }

  get schemeRoleOptions() {
    return this.props.schemes.map(({id, name}) => ({
      schemeId: id,
      name,
      role: this.formObject.type
    }))
  }

  handleFormObjectChange(formAttributes, callback){
    const newAttributes = {...formAttributes}
    if (newAttributes.type !== 'irrigator') {
      newAttributes.irrigatorId = null
      if(newAttributes.schemeRoles){
        newAttributes.schemeRoles.forEach(role => role.role = newAttributes.type)
      }
    }
    super.handleFormObjectChange(newAttributes, callback)
  }

  renderSchemes = () =>
    <Fragment>
      <br />
      <Typography variant="body1">Schemes user can access:</Typography>
      <MultiSelect options={this.schemeRoleOptions} member='schemeRoles'
                   equalityProvider={(o1, o2) => `${o1.schemeId}` === `${o2.schemeId}`}/>
    </Fragment>

  renderIrrigators = () =>
    <Select disabled={this.editMode && !Authorization.admin} member='irrigatorId' label='Irrigator'>
      {this.props.irrigators.map(irrigator =>
        <MenuItem key={irrigator.id} value={irrigator.id}>{irrigator.name}</MenuItem>
      )}
    </Select>

  render = () =>
    <PageContainer>
      <LoadedHeader name={this.formObject.name}>
        {this.editMode ? `Edit User` : 'New User'}
      </LoadedHeader>
      <LoadedContent>
        <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
          {this.renderErrorMessages()}
          <TextField member='name' autoComplete="new-password"/>
          <TextField member='email' autoComplete="new-password"/>
          <Select disabled={this.editMode && !Authorization.admin} member='type'>
            <MenuItem value='admin'>Admin</MenuItem>
            <MenuItem value='irrigator'>Irrigator</MenuItem>
            <MenuItem value='operator'>Operator</MenuItem>
            <MenuItem value='regulator'>Regulator</MenuItem>
            <MenuItem value='viewer'>Viewer</MenuItem>
          </Select>

          <Collapse in={this.formObject.type === 'irrigator'}>
            {this.renderIrrigators()}
          </Collapse>
          <Collapse in={schemeSpecificRoles.includes(this.formObject.type) && Authorization.admin}>
            {this.renderSchemes()}
          </Collapse>
          <Collapse in={this.formObject.type !== 'admin'}>
            <br />
            <Typography variant="body1">Other permissions:</Typography>
            <Typography>
              <Checkbox member="contractedWaterViewer" type="checkbox" style={{marginLeft: -12 }}/>
              {' '}Contracted Water Viewer
            </Typography>
          </Collapse>
          <br/>
          <Typography>
            <Checkbox member="serviceAccount" type="checkbox" style={{marginLeft: -12 }}/>
            {' '}Service Account
          </Typography>
          <br/>
          {
            <Collapse in={(this.editMode && Authorization.user.id === this.props.user.id)}>
            <Card>
              <CardContent className={this.props.classes.passwords}>
                <Typography variant='body1'>Password {this.editMode && '(Optional)'}</Typography>
                {
                  Authorization.user.id === this.props.user.id &&
                  <TextField  type="password" fullWidth member="oldPassword"/>
                }
                <TextField type='password' fullWidth member='password' autoComplete="new-password" />
                <PasswordStrength password={this.state.formAttributes.password} minScore={2}/>
                <TextField disabled={!this.formObject.password} type='password' fullWidth member='passwordConfirmation' autoComplete="off"/>
              </CardContent>
            </Card>
            </Collapse>
          }
          <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
        </FormContext>
      </LoadedContent>
    </PageContainer>
}

const schemeSpecificRoles = [
  'regulator', 'viewer', 'operator'
]

const styles = theme => ({
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  },
  passwords: {

  },
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({users, schemes, irrigators}) => ({...irrigators, ...schemes, ...users})),
)(Form)