import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IrrigatorActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import Button from '@material-ui/core/Button'
import LoadedCard from 'components/LoadedCard'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IrrigatorIcon from '@material-ui/icons/Waves'
import DeleteIcon from '@material-ui/icons/Delete'
import DailyEntryIcon from '@material-ui/icons/LocalDrink'
import AccountIcon from '@material-ui/icons/AccountBox'
import Tooltip from '@material-ui/core/Tooltip'
import { compose, errorStringsFromError } from 'utils'
import { connectQueryString } from 'containers/shared'

export class List extends Component{

  constructor(props){
    super(props)
    IrrigatorActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  dependsOn(){
    return this.loadIrrigators()
  }

  loadIrrigators = () => {
    return this.actions.index({
      page: this.props.page
    })
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showIrrigator = id => () => {
    this.props.history.push(`/irrigators/${id}`)
  }

  editIrrigator = id => event => {
    this.props.history.push(`/irrigators/${id}/edit`)
    event.stopPropagation()
  }

  deleteIrrigator = id => event => {
    this.actions.destroy({id})
                .then(this.loadIrrigators)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  dailyEntry = id => event => {
    this.props.history.push(`/irrigators/${id}/daily_entries`)
    event.stopPropagation()
  }

  viewAccounts = id => event => {
    this.props.history.push(`/irrigators/${id}/accounts`)
    event.stopPropagation()
  }

  get irrigators(){
    return this.props.irrigators
  }

  handlePageChange = async page =>{
    await this.props.onPageChange(page)
    this.loadIrrigators()
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderIrrigatorListItem = ({id, name, code, priority}) =>
    <ListItem button onClick={this.editIrrigator(id)} key={id}>
      <Tooltip title="Edit irrigator">
        <ListItemIcon>
          <IrrigatorIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary={`${name} (${code})`} secondary={`Priority: ${priority || ''}`}/>
      <ListItemSecondaryAction>
        <Tooltip title="Account balances">
          <IconButton onClick={this.viewAccounts(id)}><AccountIcon/></IconButton>
        </Tooltip>
        <Tooltip title="Water log">
          <IconButton onClick={this.dailyEntry(id)}><DailyEntryIcon/></IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={this.deleteIrrigator(id)}><DeleteIcon/></IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <PageContainer>
      <ActionHeader title="Irrigators">
        <Button color="primary" variant="contained" onClick={() => this.props.history.push('/irrigators/new')}>Add</Button>
      </ActionHeader>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageChange={this.handlePageChange} style={{}} linkStyle={{}}/>
      <LoadedCard>
        {this.irrigators &&
          <MuiList dense>
            {this.irrigators.map(this.renderIrrigatorListItem)}
          </MuiList>
        }
      </LoadedCard>
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageChange={this.handlePageChange} style={{}} linkStyle={{}}/>
    </PageContainer>
}

export default compose(
  Dependent({loader: true}),
  connectQueryString('irrigators'),
  connect(({irrigators}) => irrigators)
)(List)
