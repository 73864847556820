import React, {Component} from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import {IndexContext} from './IndexContext'
import {withContext} from 'utils/Context'
import withStyles from 'styles'
import {APIResource} from 'services'
import {submitForm} from 'services/APIResource'

class ExportButton extends Component {
  static defaultProps = {
    format: 'csv'
  }

  handleDownload = () => {
    const {filter, order, params} = this.props
    const data = {
      Authorization: global.localStorage.auth,
      ...params,
      filter,
      order
    }
    submitForm(`${APIResource.DEFAULT_API_BASE}/${this.props.resource}.${this.props.format}`, data, 'post')
  }

  render = () =>
    <Tooltip title="Export CSV" className={this.props.classes.root}>
      <IconButton onClick={this.handleDownload} color='primary'><DownloadIcon/></IconButton>
    </Tooltip>
}

const styles = {
  root: {
    marginRight: 10,
    '&:last-child': {
      marginRight: 0
    }
  }
}

export default withContext(IndexContext)(withStyles(styles)(ExportButton))
