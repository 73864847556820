import React, {Component, Fragment} from 'react'
import withStyles from 'styles'
import Typography from '@material-ui/core/Typography'
import Unit from 'components/units/Volume'
import {humanize} from 'utils'

export class AccountSummary extends Component {
  state = {mounted: false}

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({mounted: true})
    })
  }

  render = () => {
    const {accounts, classes} = this.props
    return (
      <div className={classes({root: true, transition: !this.state.mounted})}>
        <Typography variant='subtitle1' className={classes.title}>Available</Typography>
        <dl className={classes.accounts}>
          {accounts.map(account =>
            <Fragment key={account.id}>
              <dt className={classes.accountTitle}>{humanize(account.accountType)} (P{account.priority})</dt>
              <dd className={classes.accountBalance}>
                <Unit>{account.availableBalance}</Unit>
              </dd>
            </Fragment>
          )}
        </dl>
      </div>
    )
  }
}

const styles = ({palette}) => ({
  root: {
    transition: 'filter 1000ms, margin 225ms',
    position: 'sticky',
    width: '100%',
    top: 51,
    zIndex: 1000,
    backgroundColor: palette.primary.main,
    border: `1px solid ${palette.primary.main}`,
    padding: 10,

    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxHeight: '5rem',
    alignContent: 'flex-start',
    '@media(min-width: 600px)': {
      padding: 20,
      maxHeight: '5.5rem',
    },
    '@media(min-width: 768px)': {
      pointerEvents: 'none',
      minHeight: 100,
      maxHeight: 'none',
      position: 'fixed',
      right: 20,
      top: 70,
      width: 225,
      backgroundColor: 'rgba(42, 125, 221, 0.8)',
      display: 'block'
    },
    '@media(min-width: 1275px)': {
      left: 1000,
      top: 130,
      right: 'auto',
      backgroundColor: palette.primary.main,
      pointerEvents: 'auto',
    }
  },
  title: {
    color: 'white',
    paddingBottom: 10,
    display: 'none',
    '@media(min-width: 768px)': {
      display: 'block',
    }
  },
  accounts: {
    margin: 0,
  },
  accountTitle: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    '@media(min-width: 600px)': {
      paddingRight: 60,
    },
    '@media(min-width: 768px)': {
      paddingRight: 0,
    },
    paddingRight: 30,
  },
  accountBalance: {
    margin: 0,
    fontSize: '1.2rem',
    paddingRight: 30,
    '@media(min-width: 600px)': {
      paddingRight: 60,
    },
    '@media(min-width: 768px)': {
      paddingRight: 0,
      margin: '0 0 5px',
      '&:last-child': {
        margin: 0
      }
    }
  },
  unit: {
    fontSize: '0.8em',
    opacity: 0.8
  },
  transition: {
    filter: 'opacity(0%)'
  },
})

export default withStyles(styles)(AccountSummary)
