import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MuiSelect from '@material-ui/core/Select'

export class Select extends Component{
  static propTypes = {
    error: PropTypes.string,
    helperText: PropTypes.string
  }
  render = () => {
    const { className, value, error, label, helperText, fullWidth, MenuProps, ...rest} = this.props
    return (
      <FormControl fullWidth={fullWidth} error={error} className={className}>
        <InputLabel>{label}</InputLabel>
        <MuiSelect MenuProps={MenuProps}
        {...rest} value={!value && value !== 0 ? '' : value}/>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }

}

export default Select
