import React from 'react'
import withStyles from 'styles'
import moment from 'moment'

const styles = {
  root: {
    fontSize: '0.75rem',
    fontWeight: 500,
    display: 'block',
    color: '#000'
  }
}

export default withStyles(styles)(({children, timestamp=null, classes}) => {
  timestamp = (timestamp || (children && children.timestamp))
  return timestamp ?
    <span className={classes.root}>
      (at {moment(timestamp).format('DD/MM/YYYY HH:mm:ss')})
    </span> : false
})
