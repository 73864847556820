import React               from 'react'
import { CenteredCard } from 'components'
import CardContent from '@material-ui/core/CardContent';
import { TokenActions, SnackbarActions } from 'actionsets'

const StatusPageMixin = superclass => class extends superclass{

  constructor(props){
    super(props)
    TokenActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  goBack = () => {
    this.props.history.goBack()
  }

  goToRoot = () => {
    this.props.history.push('/')
  }

  render = () =>
    <CenteredCard>
      <CardContent>
        {this.body()}
      </CardContent>
    </CenteredCard>
}

export default StatusPageMixin