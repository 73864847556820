import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import withStyles from 'styles'
import Typography from '@material-ui/core/Typography'
import Info from '@material-ui/icons/Info'

class PasswordStrength extends PureComponent {
  state = {
    zxcvbn: () => { return { score: 0 } }
  }

  static propTypes = {
    password: PropTypes.string,
    minScore: PropTypes.number,
  }

  componentDidMount() {
    import('zxcvbn').then(module => {
      this.setState({ zxcvbn: module.default })
    })
  }

  render = () => {
    const {zxcvbn} = this.state
    const {password, minScore, className, classes, style} = this.props
    const result = zxcvbn(password || '')
    const score = result.score
    return (
      <div className={className} style={style}>
        <div className={classes({passwordScore: true, good: score > minScore, fair: score === minScore })}>
          <span className={classes({on: !!password})} />
          <span className={classes({on: score > 1})} />
          <span className={classes({on: score > 2})} />
          <span className={classes({on: score > 3})} />
        </div>
        <div className={classes.helpWrapper}>
          <Info />
          <Typography variant="body2" className={classes.help}>
            We check for <strong>common words and patterns</strong> to work out how many <strong>guesses</strong> it
            would take <strong>to crack your password</strong>. To make sure your data is <strong>safe</strong> we
            require you have <strong>at least two bars</strong> on the strength meter above.
          </Typography>
        </div>
      </div>
    )
  }
}

const styles = ({palette}) => ({
  passwordScore: {
    display: 'flex',
    width: 'calc(100% - 10px)',
    height: '5px',
    '& span': {
      backgroundColor: '#ccc',
      height: '100%',
      flex: 1,
      margin: '0 1px',
      '&$on': {
        backgroundColor: palette.error.main,
      }
    },
    '&$good span$on': {
      '&$on': {
        backgroundColor: palette.success.main,
      }
    },
    '&$fair span$on': {
      '&$on': {
        backgroundColor: palette.warning.main,
      }
    }
  },
  on: {},
  good: {},
  fair: {},
  help: {
    fontSize: '13px',
    lineHeight: '15px',
    '& a': {
      color: 'white',
      fontWeight: 'bold'
    }
  },
  helpWrapper: {
    paddingTop: 10,
    marginRight: 10,
    display: 'flex',
    '& svg': {
      fill: palette.primary.main,
      marginRight: 6,
    }
  }
})

export default withStyles(styles)(PasswordStrength)
