import React, {Component} from 'react'
import withStyles from 'styles'
import {Bar, defaults} from 'react-chartjs-2'
import moment from 'moment'

class ContractedWater extends Component {
  get data(){
    let {contractedEntitlements = [], currentDailySummary} = this.props
    const currentEntitlement = currentDailySummary && [...contractedEntitlements]
      .reverse().find(e => e.contractedVolume <= currentDailySummary.contractedWater)
    return {
      labels: contractedEntitlements.map(({contractedVolume}) => (+milm3(contractedVolume))),
      datasets: [{
        type: 'scatter',
        label: 'RRIA Entitlement',
        borderColor: '#FA9C00',
        backgroundColor: '#FA9C00',
        pointRadius: 6,
        pointHoverRadius: 8,
        pointStyle: 'rectRot',
        fill: false,
        data: contractedEntitlements.map(e => e === currentEntitlement ? milm3(e.entitlementVolume) : null)
      }, {
        label: 'RRIA Entitlement',
        borderColor: '#07b151',
        backgroundColor: '#07b151',
        fill: false,
        data: contractedEntitlements.map(({entitlementVolume}) => milm3(entitlementVolume))
      }]
    }
  }

  get options() {
    return {
      layout: {
        padding: {
          top: 0,
        }
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          title: (tooltipItems, data) => {
            if (tooltipItems[0].datasetIndex === 0) {
              return `As at ${moment(this.props.currentDailySummary.date).format('DD/MM/YYYY')}`
            } else {
              let label = data.labels[tooltipItems[0].index] || '';
              return `Other parties: ${label}`;
            }
          },
          beforeBody: (tooltipItems) => {
            if (tooltipItems[0].datasetIndex === 0) {
              return `Other Parties: ${milm3(this.props.currentDailySummary.contractedWater)}`
            }
          }
        }
      },
      scales: {
        xAxes: [{
          gridLines: {
            color: '#BFBFBF',
            tickMarkLength: 7,
            borderDash: [4]
          },
          ticks: {
            source: 'auto',
            minRotation: 90,
            padding: 7,
          },
          scaleLabel: {
            display: 'true',
            labelString: "Contracted Water (million m³)",
            fontFamily: 'Roboto'
          },
        }],
        yAxes: [{
          id: 'left-y-axis',
          gridLines: {
            zeroLineColor: '#000',
            color: '#BFBFBF',
            tickMarkLength: 7,
            borderDash: [4]
          },
          scaleLabel: {
            display: 'true',
            labelString: 'RRIA Entitlement (million m³)',
            fontFamily: 'Roboto'
          },
          ticks: {
            min: 0,
            padding: 7,
          },
        }]
      },
    }
  }

  get plugins() {
    return [{
      beforeInit: function(chart, options) {
        chart.legend.afterFit = function() {
          this.height = this.height + 23;
        };
      }
    }]
  }

  render = () => {
    defaults.global.defaultFontColor = '#000'
    defaults.global.defaultFontFamily = 'Roboto'
    defaults.global.defaultFontSize = 16
    return (
      <div className={this.props.classes.root}>
        <Bar data={this.data} options={this.options} height={200} plugins={this.plugins} />
      </div>
    )
  }
}

const milm3 = m3 => (m3 === null || m3 === undefined) ? m3 : (m3 / 1e6).toFixed(3)

const styles = {
  root: {}
}

export default withStyles(styles)(ContractedWater)