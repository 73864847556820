import { createMuiTheme } from '@material-ui/core/styles'
import { darken, lighten, fade } from '@material-ui/core/styles/colorManipulator'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#07b151',
    },
    secondary: {
      main: '#07b79c',
      dark: '#007f6e',
    },
    error: {
      main: '#FA0057'
    },
    warning: {
      main: '#FA9C00',
    },
    success: {
      main: '#A3CE00'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto, sans-serif',
    h5: {
      '@media(min-width: 600px)': {
        fontSize: '4.375rem',
        marginBottom: 33,
        marginLeft: -6,
      },
      fontSize: '3.5rem',
      marginBottom: 26,
      marginLeft: -5,
      fontWeight: 100,
      color: '#007f6e',
      lineHeight: 1,
    },
    h6: {
      '@media(min-width: 600px)': {
        fontSize: '2.75rem',
        marginBottom: 20,
      },
      fontSize: '2.2rem',
      marginBottom: 16,
      fontWeight: 400,
      color: '#007f6e',
      lineHeight: 1
    },
    subtitle1: {
      '@media(min-width: 600px)': {
        fontSize: '1.75rem',
      },
      fontSize: '1.4rem',
      fontWeight: 500,
      color: '#007f6e',
      lineHeight: 1
    },
    body1: {
      fontSize: '1.125rem',
      fontWeight: 700,
      color: '#000'
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 'normal',
      color: '#000'
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        marginRight: 10,
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#F8F8F8',
        '& .link-small': {
          margin: 5,
          textAlign: 'center',
          display: 'block',
          color: '#666565',
          fontSize: '0.8rem',
          '&:hover': {
            textDecoration: 'underline'
          },
        }
      }
    },
    MuiCardContent: {
      root: {
        maxWidth: 1000,
        width: '100%',
        paddingRight: 6,
      }
    },
    MuiCardActions: {
      root: {
        paddingBottom: 18,
        '@media(min-width: 600px)': {
          padding: 0
        },
        padding: 0,
        margin: '0 10px 10px 12px'
      }
    },
    MuiCard: {
      root: {
        margin: '8px 10px 10px 0',
        backgroundColor: '#F8F8F8',
        border: `1px solid ${darken('#07b151', 0.2)}`,
        borderRadius: 0,
        boxShadow: 'none',
        maxWidth: 'calc(100vw - 20px)'
      }
    },
    MuiModal: {
      root: {
        top: 34,
      }
    },
    MuiToolbar: {
      root: {
        alignItems: 'stretch'
      },
      regular: {
        '@media(min-width: 600px)': {
          minHeight: 50
        },
        minHeight: 50
      },
      gutters: {
        paddingLeft: 0,
        paddingRight: 0,
        '@media(min-width: 600px)': {
          padding: 0,
        }
      }
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
        zIndex: 1400,
        borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
      },
      colorPrimary: {
        background: 'linear-gradient(to right, #07b151, #07b79c)',
        color: '#fff'
      }
    },
    MuiDrawer: {
      paper: {
        top: 50,
        padding: '20px 32px',
        height: 'auto',
        bottom: '0'
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      dense: {
        paddingTop: 0,
        paddingBottom: 0,
      }
    },
    MuiInputBase: {
      input: {
        '&$disabled': {
          color: 'rgba(0,0,0,0.87)'
        }
      }
    },
    MuiListSubheader: {
      sticky: {
        backgroundColor: 'white'
      }
    },
    MuiListItem: {
      root: {
        borderBottom: '1px solid rgba(180,180,180,0.3)',
        '&:last-child': {
          borderBottom: 'none'
        },
        '&:only-child': {
          borderBottom: '1px solid rgba(180,180,180,0.3)',
        },
        '&$selected': {
          fontWeight: 'bold',
          '& svg': {
            color: '#07b151'
          },
          '& span': {
            color: darken('#07b151', 0.2),
            fontWeight: 500
          },
        }
      }
    },
    MuiListItemText: {
      root: {
        '& > span': {
          fontSize: '1rem',
          fontWeight: 'normal',
          color: '#000'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        marginRight: 0,
      }
    },
    MuiFormControl: {
      root: {
        marginBottom: 15,
        paddingRight: 10,
        width: '50%'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: 'normal',
        marginRight: 10,
        marginBottom: 10,

      },
      fullWidth: {
        width: 'calc(100% - 10px)',
        marginTop: 30,
        '&~$fullWidth': {
          marginTop: 0
        }
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
        borderRadius: 0,
      },
      containedPrimary: {
        border: `1px solid ${darken('#07b151', 0.2)}`,
        color: darken('#07b151', 0.2),
        backgroundColor: '#fff',
        '&:hover': {
          backgroundColor: lighten('#07b151', 0.9),
        }
      },
      containedSecondary: {
        backgroundColor: '#07b151',
        color: '#fff',
        '&:hover': {
          backgroundColor: lighten('#07b151', 0.2),
        }
      },
      text: {
        '& $label': {
          fontWeight: 'bold'
        }
      },
      label: {
        fontWeight: '500'
      }
    },
    MuiBadge: {
      badge: {
        fontWeight: 'bold',
        height: 20,
        minWidth: 20,
        width: 'auto',
        borderRadius: 10,
        padding: '7px 6px 5px 5px',
        top: 'auto',
        bottom: -8,
        right: -10
      }
    },
    MuiDialog: {
      paper: {
        '@media(min-width: 600px)': {
          minWidth: 400
        }
      }
    },
    MuiDialogTitle: {
      root: {
        padding: '24px 18px 0',
      }
    },
    MuiDialogContent: {
      root: {
        paddingLeft: '22px',
        paddingRight: '12px',
        paddingBottom: 0,
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        paddingLeft: 24,
        paddingRight: 24,
      }
    },
    MuiDialogActions: {
      root: {
        paddingLeft: '22px',
        paddingRight: '22px',
        marginLeft: 0,
        marginRight: 0,
      },
      action: {
        marginTop: 27,
        marginBottom: 27,
        marginLeft: 0,
        marginRight: 0,
        flex: '1 0',
        '&:not(:last-child)': {
          marginRight: '6px'
        },
        '&:not(:first-child)': {
          marginLeft: '6px'
        }
      }
    },
    MuiPickersModal: {
      dialogAction: {
        margin: 0,
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: '#F8F8F8',
        '& > *': {
          backgroundColor: 'transparent',
        }
      },
      transitionContainer: {
        height: '23px'
      }
    },
    MuiTableCell: {
      footer: {
        color: '#000',
        fontWeight: '500 !important',
        backgroundColor: fade('#07b79c', 0.1)
      }
    }
  }
})