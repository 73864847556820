import React, {Component} from 'react'
import Button from '@material-ui/core/Button'
import {ErrorBanner, FormContext} from 'components'
import VolumeField from 'components/units/VolumeField'
import LabeledSelect from 'components/LabeledSelect';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DatePicker from 'components/DatePicker'
import PromiseButton from 'components/PromiseButton'
import {errorStringsFromError, humanize} from 'utils'
import moment from 'moment'

export class AccountTransactionDialog extends Component{

  static getDerivedStateFromProps(props, state){
    if (props.accountDelta !== state.accountDelta) {
      let newState = {
        ...state,
        accountDelta: props.accountDelta,
        ...props.accountDelta && {
          id:                props.accountDelta.id,
          irrigatorId:       props.accountDelta.irrigator?.id,
          accountId:         props.accountDelta.account?.id,
          transactionId:     props.accountDelta.deltaTransaction?.id,
          notes:             props.accountDelta.notes,
          balance:           props.accountDelta.availableBalance + props.accountDelta.contractedBalance,
          transactedAt:      props.accountDelta.deltaTransaction?.transactedAt,
          subaccount:        props.accountDelta.contractedBalance === 0 ? 'available' : 'contracted'
        },
        error: undefined
      }

      // If no account is set in accountDelta and only one account is available in props, set it as default
      if (!props.accountDelta?.id && props.irrigators?.length === 1) {
        newState.irrigatorId = props.irrigators[0].id
      }

      return newState
    }
    return null
  }

  state = {}

  handleSave = async () => {
    this.setState({error: undefined})
    let formErrors = {}

    if (!this.state.irrigatorId) {
      formErrors = {...formErrors, ...{ irrigatorId: 'Irrigator is required'} }
    }

    if (!this.state.accountId) {
      formErrors = {...formErrors, ...{ accountId: 'Account is required'} }
    }

    try{
      if (Object.keys(formErrors).length > 0) {
        throw formErrors
      }

      await this.props.onSave({
        id: this.state.id,
        deltaTransactionAttributes: {
          id: this.state.transactionId,
          schemeId: this.irrigator?.schemeId,
          transactedAt: moment(this.state.transactedAt).format('YYYY-MM-DD')
        },
        accountId: this.state.accountId,
        availableBalance: this.state.subaccount === 'available' ? this.state.balance : 0,
        contractedBalance: this.state.subaccount === 'contracted' ? this.state.balance : 0,
        notes: this.state.notes
      })
    }
    catch(error){
      this.setState({error})
      throw error
    }
  }

  get irrigator(){
    return this.props.irrigators.find(({id}) => `${id}` === this.state.irrigatorId)
  }

  get irrigators(){
    return this.props.irrigators
  }

  get accounts(){
    return this.irrigator && this.irrigator.accounts
  }

  get errorContext(){
    const {meta} = this.state.error || {}
    if(meta){
      return Object.entries(meta).reduce((acc, [name, value]) => {acc[name] = value.join(', '); return acc},{})
    }
    return null
  }

  handleChange = state =>
    this.setState(state)

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.state.error)}
    </ErrorBanner>

  render = () =>
    <Dialog open={!!this.props.accountDelta}>
      <FormContext onSubmit={this.handleSave} context={this.state} errContext={this.errorContext} onChange={this.handleChange}>
        <DialogTitle>{this.props.accountDelta.id ? 'Edit' : 'New'} Account Transaction</DialogTitle>
        <DialogContent style={{width: 600}}>
          {this.renderErrorMessages()}
          <DatePicker errorMember='deltaTransaction.transactedAt' fullWidth label='Effective Date' member='transactedAt'/>
          <LabeledSelect fullWidth label='Irrigator' member='irrigatorId' required={true}>
            {
              this.irrigators?.map(irrigator =>
                <MenuItem key={irrigator.id} value={irrigator.id}>{irrigator.name}</MenuItem>
              )
            }
          </LabeledSelect>
          { this.state.error?.irrigatorId && (
              <p style={{color: 'red', margin: 0}}>{ this.state.error?.irrigatorId }</p>
            )
          }        
          <LabeledSelect fullWidth label='Account' member='accountId' required={true}>
            {
              this.accounts?.map(account =>
                <MenuItem key={account.id} value={account.id}>{humanize(account.accountType)}</MenuItem>
              )
            }
          </LabeledSelect>
          { this.state.error?.accountId && (
              <p style={{color: 'red', margin: 0}}>{ this.state.error?.accountId }</p>
            )
          }
          <LabeledSelect fullWidth label='Sub Account' member='subaccount' required={true}>
              <MenuItem value='contracted'>Contracted</MenuItem>
              <MenuItem value='available'>Available</MenuItem>
            </LabeledSelect>
          <TextField member='notes' fullWidth/>
          <VolumeField min={-1000000000} max={1000000000} member='balance' label='Transaction Amount' fullWidth/>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} variant='contained' color='primary'>Cancel</Button>
          <PromiseButton type='submit' color='secondary' variant='contained'>Save</PromiseButton>
        </DialogActions>
      </FormContext>
    </Dialog>
}

export default AccountTransactionDialog
