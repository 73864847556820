import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AuditActions, SnackbarActions } from 'actionsets'
import Card from '@material-ui/core/Card'
import LoadedCard from 'components/LoadedCard'
import TextField from '@material-ui/core/TextField/TextField'
import Typography from '@material-ui/core/Typography'
import { compose, humanize, userFriendlyDate } from 'utils'
import { connectQueryString } from 'containers/shared'
import Dependent from 'containers/shared/Dependent'
import PageContainer from 'components/PageContainer'
import Table, {TableHead, TableBody, TableRow, TableCell} from 'components/CollapsibleTable'
import DatePicker from 'components/DatePicker'
import moment from 'moment'

import {
  Pagination,
  SearchCombo,
  IndexTools,
  MultiSelect,
  LinkButton,
  ConfirmationDialog,
} from 'components'
import withStyles from 'styles'

export class List extends Component {
  constructor(props) {
    super(props)
    AuditActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  dependsOn() {
    return this.loadAudits()
  }

  dependenciesMet() {
    return this.props.requests.length === 0
  }

  get audits() {
    return this.props.audits
  }

  get filter() {
    const {filter, filter: {event}} = this.props
    return {...filter, event: (!event || Array.isArray(event)) ? event : [event] }
  }

  get changeset() {
    const changes = (this.state.showAudit && this.state.showAudit.changeset) || {}
    return Object.keys(changes).map(k => ({field: k, from: changes[k][0], to: changes[k][1]}))
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.query !== this.props.query) {
      this.loadAudits()
    }
  }

  handleShowAudit = (audit) => () => {
    this.setState({showAudit: audit})
  }

  humanizeValue(value) {
    if (value && isNaN(value) && moment(value).isValid()) {
      return userFriendlyDate(value)
    }
    else {
      return value
    }
  }

  loadAudits = () => this.actions.index({page: this.props.page, filter: this.props.filter})

  renderAudit = audit =>
    <TableRow key={audit.id}>
      <TableCell mobileHeading>{humanize(audit.event)}</TableCell>
      <TableCell mobileLabel="Item Type">{audit.itemType}</TableCell>
      <TableCell mobileLabel="Item Id">
        <LinkButton onClick={this.handleShowAudit(audit)} disabled={Object.keys(audit.changeset).length === 0}>
          {audit.itemId}
        </LinkButton>
      </TableCell>
      <TableCell mobileLabel="Author">{audit.userEmail || 'System'}</TableCell>
      <TableCell mobileLabel="Created Date">{userFriendlyDate(audit.createdAt)}</TableCell>
    </TableRow>

    render = () => {
      const everyEvent = ['create', 'update', 'delete', 'user_actions']
      const { onFilterChange, classes } = this.props
      return (
        <PageContainer width={'lg'}>
          <Typography variant="h5">Audit Logs</Typography>
          <IndexTools>
            <SearchCombo onFilterChange={onFilterChange} filter={this.filter}>
              <div>
                <TextField label='Entity' member='item_type' fullWidth={false}/>
                <TextField label='ID' member='item_id' fullWidth={false}/>
              </div>
              <div>
                <DatePicker label='Start Time' member='created_at.start'/>
                <DatePicker label='End Time' member='created_at.end'/>
              </div>
              <div>
                <Typography variant="subtitle1">Events</Typography>
                <MultiSelect member="event" options={everyEvent} defaultOptions={everyEvent} unsetWhenDefault/>
              </div>
            </SearchCombo>
            <Pagination totalPages={this.props.totalPages} page={this.props.page} style={{}} linkStyle={{}}/>
          </IndexTools>
          <LoadedCard>
            <Table>
            <TableHead>
              <TableRow>
                <TableCell>Event</TableCell>
                <TableCell>Item Type</TableCell>
                <TableCell>Id</TableCell>
                <TableCell>Author</TableCell>
                <TableCell>Create Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.audits.map(audit => this.renderAudit(audit))}
            </TableBody>
          </Table>
          </LoadedCard>
          <ConfirmationDialog title="Changes" onConfirm={this.handleShowAudit(null)} open={!!this.state.showAudit}
                              canCancel={false} maxWidth={'lg'}
          >
            <Card className={classes.changes}>
              <Table breakpoint={400}>
                <TableHead>
                  <TableRow>
                    <TableCell>Field</TableCell>
                    <TableCell>Old Value</TableCell>
                    <TableCell>New Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.changeset.map(change =>
                    <TableRow key={change.field}>
                      <TableCell mobileHeading>{humanize(change.field)}</TableCell>
                      <TableCell mobileLabel="Old Value">{this.humanizeValue(change.from)}</TableCell>
                      <TableCell mobileLabel="New Value">{this.humanizeValue(change.to)}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Card>
          </ConfirmationDialog>
        </PageContainer>
      )
    }
}

const styles = (theme) =>({
  changes: {
    overflow: 'auto'
  }
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connectQueryString('audits'),
  connect(({audits}) => audits)
)(List)
