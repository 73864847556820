import React, {PureComponent} from 'react'
import TextField from '@material-ui/core/TextField'
import {withContext} from 'utils/Context'
import VolumeContext from './VolumeContext'
import InputAdornment from '@material-ui/core/InputAdornment'
import withStyles from 'styles'

export class VolumeField extends PureComponent {
  state = {value: '', nativeValue: null}

  renderAdornment = () => {
    const {units, display} = this.props
    return (
      <InputAdornment position='end' className={this.props.classes.adornment}>{units[display].display}</InputAdornment>
    )
  }

  componentDidMount = () => {
    this.setState({value: this.toDisplay(this.props.value), nativeValue: this.props.value})
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.value !== prevProps.value && this.props.value !== this.state.nativeValue) {
      this.setState({value: this.toDisplay(this.props.value), nativeValue: this.props.value})
    }
  }

  get step() {
    const {units, display} = this.props
    return Math.pow(10, -units[display].dp)
  }

  toNative = displayValue => {
    const {units, native, display} = this.props
    return Math.round(Number(displayValue) * units[display].factor / units[native].factor)
  }

  toDisplay = nativeValue => {
    if (nativeValue === null || Number.isNaN(nativeValue))
      return ''
    const {units, native, display} = this.props
    let convertedValue = nativeValue * units[native].factor / units[display].factor
    if (typeof units[display].dp === 'number')
      convertedValue = +convertedValue.toFixed(units[display].dp)
    return convertedValue.toString()
  }

  handleChange = event => {
    const value = this.toNative(event.target.value)
    if (value || value === 0)
      this.props.onChange && this.props.onChange({target: {value}})

    this.setState({value: event.target.value, nativeValue: value})
  }

  render = () => {
    let {classes, units, native, display, min, max, ...props} = this.props
    min = min ? this.toDisplay(min) : 0
    max = max && this.toDisplay(max)
    return (
      <TextField {...props} value={this.state.value} type='number' onChange={this.handleChange}
                 InputProps={{...props.inputProps, endAdornment: this.renderAdornment(), }}
                 // eslint-disable-next-line
                 inputProps={{...this.props.inputProps, step: this.step, min, max}}/>
    )
  }
}

const styles = {
  adornment: {
    whiteSpace: 'nowrap',
    display: 'block',
    height: 'auto',
    color: 'rgba(0,0,0,0.60)'
  }
}

export default withContext(VolumeContext)(withStyles(styles)(VolumeField))
