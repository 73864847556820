import React, {Component} from 'react'
import withStyles from 'styles'

class DataValue extends Component {
  render = () => {
    const {classes, className, title, children, ...props} = this.props
    return (
      <div {...props} className={classes('root', className)}>
        <dt className={classes.title}>{title}</dt>
        <dd className={classes.value}>{children}</dd>
      </div>
    )
  }
}

const styles = ({palette}) => ({
  root: {
    paddingRight: 10,
    flex: 1,
    paddingBottom: 8,
  },
  title: {
    fontSize: '0.75em',
    fontWeight: 'bold',
  },
  value: {
    margin: 0
  },
})

export default withStyles(styles, {name: 'DataValue'})(DataValue)