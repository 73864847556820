import React, {Fragment, Component} from 'react'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import Typography from '@material-ui/core/Typography'
import AccountTransactions from './AccountTransactions'
import SchemeTransactions from './SchemeTransactions'
import BalancesSummary from 'components/BalancesSummary'
import RightSideContent from 'components/RightSideContent'
import withStyles from 'styles'
import {IrrigatorActions} from 'actionsets'
import {connect} from 'react-redux'
import * as API from 'api'
import WaterLevel from 'components/scheme/WaterLevel'
import {SchemeView} from 'containers/shared'
import {compose} from 'utils'
import moment from 'moment'

export class Show extends Component {

  constructor(props) {
    super(props)
    IrrigatorActions.bindActions(this)
  }

  state = {
    irrigators: [],
    scheme: {}
  }

  componentDidMount = async () => {
    if (this.props.scheme.id) {
      await this.loadIrrigators()
      try {
        const {data: scheme} = await API.Schemes.latestDaily({id: this.props.scheme.id})
        this.setState({scheme})
      } catch (e) {
        console.warn('No daily transactions for a scheme')
      }
    }
  }

  componentDidUpdate = async prevProps => {
    if (this.props.match.params.id && this.props.match.params.id !== prevProps.match.params.id) {
      await this.loadIrrigators()
      try {
        const {data: scheme} = await API.Schemes.latestDaily({id: this.props.scheme.id})
        this.setState({scheme})
      } catch (e) {
        this.setState({scheme: {}})
        console.warn('No daily transactions for a scheme')
      }
    }
  }

  loadIrrigators = async () => {
    const {data: irrigators} = await API.Irrigators.index({
      options: {
        include: 'accounts',
        fields: {irrigators: 'name,id,accounts'},
        filter: {scheme: {id: this.props.scheme.id}}
      }
    })
    this.setState({
      irrigators: irrigators.map(irrigator => ({
        ...irrigator,
        timestamp: moment().add(-1, 'day').endOf('day')
      }))
    })
  }

  get irrigators() {
    return this.state.irrigators
  }

  render = () =>
    <Fragment>
      <PageContainer width='lg'>
        <ActionHeader title="Transactions"/>
        <div style={{marginBottom: 20}}>
          {this.props.schemeSelect}
        </div>
        {this.props.scheme.id &&
          <Fragment>
            <RightSideContent>
              <BalancesSummary irrigators={this.irrigators}/>
              <Typography variant='h6'>Scheme Levels</Typography>
              <WaterLevel store='accessible' scheme={this.state.scheme}/>
              <WaterLevel store='warehouse' scheme={this.state.scheme}/>
            </RightSideContent>
            <div className={this.props.classes.section}>
              <AccountTransactions irrigators={this.irrigators} onChange={this.loadIrrigators}/>
            </div>
            <div className={this.props.classes.section}>
              <SchemeTransactions scheme={this.props.scheme}/>
            </div>
          </Fragment>
        }
      </PageContainer>
    </Fragment>
}

const styles = {
  accountTransactions: {
    flexFlow: 'wrap-reverse',
    '& > div': {
      flex: 1
    }
  },
  section: {
    marginBottom: 20,
  },
  schemeSelect: {
    maxWidth: 400
  }
}

export default compose(
  SchemeView({path: 'transactions'}),
  withStyles(styles),
  connect(({irrigators, schemes}) => ({...schemes, ...irrigators}))
)(Show)
