import React, { Component } from 'react'
import LoadedContext from 'components/LoadedContext'

/**
 * The Dependent high-order component allows you to make rendering of a child component conditional on a
 * certain dependency being met, and allows you to trigger actions if the attempt to load the dependency fails.
 * This way your child component does not need to contain dependency null checking code.
 * It will only render if all dependencies have been met.
 *
 * If the component is dependent on certain asynchronous actions being triggered when it first mounts
 * these will no longer be triggered because mounting of the component will be prevented by the
 * Dependent wrapper. To circumvent this, you can expose a `dependsOn` function on your component
 * which will be invoked when the component first attempts to mount.
 *
 * The `dependsOn` function can return a promise if you want to handle the case where
 * meeting a dependency has failed
 *
 * The component 'must' define a `dependenciesMet` function, which will return true if all properties required
 * to render the component are present
 *
 * The component can optionally define a `dependenciesFailed` handler. Otherwise
 * the default response is to redirec the user to the '/not_found' url
 *
 * The wrapper can optionally show a loader component while dependencies are being loaded
 * To do this wrap the component as follows:
 *
 * export Dependent({loader: true})(NeedsUser)
 *
 * OR
 *
 * export Dependent({loader: <MyCustomLoader/>})(NeedsUser)
 *
 * E.g
 *
 * class NeedsUser extends Component{

 *   dependsOn(){
 *     return this.actions.load(this.props.match.params.id))
 *   }
 *
 *   dependenciesMet(){
 *     return !!this.user
 *   }
 *
 *   render(){
 *     .. check this.props.loaded to know if this.user is loaded
 *   }
 * }
 *
 * export Dependent(NeedsUser)
 */

export class Dependent extends Component{

  static SHOW_LOADER_AFTER    = 600;
  static SHOW_LOADER_AT_LEAST = 250;

  constructor(props){
    super(props)
    const { component: Component, ...rest} = this.props
    this.scope = new Component(rest)
    this.state = { showLoader: false, dependsOnResolved: false, dependenciesMet: false }
  }

  static defaultProps = {
    options: {},
    wrapperStyle: {},
    wrapperClassName: ''
  }

  componentDidMount = () => {
    return Promise.resolve(this.dependsOn()).then(() => {
      this.setState({dependsOnResolved: true})
    }).catch(this.dependenciesFailed.bind(this.scope))
  }

  dependenciesMet = () => {
    this.scope.props = this.props
    const { dependenciesMet } = this.scope
    if(!dependenciesMet){
      throw new Error('Dependent component must define a `dependenciesMet` function')
    }
    try{
      return dependenciesMet.call(this.scope) && this.state.dependsOnResolved
    }catch(err){
      console.log(err)
      return false
    }
  }

  redirect = (path) => {
    if (this.props.history)
      this.props.history.replace(path)
  }

  get dependsOn(){
    return (this.scope.dependsOn || (() => {
      return true
    })).bind(this.scope)
  }

  get dependenciesFailed(){
    return this.scope.dependenciesFailed || (error => {
      error = error.length ? error[0] : error
      switch(error.status){
      case 403: break
      case 408: {
        this.redirect('/timed_out')
        break
      }
      case 504: {
        this.redirect('/timed_out')
        break
      }
      case 404: {
        this.redirect('/not_found')
        break
      }
      default: {
        console.error('Unexpected error', error)
        this.redirect('/not_found')
        break
      }}
    })
  }

  render = () => {
    const { component: Component, ...rest} = this.props
    const isLoaded = this.dependenciesMet()
    return (
      <LoadedContext.Provider value={{loaded: isLoaded}}>
        <Component {...rest} loaded={isLoaded}/>
      </LoadedContext.Provider>
    )
  }
}

export default (optionsOrComponent) => {
  switch(typeof optionsOrComponent){
  case 'function': {
    return props => <Dependent {...props} component={optionsOrComponent} />
  }
  default: {
    return component => props => <Dependent {...props} options={optionsOrComponent} component={component} />
  }}
}
