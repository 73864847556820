import React, { Component } from 'react'
import { connect } from 'react-redux'
import StatusPageMixin from './StatusPageMixin'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

export class Inactive extends StatusPageMixin(Component){

  continue = () => {
    this.actions.refresh(false).then(() => {
      const location = (
        this.props.savedLocation &&
        !this.props.savedLocation.match(/^\/?(?:tokens)|(?:inactive)\/?/)
      ) ?
        this.props.savedLocation :
        '/'
      this.props.history.push(location)
    }).catch(() => {
      this.actions.destroy()
      this.actions.snackbar.show('Session expired')
    })
  }

  signIn = () => {
    this.props.history.push('/tokens/sign_in')
  }

  body(){
    return (
      <div>
        <Typography variant='h6'>User session inactive</Typography>
        <Typography variant='body1' paragraph>Your current session has expired.</Typography>
        <Button fullWidth variant='contained' color='secondary' onClick={this.continue}>Continue</Button>
        <Button fullWidth variant='contained' color='primary' onClick={this.actions.destroy}>Sign Out</Button>
      </div>
    )
  }
}

export default connect(({tokens}) => tokens)(Inactive)