import React, { Component } from 'react'
import { connect } from 'react-redux'
import { UserActions } from 'actionsets'
import Dependent from 'containers/shared/Dependent'
import Button from '@material-ui/core/Button'
import PersonIcon from '@material-ui/icons/Person'
import EmailIcon from '@material-ui/icons/Email'
import FaceIcon from '@material-ui/icons/Face'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import {humanize} from 'utils'
import InfoIcon from '@material-ui/icons/Info'
import Typography from '@material-ui/core/Typography'
import withStyles from 'styles'
import { compose } from 'utils'

export class Profile extends Component{

  constructor(props){
    super(props)
    UserActions.bindActions(this)
  }

  dependenciesMet(){
    return !!this.props.currentUser.id
  }

  get currentUser(){
    return this.props.currentUser
  }

  render = () =>
    <PageContainer>
      <ActionHeader title="Profile">
        <Button color="primary" variant="contained" onClick={() => this.props.history.push(`/users/${this.currentUser.id}/edit`)}>
          Edit
        </Button>
      </ActionHeader>
      {this.currentUser.unconfirmedEmail &&
        <Typography paragraph className={this.props.classes.helpWrapper}>
          <InfoIcon/>We have sent an email to your new address {this.currentUser.unconfirmedEmail}. Please click the link in the email to confirm the change.
        </Typography>
      }
      <List>
        <ListItem>
          <ListItemIcon><PersonIcon/></ListItemIcon>
          <ListItemText primary={this.currentUser.name} secondary='name'/>
        </ListItem>
        <ListItem>
          <ListItemIcon><EmailIcon/></ListItemIcon>
          <ListItemText primary={this.currentUser.email} secondary='email'/>
        </ListItem>
        <ListItem>
          <ListItemIcon><FaceIcon/></ListItemIcon>
          <ListItemText primary={humanize(this.currentUser.type)} secondary='type'/>
        </ListItem>
      </List>
    </PageContainer>
}

const styles = ({palette}) => ({
  helpWrapper: {
    paddingTop: 10,
    marginRight: 10,
    display: 'flex',
    '& svg': {
      marginRight: 6,
      marginTop: 2,
      color: palette.primary.main
    }
  }
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({tokens}) => tokens),
)(Profile)
