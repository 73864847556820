import React, { Component } from 'react'
import { withContext } from 'utils/Context'
import withStyles from 'styles'
import LoadedContext from 'components/LoadedContext'
import Loader from 'components/Loader'
import ResizeCard from 'components/ResizeCard'

class LoadedCard extends Component {

  render = () => {
    const {loaded, classes, children, ...props} = this.props
    return (
      <div className={classes.wrapper}>
        <ResizeCard {...props}>
          <div className={classes({content: true, loading: !loaded})}>
            {children}
          </div>
        </ResizeCard>
        <div className={classes({loaderOverlay: true, loading: !loaded})}><Loader/></div>
      </div>
    )
  }
}

export const styles = theme => ({
  wrapper: {
    position: 'relative'
  },
  content: {
    transition: 'filter 1000ms',
    '&$loading': {
      minHeight: 140,
      filter: 'opacity(0.5)',
    }
  },
  loading: {},
  loaderOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: 'opacity 500ms',
    pointerEvents: 'none',
    '&$loading': {
      opacity: 1,
      pointerEvents: 'auto',
    }
  }
})

export default withContext(LoadedContext)(withStyles(styles)(LoadedCard))
