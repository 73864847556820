import React, {Component} from 'react'
import withStyles from 'styles'
import ReactDOM from 'react-dom'
import Card from '@material-ui/core/Card'

class ResizeCard extends Component {
  state = {}

  contentRef = ref => {
    this._ref = ReactDOM.findDOMNode(ref)
    if (this._ref) {
      this.observer.observe(this._ref, {attributes: true, childList: true, subtree: true})
    }
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize)
    this.observer.disconnect()
  }

  componentDidUpdate = () => {
    this.handleResize()
  }

  handleResize = () => {
    if (this._ref.offsetHeight !== this.state.contentHeight) {
      this.setState({contentHeight: this._ref.offsetHeight})
    }
  }
  observer = new MutationObserver(this.handleResize)

  render = () => {
    const {children, classes, className, ...props} = this.props
    const transition = this.state.contentHeight !== undefined
    return (
      <Card {...props} className={classes(className, 'root', {transition})} style={{height: this.state.contentHeight}}>
        <div ref={this.contentRef}>{children}</div>
      </Card>
    )
  }

}

const styles = {
  root: {
    overflow: 'hidden',
  },
  transition: {
    transition: 'height 500ms',
  }
}

export default withStyles(styles)(ResizeCard)