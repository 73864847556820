import React, {Component} from 'react'
import withStyles from 'styles'

class DataList extends Component {
  render = () => {
    const {classes, className, direction, ...props} = this.props
    return (
      <dl {...props} className={classes('root', className, {vertical: direction === 'vertical'})} />
    )
  }
}

const styles = ({palette}) => ({
  root: {
    margin: 0,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContext: 'space-between',
  },
  vertical: {
    flexDirection: 'column'
  }
})

export default withStyles(styles, {name: 'DataList'})(DataList)