import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'styles'

export class Loader extends Component {

  static propTypes = {
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.string
  }

  static defaultProps = {
    size: 100
  }

  loaderStyle = () => {
    return {
      width: this.props.size,
      height: this.props.size
    }
  }

  render = () =>
    <div className={[this.props.className, this.props.classes.loader].filter(c => c).join(' ')} style={this.loaderStyle()}/>
}

const styles = theme =>({
  loader: {
    margin:"20px auto",
    position:"relative",
    textIndent:"-9999em",
    borderTop:`0.5em solid ${theme.palette.secondary.main}`,
    borderRight:`0.5em solid ${theme.palette.secondary.main}`,
    borderBottom:`0.5em solid ${theme.palette.secondary.main}`,
    borderLeft:"0.5em solid rgba(0, 0, 0, 0)",
    transform:"translateZ(0)",
    animation:"load8 1.1s infinite linear",
    borderRadius:"50%",
    width:"10em",
    height:"10em",
    '& :after': {
      borderRadius:"50%",
      width:"10em",
      height:"10em"
    }
  },
  '@keyframes load8':{
    '0%': {
      transform:"rotate(0deg)"
    },
    '100%': {
      transform:"rotate(360deg)"
    }
  }

})
export default withStyles(styles)(Loader)
