import React, {Component} from 'react'
import Card from '@material-ui/core/Card'
import withStyles from 'styles'
import { MuiThemeProvider } from '@material-ui/core/styles';
import {theme} from 'styles/theme'

export class IndexToolbar extends Component {
  render = () => {
    const {classes, ...props} = this.props
    return (
      <MuiThemeProvider theme={{...theme, overrides: {...theme.overrides, ...overrides}}}>
        <Card className={classes.card} {...props}/>
      </MuiThemeProvider>
    )
  }
}

const overrides = {
  Pagination: {
    pagination: {
      borderRight: 'none',
    },
  },
  FilteredSearchInput: {
    searchInput: {
      background: '#FDFDFD',
      minHeight: 48,
      border: 'none',
      borderBottom: '1px solid rgba(180,180,180,0.3)',
      '@media(min-width: 800px)': {
        borderRight: '1px solid rgba(180,180,180,0.3)',
        borderBottom: 'none'
      },
    }
  }
}

const styles = theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    '@media(min-width: 800px)': {
      flexDirection: 'row',
    },
    borderRightWidth: 6,
    '& > *': {
      margin: 0,
      width: '100%',
      '@media(min-width: 800px)': {
        width: 'auto',
      },
    }
  }
})

export default withStyles(styles)(IndexToolbar)