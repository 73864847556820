import React, {Component} from 'react'
import withStyles from 'styles'
import {Bar, defaults} from 'react-chartjs-2'

class LimitingFactors extends Component {
  get data(){
    const {
      ruleInflow: {max_stored_water_change: ruleInflow }={},
      ruleLake: {max_stored_water_change: ruleLake }={},
      ruleWcoHigh: {max_stored_water_change: ruleWcoHigh }={},
      ruleWcoLow: {max_stored_water_change: ruleWcoLow }={},
      ruleWarehouse: {max_stored_water_change: ruleWarehouse }={},
    } = this.props.dailyChange
    const ruleWco = Math.min(ruleWcoHigh, ruleWcoLow)
    const lowest = milm3(Math.min(ruleInflow, ruleLake, ruleWco, ruleWarehouse))
    return {
      labels: ['Lake Inflow', 'Lake Level', 'WCO Limit', 'Storable Water'],
      datasets: [{
        type: 'line',
        lineTension: 0,
        label: 'Min Value',
        backgroundColor: '#FA0057',
        borderColor: '#FA0057',
        fill: false,
        borderWidth: 2,
        pointRadius: 0,
        data: [lowest, lowest, lowest, lowest]
      },{
        label: 'Limit',
        stack: '1',
        backgroundColor: '#2d2b74',
        borderWidth: 0,
        data: [milm3(ruleInflow), milm3(ruleLake), milm3(ruleWco), 0]
      }, {
        label: 'Storable',
        stack: '1',
        backgroundColor: '#07b151',
        borderWidth: 0,
        data: [0, 0, 0, milm3(ruleWarehouse)]
      }]
    }
  }

  get options() {
    return {
      layout: {
        padding: {
          top: 0,
        }
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            color: '#BFBFBF',
            tickMarkLength: 8,
            borderDash: [4]
          },
          ticks: {
            autoSkip: false,
            padding: 7,
          }
        }],
        yAxes: [{
          gridLines: {
            zeroLineColor: '#000',
            color: '#BFBFBF',
            tickMarkLength: 7,
            borderDash: [4]
          },
          scaleLabel: {
            display: 'true',
            labelString: 'million m³',
            fontFamily: 'Roboto'
          },
          ticks: {
            padding: 7,
            maxTicksLimit: 5,
          }
        }]
      },
    }
  }

  get plugins() {
    return [{
      beforeInit: function(chart, options) {
        chart.legend.afterFit = function() {
          this.height = this.height + 23;
        };
      }
    }]
  }

  render = () => {
    defaults.global.defaultFontColor = '#000'
    defaults.global.defaultFontFamily = 'Roboto'
    defaults.global.defaultFontSize = 16
    return (
      <div className={this.props.classes.root}>
        <Bar data={this.data} options={this.options} height={200} plugins={this.plugins} />
      </div>
    )
  }
}

const milm3 = m3 => (m3 === null || m3 === undefined) ? m3 : (m3 / 1e6).toFixed(3)

const styles = {
  root: {
    margin: '0 auto',
    maxWidth: "90%"
  }
}

export default withStyles(styles)(LimitingFactors)