import React, {Component} from 'react'
import {connect} from 'react-redux'
import {UserActions, SnackbarActions} from 'actionsets'
import {Pagination, ErrorBanner} from 'components'
import Dependent from 'containers/shared/Dependent'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PersonIcon from '@material-ui/icons/Person'
import DeleteIcon from '@material-ui/icons/Delete'
import {compose, errorStringsFromError} from 'utils'
import {connectQueryString} from 'containers/shared'
import LoadedCard from 'components/LoadedCard'
import Tooltip from '@material-ui/core/Tooltip'

export class List extends Component {

  constructor(props) {
    super(props)
    UserActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  dependsOn() {
    return this.loadUsers()
  }

  handlePageChange = async page => {
    await this.props.onPageChange(page)
    this.loadUsers()
  }

  loadUsers = () => {
    return this.actions.index({
      page: this.props.page
    })
  }

  dependenciesMet() {
    return this.props.requests.length === 0
  }

  showUser = id => () => {
    this.props.history.push(`/users/${id}`)
  }

  editUser = id => event => {
    this.props.history.push(`/users/${id}/edit`)
    event.stopPropagation()
  }

  deleteUser = id => event => {
    this.actions.destroy({id})
      .then(() => this.actions.index())
      .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get users() {
    return this.props.users || []
  }

  get errors() {
    let errors = []
    if (this.props.errors.index) {
      errors = errors.concat(this.props.errors.index)
    }
    if (this.props.errors.destroy) {
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderUserListItem = ({id, name, email}) =>
    <Tooltip key={`tooltip_user_${id}`} title="Edit user">
      <ListItem button onClick={this.editUser(id)} key={id}>
        <ListItemIcon>
          <PersonIcon/>
        </ListItemIcon>
        <ListItemText primary={name} secondary={email}/>
        <ListItemSecondaryAction>
          <Tooltip title="Delete">
            <IconButton onClick={this.deleteUser(id)}><DeleteIcon/></IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    </Tooltip>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>


  render = () =>
    <PageContainer>
      <ActionHeader title="Users">
        <Button color="primary" variant="contained" onClick={() => this.props.history.push('/users/new')}>Add</Button>
      </ActionHeader>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageChange={this.handlePageChange}
                  style={{}} linkStyle={{}}/>
      <LoadedCard>
        {this.users &&
          <MuiList dense>
            {this.users.map(this.renderUserListItem)}
          </MuiList>
        }
      </LoadedCard>
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageChange={this.handlePageChange}
                  style={{}} linkStyle={{}}/>
    </PageContainer>
}

export default compose(
  Dependent({loader: true}),
  connectQueryString('users'),
  connect(({users}) => users)
)(List)
