import React, {Component} from 'react'
import {connect} from 'react-redux'
import {NotificationActions, UserActions} from 'actionsets'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import Dependent from 'containers/shared/Dependent'
import {FormContext, Select} from 'components'
import PageContainer from 'components/PageContainer'
import LoadedHeader from 'components/LoadedHeader'
import LoadedContent from 'components/LoadedContent'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel'
import {compose, humanize} from 'utils'
import withStyles from 'styles'
import MenuItem from '@material-ui/core/MenuItem'
import PromiseButton from 'components/PromiseButton'
import {ListSubheader} from "@material-ui/core";
import Button from "@material-ui/core/Button";

const recipientGroupSelections = ['all', 'all_irrigators', 'all_operators', 'all_regulators']

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    NotificationActions.bindActions(this)
    UserActions.bindActions(this, 'users')
  }

  state = {
    recipientsOpen: false,
    formAttributes: {
      recipients: [],
      notificationType: 'regular',
      delivery: 'app'
    }
  }

  async dependsOn() {
    await Promise.all(
      [
        this.actions.users.index({page: 'all'}),
        super.dependsOn()
      ]
    )
  }

  get formObject(){
    return {...this.props.notification, ...this.state.formAttributes}
  }

  get nonDirectUserRecipients() {
    return ['all', 'all_irrigators', 'all_operators', 'all_regulators']
  }

  handleRecipientChange = (event) => {
    let recipients = event.target.value.filter(e => e != null)
    let newFormState;
    if(recipientGroupSelections.includes(recipients[recipients.length - 1])){
      newFormState = {...this.state.formAttributes, ...{recipients: [recipients.pop()]}}
      this.setState({recipientsOpen: false})
    } else {
      newFormState = {...this.state.formAttributes, ...{recipients: recipients.filter((r) => !recipientGroupSelections.includes(r))}}
    }
    this.setState({formAttributes: newFormState})
  }

  handleTypeChange = (event) => {
    let notificationType = event.target.value
    let newFormState = {
      ...this.state.formAttributes,
      delivery: 'app',
      notificationType: notificationType,
      uploadedFile: null,
      uploadedFileName: null
    };
    this.setState({formAttributes: newFormState})
  }

  handleFileSelected = ({target: { files: { "0": uploadedFile }}}) => {
    this.handleFormObjectChange({
      ...this.formObject,
      uploadedFile,
      uploadedFileName: uploadedFile.name
    })
  }

  render = () =>
    <PageContainer>
      <LoadedHeader name={this.formObject.name}>
        {this.editMode ? `Edit Notification` : 'New Notification'}
      </LoadedHeader>
      <LoadedContent>
        <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
          {this.renderErrorMessages()}
          <Select
            member='notificationType'
            label='Notification Type'
            value={this.state.formAttributes.notificationType}
            onChange={this.handleTypeChange}
            fullWidth
          >
            <MenuItem value='regular'>Regular Notification</MenuItem>
            <MenuItem value='priority'>Priority Notification</MenuItem>
          </Select>
          <TextField member='title' fullWidth/>
          <TextField member='message' multiline={true} rows={5} fullWidth/>
          {
            this.state.formAttributes.notificationType === 'regular' &&
            <>
              <FormLabel component="legend">Delivery</FormLabel>
              <RadioGroup member='delivery' label='Delivery'>
                <FormControlLabel value="app" control={<Radio />} label="In App" />
                <FormControlLabel value="email" control={<Radio />} label="In App + Email" />
              </RadioGroup>
            </>
          }
          {
            this.state.formAttributes.notificationType === 'priority' &&
            <>
              <FormLabel component="legend">File</FormLabel>
              <TextField disabled value={this.formObject.uploadedFileName || 'Select a file to upload'}/>
              <input
                  accept="*/*"
                  className={this.props.classes.uploadInput}
                  onChange={this.handleFileSelected}
                  id="contained-button-file"
                  type="file"
              />
              <label htmlFor="contained-button-file">
                <Button className={this.props.classes.uploadButton} color='secondary' component="span" variant="contained">
                  Select
                </Button>
              </label>
            </>
          }
          <Select
            multiple
            fullWidth
            onChange={this.handleRecipientChange}
            value={this.state.formAttributes.recipients}
            member='recipients'
            label='Recipients'
            open={this.state.recipientsOpen}
            onOpen={() => this.setState({recipientsOpen: true})}
            onClose={() => this.setState({recipientsOpen: false})}
            MenuProps={MenuProps}
          >
            <ListSubheader>Groups</ListSubheader>
            {this.nonDirectUserRecipients.map((recipient) =>
              <MenuItem key={recipient} value={recipient}>{humanize(recipient)}</MenuItem>
            )}
            <ListSubheader>Users</ListSubheader>
            {this.props.users.sort((a, b) => {
              const nameA = a.name.toUpperCase()
              const nameB = b.name.toUpperCase()
              if (nameA < nameB) { return -1 }
              if (nameA > nameB) { return 1 }
              return 0
            }).map((user) =>
                <MenuItem key={user.id} value={String(user.id)}>{`${user.name} (${user.email})`}</MenuItem>)}
          </Select>
          <PromiseButton disabled={this.state.formAttributes.recipients.length === 0} color='secondary' fullWidth variant='contained' type='submit'>Send</PromiseButton>
        </FormContext>
      </LoadedContent>
    </PageContainer>
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  },
  uploadButton: {
    margin: "-6px 0 3px 0"
  },
  uploadInput: {
    display: 'none'
  }
}

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({notifications, users}) => ({...users, ...notifications})),
)(Form)

