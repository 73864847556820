import React, {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'
import {TokenActions, VersionActions} from 'actionsets'
import {connect} from 'react-redux'
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography';
import PeopleIcon from '@material-ui/icons/People'
import AccountIcon from '@material-ui/icons/AccountBox'
import FaceIcon from '@material-ui/icons/Face'
import AuditIcon from '@material-ui/icons/List'
import TransactionIcon from '@material-ui/icons/SwapHoriz'
import WashupIcon from '@material-ui/icons/SettingsBackupRestore'
import IrrigatorIcon from '@material-ui/icons/Waves'
import SchemeIcon from '@material-ui/icons/Terrain'
import StoredWaterIcon from '@material-ui/icons/SaveAlt'
import ContractedWaterIcon from '@material-ui/icons/BarChart'
import ReleaseRequestIcon from '@material-ui/icons/LocalDrink'
import DocumentIcon from '@material-ui/icons/Description'
import NotificationIcon from '@material-ui/icons/Inbox'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import RequestApprovalIcon from '@material-ui/icons/AssignmentTurnedIn'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge'
import {Authorization, compose} from 'utils'
import withStyles from 'styles'
import NotificationPopup from 'containers/notifications/NotificationsPopup'
import PriorityNotifications from 'containers/notifications/PriorityNotifications'
import Tooltip from '@material-ui/core/Tooltip'

export class Nav extends Component{

  constructor(props){
    super(props)
    TokenActions.bindActions(this, 'tokens')
    VersionActions.bindActions(this, 'versions')
    this.state = {drawerOpen: false}
  }

  state = {mounted: false}

  componentDidMount(){
    this.actions.versions.getTag().catch(() => { /* no-op to prevent uncaught runtime error */ })
    setTimeout(() => {
      this.setState({mounted: true})
    })
  }

  handleRequestOpen = itemName => event => {
    this.setState({ [`${itemName}Open`]: !this.open(itemName), anchorEl: event.currentTarget });
  }

  handleRequestClose = itemName => () => {
    this.setState({ [`${itemName}Open`]: false});
  }

  open = itemName => !!this.state[`${itemName}Open`]

  get path() {
    return window.location.pathname
  }

  render = () =>
    <Fragment>
      <NotificationPopup show={this.open('notifications')} onClose={this.handleRequestOpen('notifications')} />
      <PriorityNotifications/>
      <AppBar position="sticky" className={this.props.classes({root: true, transition: !this.state.mounted})}>
        <Toolbar>
          <div className={this.props.classes.headerItem} onClick={this.handleRequestOpen('drawer')}>
            <img src="/menu_icon.png" alt="" className={this.props.classes.menuIcon} />
            <span>Menu</span>
          </div>
          <div className={this.props.classes('headerItem', 'headerTitle')}>
            <Typography className={this.props.classes.title} variant="h1" color="inherit">
              <Link to='/'>
                <img src="/logo.png" alt="Manawa Energy" className={this.props.classes.logoImg}/>
              </Link>
              <span>Stored Water Information Management System</span>
            </Typography>
          </div>
          <Tooltip title="Notifications">
            <div className={this.props.classes('headerItem', 'iconItem')} onClick={this.handleRequestOpen('notifications')}>
              <Badge badgeContent={this.props.unread} color="error" max={99}
                     className={this.props.classes.iconItemBadge}>
                <NotificationIcon />
              </Badge>
            </div>
          </Tooltip>
          <div className={this.props.classes.headerItem} onClick={this.handleRequestOpen('userMenu')}>
            {Authorization.user.name}
          </div>
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchorEl}
        open={this.open('userMenu')}
        onClose={this.handleRequestClose('userMenu')}
      >
        {/*Hack to align menu in Firefox because first child is indented */}
        <div style={{display: 'none'}}></div>
        <Link to={`/profile`}>
          <MenuItem onClick={this.handleRequestClose('userMenu')}>
            <ListItemIcon><FaceIcon/></ListItemIcon>
            <ListItemText primary="My Profile"/>
          </MenuItem>
        </Link>
        <MenuItem onClick={this.actions.tokens.destroy}>
          <ListItemIcon><ExitToAppIcon/></ListItemIcon>
          <ListItemText primary="Logout"/>
        </MenuItem>
      </Menu>
      <Drawer
        anchor="left"
        open={this.state.drawerOpen}
        onClose={() => this.setState({drawerOpen: false})}
        onClick={() => this.setState({drawerOpen: false})}
        className={this.props.classes.drawer}
      >
        <Link to={`/notifications/board`}>
          <ListItem button selected={this.path === '/notifications/board'}>
            <ListItemIcon>
              <NotificationsIcon/>
            </ListItemIcon>
            <ListItemText primary="Notification Board"/>
          </ListItem>
        </Link>

        {(Authorization.admin || Authorization.irrigator || Authorization.viewer) &&
        <Link to={`/irrigators/accounts`}>
          <ListItem button selected={this.path.match('^/irrigators/(\\d+/)?accounts$') !== null}>
            <ListItemIcon>
              <AccountIcon/>
            </ListItemIcon>
            <ListItemText primary="Account Balances"/>
          </ListItem>
        </Link>
        }

        {(Authorization.admin || Authorization.irrigator || Authorization.viewer) &&
        <Link to='/irrigator_daily_entries'>
          <ListItem button selected={this.path.match('^/irrigator(_|(s/\\d+/))daily_entries$') !== null}>
            <ListItemIcon>
              <ReleaseRequestIcon/>
            </ListItemIcon>
            <ListItemText primary="Water Log"/>
          </ListItem>
        </Link>
        }

        {(Authorization.admin || Authorization.operator || Authorization.viewer) &&
        <Link to='/schemes/request_approval'>
          <ListItem button selected={this.path.match('^/schemes/(\\d+/)?request_approval$') !== null}>
            <ListItemIcon>
              <RequestApprovalIcon/>
            </ListItemIcon>
            <ListItemText primary="Request Approval"/>
          </ListItem>
        </Link>
        }

        {(Authorization.admin || Authorization.regulator || Authorization.viewer) &&
        <Link to='/schemes/stored_water'>
          <ListItem button selected={this.path.match('^/schemes/(\\d+/)?stored_water$') !== null}>
            <ListItemIcon>
              <StoredWaterIcon/>
            </ListItemIcon>
            <ListItemText primary="Stored Water"/>
          </ListItem>
        </Link>
        }

        {(Authorization.admin || Authorization.contractedWaterViewer) &&
        <Link to='/schemes/contracted_water'>
          <ListItem button selected={this.path.match('^/schemes/(\\d+/)?contracted_water$') !== null}>
            <ListItemIcon>
              <ContractedWaterIcon/>
            </ListItemIcon>
            <ListItemText primary="Contracted Water"/>
          </ListItem>
        </Link>
        }

        {(Authorization.admin || Authorization.viewer) &&
        <Link to='/schemes/transactions'>
          <ListItem button selected={this.path.match('^/schemes/(\\d+/)?transactions$') !== null}>
            <ListItemIcon>
              <TransactionIcon/>
            </ListItemIcon>
            <ListItemText primary="Transactions"/>
          </ListItem>
        </Link>
        }

        {Authorization.admin &&
        <Link to='/washup_entries'>
          <ListItem button selected={this.path.match('^/washup_entries(/(new|(\\d+/edit)))?$') !== null}>
            <ListItemIcon>
              <WashupIcon/>
            </ListItemIcon>
            <ListItemText primary="Washups"/>
          </ListItem>
        </Link>
        }

        {Authorization.admin &&
        <Link to='/notifications'>
          <ListItem button selected={this.path.match('^/notifications(/(new|(\\d+/edit)))?$') !== null}>
            <ListItemIcon>
              <NotificationIcon/>
            </ListItemIcon>
            <ListItemText primary="Notifications"/>
          </ListItem>
        </Link>
        }

        {Authorization.admin &&
        <Link to='/accounts'>
          <ListItem button selected={this.path.match('^/accounts(/(new|(\\d+/edit)))?$') !== null}>
            <ListItemIcon>
              <AccountIcon/>
            </ListItemIcon>
            <ListItemText primary="Accounts"/>
          </ListItem>
        </Link>
        }

        {Authorization.admin &&
        <Link to='/irrigators'>
          <ListItem button selected={this.path.match('^/irrigators(/(new|(\\d+/edit)))?$') !== null}>
            <ListItemIcon>
              <IrrigatorIcon/>
            </ListItemIcon>
            <ListItemText primary="Irrigators"/>
          </ListItem>
        </Link>
        }

        {Authorization.admin &&
        <Link to='/schemes'>
          <ListItem button selected={this.path.match('^/schemes(/(new|(\\d+/edit)))?$') !== null}>
            <ListItemIcon>
              <SchemeIcon/>
            </ListItemIcon>
            <ListItemText primary="Schemes"/>
          </ListItem>
        </Link>
        }

        {Authorization.admin &&
        <Link to='/users'>
          <ListItem button  selected={this.path.match('^/users(/(new|(\\d+/edit)))?$') !== null}>
            <ListItemIcon>
              <PeopleIcon/>
            </ListItemIcon>
            <ListItemText primary="Users"/>
          </ListItem>
        </Link>
        }

        {Authorization.admin &&
        <Link to='/audits'>
          <ListItem button selected={this.path === '/audits'}>
            <ListItemIcon>
              <AuditIcon/>
            </ListItemIcon>
            <ListItemText primary="Audit logs" />
          </ListItem>
        </Link>
        }

        {Authorization.admin &&
          <Link to='/apidocs'>
            <ListItem button selected={this.path === '/apidocs'}>
              <ListItemIcon>
                <DocumentIcon/>
              </ListItemIcon>
              <ListItemText primary="Api Docs" />
            </ListItem>
          </Link>
        }
        <div className={this.props.classes.version}>
          API {this.props.tag} / CLIENT {process.env.REACT_APP_VERSION}
        </div>
      </Drawer>
    </Fragment>
}

const styles = {
  root: {
    transition: 'transform 500ms',
    transitionDelay: '200ms',
  },
  transition: {
    transform: 'translateY(-100%)',
  },
  title: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  version: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    fontSize: 11,
    color: "#9a9a9a",
    '@media(max-height: 720px)': {
      display: 'none'
    }
  },
  logoImg: {
    height: 27,
    verticalAlign: 'middle',
    marginRight: 27
  },
  headerItem: {
    '@media(min-width: 600px)': {
      paddingLeft: 27,
      paddingRight: 27,
    },
    paddingLeft: 18,
    paddingRight: 18,
    borderRight: '1px solid rgba(255, 255, 255, 0.3)',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:last-child': {
      borderRight: 'none'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)'
    },
    '& span': {
      '@media(min-width: 700px)': {
        display: 'inline'
      },
      display: 'none'
    }
  },
  iconItem: {
    paddingLeft: 15,
    paddingRight: 15,
    '& span': {
      display: 'inherit'
    }
  },
  iconItemBadge: {
    '& span': {
      top: 0,
      right: 0,
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 5,
      paddingRight: 5
    }
  },
  headerTitle: {
    flex: 1,
    cursor: 'auto',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& h1': {
      color: 'white',
      fontSize: '1rem',
      fontWeight: '600',
    },
    paddingLeft: 18,
    paddingRight: 18,
  },
  drawer: {
    '& a > div:not(:hover)': {
      backgroundColor: 'transparent !important'
    }
  },
  menuIcon: {
    width: 20,
    height: 16,
    verticalAlign: 'middle',
    '@media(min-width: 700px)': {
      marginRight: 18
    }
  },
}

export default compose(
  connect(({notifications: {unread}, version: { tag }}) => ({ unread, tag })),
  withStyles(styles)
)(Nav)
