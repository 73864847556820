import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import withStyles from 'styles'
import Checkbox from '@material-ui/core/Checkbox'
import {humanize} from 'utils'
import Typography from '@material-ui/core/Typography'
import shallowEqual from 'shallowequal'

class MultiSelect extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    labelProvider: PropTypes.func,
    valueProvider: PropTypes.func,
    equalityProvider: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([''])]),
    options: PropTypes.array,
    defaultOptions: PropTypes.array,
    unsetWhenDefault: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    classes: {},
    defaultOptions: [],
    labelProvider: option => option.name || humanize(option),
    valueProvider: option => option.id || option,
    equalityProvider: (option1, option2) => shallowEqual(option1, option2)
  }

  get value() {
    return this.props.value || this.props.defaultOptions
  }

  valueIsDefault(value) {
    const defaultOptions = this.props.defaultOptions || []
    return value.length === defaultOptions.length && value.every(o => defaultOptions.includes(o))
  }

  getResult = value => {
    return this.props.unsetWhenDefault && this.valueIsDefault(value) ? undefined : value
  }

  onValueChange = option => ({target: {checked}}) => {
    const newValue = checked ? [...this.value, option] : this.value.filter(o => !this.props.equalityProvider(o, option))
    this.props.onChange({target: {value: this.getResult(newValue)}})
  }

  includesOption = option => {
    return this.value.some(o => this.props.equalityProvider(o, option))
  }

  render = () => {
    const {className, classes, options, labelProvider, valueProvider, disabled} = this.props
    return (
      <ul className={[className, classes.options].filter(c => c).join(' ')}>
        {options.map((option, i) =>
          <Typography className={classes.option} headlineMapping={{body2: 'li'}} key={i}>
            <Checkbox
              className={classes.checkbox}
              onChange={this.onValueChange(valueProvider(option, i))}
              checked={this.includesOption(valueProvider(option, i))}
              disabled={disabled}
            />
            <span className={classes.label}>{labelProvider(option, i)}</span>
          </Typography>
        )}
      </ul>
    )
  }
}

const styles = {
  options: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  option: {
    margin: 0,
    display: 'inline-block',
  },
  checkbox: {
    marginLeft: -12,
  },
  label: {
    marginRight: 25,
  }
}

export default withStyles(styles)(MultiSelect)
