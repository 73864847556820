import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {IrrigatorActions} from 'actionsets'
import {Dependent} from 'containers/shared'
import withStyles from 'styles'
import PageContainer from 'components/PageContainer'
import RightSideContent from 'components/RightSideContent'
import Typography from '@material-ui/core/Typography'
import Balances from 'components/irrigator/Balances'
import {Authorization} from 'utils'
import Select from 'components/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ContractedVsAvailable from 'components/charts/ContractedVsAvailable'
import AvailableBalance from 'components/charts/AvailableBalance'
import ExpandButton from 'components/ExpandButton'
import Collapse from '@material-ui/core/Collapse'
import moment from 'moment'
import AccountTransactions from 'containers/transactions/AccountTransactions'
import Timestamp from 'components/Timestamp'
import ActionHeader from 'components/ActionHeader'
import HelpButton from 'components/HelpButton'

const SeasonStartMonth = 4

class AccountView extends Component {
  state = {
    month: 0,
    season: moment().month() > 4 ? moment().year() : (moment().year() - 1),
    availableExpanded: true,
  }

  constructor(props) {
    super(props)
    IrrigatorActions.bindActions(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.irrigatorId !== prevProps.match.params.irrigatorId) {
      this.loadData()
    }
  }

  dependsOn = () => {
    this.actions.index()
    if (this.irrigatorId)
      return this.loadData()
  }

  dependenciesMet = () => {
    return !this.irrigatorId || `${this.props.irrigator.id}` === `${this.irrigatorId}`
  }

  loadData = () => {
    if (this.irrigatorId) {
      return Promise.all([
        this.actions.show(this.irrigatorId, {include: 'accounts'}),
        this.actions.dailySummaries(this.irrigatorId, {dates: this.dateRange})
      ])
    }
  }

  handleDateChange = part => ({target: {value}}) => {
    this.setState({[part]: value}, () => {
      this.actions.dailySummaries(this.irrigatorId, {dates: this.dateRange})
    })
  }

  handleToggleExpand = name => () => {
    this.setState({[`${name}Expanded`]: !this.state[`${name}Expanded`]})
  }

  get irrigatorId() {
    return this.props.match.params.irrigatorId || Authorization.user.irrigatorId
  }

  get years() {
    if (!this._years) {
      this._years = []
      if (moment().month() > SeasonStartMonth)
        this._years.push(moment().year())
      for (let i = moment().year() - 1; i >= 2013; i--) {
        this._years.push(i)
      }
    }
    return this._years
  }

  get startOfDay() {
    return moment().add(-1, 'day').endOf('day')
  }

  get months() {
    if (!this._months) {
      const names = moment.months()
      this._months = [{value: 0, name: 'Whole Season'}]
      for (let i = 0; i < 12; i++) {
        const index = (i + SeasonStartMonth) % 12
        this._months.push({value: index + 1, name: names[index]})
      }
    }
    return this._months
  }

  get dateRange() {
    const {month, season} = this.state
    if (month) {
      const year = month > SeasonStartMonth ? season : season + 1
      const date = moment({year, month: month - 1})
      return {start: date.startOf('month').format(), end: date.endOf('month').format()}
    } else {
      const start = moment({year: season, month: SeasonStartMonth}).startOf('month').format()
      const end = moment({year: season + 1, month: SeasonStartMonth - 1}).endOf('month').format()
      return {start, end}
    }
  }

  handleChangeIrrigator = ({target: {value}}) => {
    this.props.history.replace(`/irrigators/${value}/accounts`)
  }

  render = () => {
    const {classes, irrigator} = this.props
    const {month, season, availableExpanded} = this.state
    return (
      <PageContainer>
        <Typography variant='h5' className={classes.title}>
          {Authorization.irrigator ? 'My Accounts' : 'Account Balances'}
        </Typography>
        <div className={classes.irrigatorSelect}>
          {!Authorization.irrigator &&
            <Select value={this.irrigatorId || ''} onChange={this.handleChangeIrrigator} label='Irrigator'>
              {this.props.irrigators.map(irrigator =>
                <MenuItem key={irrigator.id} value={irrigator.id}>{irrigator.name}</MenuItem>
              )}
            </Select>
          }
        </div>
        {this.irrigatorId &&
          <Fragment>
            <RightSideContent>
              <div className={classes.section}>
                <ActionHeader title='Account Balances' variant='subtitle1'>
                  <HelpButton>
                    Stored Water Account Balance for transactions up to and including today (excludes Releases occurring
                    today)
                  </HelpButton>
                </ActionHeader>
                <Timestamp timestamp={this.startOfDay}/>
                <Balances irrigator={irrigator}/>
              </div>
              <div className={classes.section}>
                <Typography variant='subtitle1'>Contracted vs. Available</Typography>
                <Timestamp timestamp={this.startOfDay}/>
                <ContractedVsAvailable irrigator={irrigator}/>
              </div>
            </RightSideContent>
            <ActionHeader style={{marginTop: 20}} title='Available Balance' variant='h6' startAdornment={
              <ExpandButton onClick={this.handleToggleExpand('available')} expanded={availableExpanded}/>
            }>
              <HelpButton>
                Stored water balance projection (historical, current and future) for credits, requests releases.
              </HelpButton>
            </ActionHeader>
            <Collapse in={availableExpanded} style={{marginBottom: 30}}>
              <Select label='Season' value={season} onChange={this.handleDateChange('season')}>
                {this.years.map(year =>
                  <MenuItem key={year} value={year}>{year} - {year + 1}</MenuItem>
                )}
              </Select>
              <Select label='Month' value={month} onChange={this.handleDateChange('month')}>
                {this.months.map(month =>
                  <MenuItem key={month.value} value={month.value}>{month.name}</MenuItem>
                )}
              </Select>
              <AvailableBalance dailySummaries={this.props.dailySummaries}/>
            </Collapse>
            <AccountTransactions irrigators={[irrigator]} onChange={this.loadData}/>
          </Fragment>
        }
      </PageContainer>
    )
  }
}

const styles = {
  section: {
    marginBottom: 50,
  },
  title: {
    marginBottom: 0
  },
  irrigatorSelect: {
    marginBottom: 28,
    '@media(min-width: 600px)': {
      marginBottom: 47,
    },
  },
}

export default connect(({irrigators}) => ({...irrigators}))(withStyles(styles)(Dependent(AccountView)))
