import React, {Component} from 'react'
import withStyles from 'styles'
import {connect} from 'react-redux'
import {compose} from 'utils'
import PageContainer from 'components/PageContainer'
import Typography from '@material-ui/core/Typography'
import {SchemeView, Dependent, connectQueryString} from 'containers/shared'
import {SchemeSeasonActions, ContractedEntitlementActions} from 'actionsets'
import Select from 'components/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import ContractedWater from 'components/charts/ContractedWater'
import ContractedEntitlement from 'components/charts/ContractedEntitlement'
import ActionHeader from 'components/ActionHeader'
import ExportButton from 'components/index_view/ExportButton'

class StoredWaterView extends Component {
  state = {
    schemeSeasonId: null
  }

  constructor(props) {
    super(props)
    SchemeSeasonActions.bindActions(this)
    ContractedEntitlementActions.bindActions(this, 'contractedEntitlements')
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.schemeSeasonId !== this.state.schemeSeasonId || prevProps.scheme !== this.props.scheme) {
      return this.loadData()
    }
  }

  dependsOn = () => {
    return Promise.all([
      this.loadData(),
      this.actions.contractedEntitlements.index({pageSize: 100})
    ])
  }

  dependenciesMet = () => {
    return this.props.dailySummaries && this.props.dailySummaries.length > 0
  }

  loadData = () => {
    if (this.schemeSeason)
      return this.actions.dailySummaries(this.schemeSeason.id)
  }

  handleSeasonChange = ({target: {value}}) => {
    this.setState({schemeSeasonId: value})
  }

  get schemeSeasons() {
    return (this.props.scheme.schemeSeasons || []).filter(season => season.useContractedEntitlement)
  }

  get schemeSeason() {
    return this.state.schemeSeasonId ? (
      this.schemeSeasons.find(({id}) => id === this.state.schemeSeasonId)
    ) : (
      this.schemeSeasons[0]
    )
  }

  get currentDailySummary() {
    return (this.props.dailySummaries || []).find(({date}) => moment(date).isSame(moment(), 'day'))
  }

  render = () => {
    const {classes, schemeSelect} = this.props
    return (
      <PageContainer>
        <Typography variant='h5'>Contracted Water</Typography>
        {schemeSelect}
        <Select label='Season' value={(this.schemeSeason || {id: null}).id} onChange={this.handleSeasonChange}>
          {this.schemeSeasons.map(season => (
            <MenuItem key={season.id} value={season.id}>
              {moment(season.startsOn).year()}-{moment(season.endsOn).year()}
            </MenuItem>
          ))}
        </Select>
        {this.schemeSeason &&
        <div className={classes.content}>
          <div className={classes.section}>
            <ActionHeader
              title={`Contracted Water: ${moment(this.schemeSeason.startsOn).year()}-${moment(this.schemeSeason.endsOn).year()}`}
              variant='subtitle1'
            >
              <ExportButton resource={`scheme_seasons/${this.schemeSeason.id}/daily_summaries`} />
            </ActionHeader>
            <ContractedWater dailySummaries={this.props.dailySummaries} schemeSeason={this.schemeSeason} />
          </div>
          <div>
            <ActionHeader title="RRIA Entitlement" variant='subtitle1'>
              <ExportButton resource="contracted_entitlements" />
            </ActionHeader>
            <ContractedEntitlement
              contractedEntitlements={this.props.contractedEntitlements}
              currentDailySummary={this.currentDailySummary}
            />
          </div>
        </div>
        }
      </PageContainer>
    )
  }
}

const styles = {
  content: {
    paddingTop: 20,
    '& > $section': {
      marginBottom: 35,
    }
  },
  schemeSelect: {
    maxWidth: 400
  },
  section: {
    marginBottom: 50,
  },
  subTitle: {
    marginBottom: 20,
  },
  chartDate: {
    whiteSpace: 'nowrap'
  }
}

export default compose(
  Dependent,
  SchemeView({path: 'contracted_water', include: 'schemeSeasons'}),
  withStyles(styles),
  connectQueryString({customQueryParams: ['year', 'month', 'infoDate']}),
  connect(({contractedEntitlements, schemeSeasons}) => ({...contractedEntitlements, ...schemeSeasons}))
)(StoredWaterView)
