import React, { Component } from 'react'
import qs from "qs";
import { withRouter } from 'react-router-dom'
import { IndexContext } from 'components/index_view/IndexContext'

class QueryStringProvider extends Component {

  serializeUrlState = ({filter, page, order, queryParams}) => {
    const qsObject = {...queryParams, ...filter} || {}
    Object.keys(qsObject).forEach((key) => {
      if (qsObject[key] === '' || qsObject === undefined || (this.props.filter || {})[key] !== undefined)
        delete qsObject[key]
    })
    if (page > 1)
      qsObject.page = page
    if (order)
      qsObject.order = order

    return this.updateNameSpaceAndSerialize(qsObject)
  }

  updateNameSpaceAndSerialize(state) {
    const qsObject = qs.parse(window.location.search.slice(1), { allowDots: true })
    const nameSpaces = {defaultNameSpace: {}}
    Object.keys(qsObject).forEach(key => {
      if (typeof(qsObject[key]) === 'object') {
        nameSpaces[key] = qsObject[key]
      }
      else {
        nameSpaces.defaultNameSpace[key] = qsObject[key]
      }
    })
    nameSpaces[this.props.nameSpace || 'defaultNameSpace'] = state
    const {defaultNameSpace, ...otherNameSpaces} = nameSpaces
    return qs.stringify({...otherNameSpaces, ...defaultNameSpace}, {arrayFormat: 'repeat', allowDots: true})
  }

  updateUrlState = (state, callback) => {
    this.props.history.replace(window.location.pathname + '?' + this.serializeUrlState(state))
    this.forceUpdate(callback)
  }

  get urlState() {
    if (window.location.search) {
      const queryParams = getNameSpace(qs.parse(window.location.search.slice(1), { allowDots: true }))(this.props.nameSpace)
      const {page, order, ...filter} = queryParams
      Object.keys(filter).forEach(key => {
        if (this.props.customQueryParams.includes(key))
          delete filter[key]
      })
      Object.keys(queryParams).forEach(key => {
        if (!this.props.customQueryParams.includes(key))
          delete queryParams[key]
      })
      return {
        query: window.location.search,
        queryParams,
        page: Number(page) || 1,
        order: order,
        filter: filter
      }
    }
    return {page: 1, filter: {}, order: null, queryParams: {}}
  }

  handleOrderChange = (order, callback) => {
    this.updateUrlState({...this.urlState, order, page: 1}, callback)
  }

  handleFilterChange = (filter, callback) => {
    this.updateUrlState({...this.urlState, filter, page: 1}, callback)
  }

  handlePageChange = (page, callback) => {
    this.updateUrlState({...this.urlState, page}, callback)
  }

  handleQueryParamsChange = (queryParams, callback) => {
    this.updateUrlState({...this.urlState, queryParams}, callback)
  }

  render = () => {
    const {type: Type, customQueryParams, ...props} = this.props
    const {order, query, queryParams, page, filter} = this.urlState
    return (
      <IndexContext.Provider value={{
        order, page, filter,
        onOrderChange: this.handleOrderChange,
        onFilterChange: this.handleFilterChange,
        onPageChange: this.handlePageChange
      }}>
        <Type
          {...props}
          query={query}
          queryParams={queryParams}
          order={order}
          page={page}
          filter={{...this.props.filter, ...filter}}
          onOrderChange={this.handleOrderChange}
          onFilterChange={this.handleFilterChange}
          onPageChange={this.handlePageChange}
          onQueryParamsChange={this.handleQueryParamsChange}
        />
      </IndexContext.Provider>
    )
  }
}

const getNameSpace = object => nameSpace => {
  return (nameSpace ? object[nameSpace] : object) || {}
}

const QueryStringComponent = withRouter(QueryStringProvider)
export default ({nameSpace, customQueryParams = []} = {}) => Type => props =>
  <QueryStringComponent type={Type} nameSpace={nameSpace} customQueryParams={customQueryParams} {...props}/>
