import React, {Component} from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from 'styles'

export class ActionHeader extends Component {
  static defaultProps = {
    variant: 'h5'
  }

  render = () => {
    const {classes, className, startAdornment, children, variant, title, ...props} = this.props
    return (
      <div className={classes('root', variant)}>
        <Typography className={classes('typography', className)} variant={variant} {...props}>
          {startAdornment}{title}
        </Typography>
        {children}
      </div>
    )
  }
}

const styles = ({typography}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginBottom: 0,
      marginTop: -2,
    }
  },
  typography: {
    flex: 1,
    marginBottom: 0,
  },
  ...margins(typography)
})

const margins = typography => {
  const margins = {}
  Object.keys(typography).forEach(key => {
    const {marginBottom} = typography[key]
    if (marginBottom)
      margins[key] = {marginBottom}
  })
  return margins
}

export default withStyles(styles)(ActionHeader)