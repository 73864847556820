import React, {Component} from 'react'
import {connect} from 'react-redux'
import {NotificationActions, UserActions} from 'actionsets'
import Dependent from 'containers/shared/Dependent'
import {compose} from 'utils'
import {connectQueryString} from 'containers/shared'
import NotificationsList from './NotificationsList'

export class List extends Component {

  constructor(props) {
    super(props)
    NotificationActions.bindActions(this)
    UserActions.bindActions(this, 'users')
  }

  dependsOn() {
    return Promise.all([
      this.actions.users.index({page: 'all'}),
      this.loadNotifications()
    ])
  }

  dependenciesMet() {
    return this.props.requests.length === 0
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.query !== this.props.query) {
      this.loadNotifications()
    }
  }

  loadNotifications = () => {
    return this.actions.index({
      page: this.props.page,
      filter: this.filter,
      include: 'user'
    })
  }

  get filter() {
    return this.props.filter
  }

  render = () =>
    <NotificationsList
      title='Notifications'
      totalPagesKey='totalPages'
      notificationsKey='notifications'
      errorsKeys={['index', 'destroy']}
      isAdmin={true}
    >
    </NotificationsList>
}

export default compose(
  Dependent({loader: true}),
  connectQueryString('notifications'),
  connect(({notifications, users: {users}}) => ({...notifications, users}))
)(List)
