import CrudActionSetBase from './CrudActionSetBase'
import {Notifications} from 'api'

export class NotificationActionSet extends CrudActionSetBase('notification'){
  static initialState = {
    notifications: [],
    notification: {},
    page: 1,
    totalPages: 1,
    mine: [],
    pageMine: 1,
    totalPagesMine: 1,
    board: [],
    pageBoard: 1,
    totalPagesBoard: 1,
    unreadNotifications: [],
    requests: [],
    errors: {
      index: null,
      create: null,
      update: null,
      destroy: null,
      show: null,
      mine: null,
      unread: null,
      board: null,
    }
  }

  static mine(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({page= 1, pageSize= 10, include = null} = {}) => (dispatch) => {
      return dispatch({
        type: constants.ACTION,
        promise: Notifications.mine({options: {page: { number: page, size: pageSize }, ...include && {include}}}),
      })
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { data, meta: { totalPages: totalPagesMine, page: pageMine }} }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, mine: data, totalPagesMine, pageMine }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, mine: error }
        return {...state, requests, errors }
      }
    })
  }

  static unread(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(() => (dispatch) => {
      return dispatch({
        type: constants.ACTION,
        promise: Notifications.unread(),
      })
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, unreadNotifications: data, unread: data.length }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, unread: error }
        return {...state, requests, errors }
      }
    })
  }

  static update(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((item, options={}) => {
      return {
        type: constants.ACTION,
        promise: Notifications.update({...item, options})
      }
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, update: null }}
      },
      [constants.SUCCESS]: (state, { request, result: { data, meta: {unread}={} } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const notifications = state.notifications.map(r => r.id === data.id ? data : r)
        const unreadNotifications = state.unreadNotifications.filter(r => !(r.id === data.id && data.read))
        const mine = state.mine.map(r => r.id === data.id ? data : r)
        const board = state.board.map(r => r.id === data.id ? data : r)
        return {...state, requests, notification: data, notifications, unreadNotifications, mine, board, unread}
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, update: error }
        return {...state, requests, errors }
      }
    })
  }

  static resend(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((item, options={}) => {
      return {
        type: constants.ACTION,
        promise: Notifications.resend({...item, options})
      }
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, update: null }}
      },
      [constants.SUCCESS]: (state, { request, result }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests }
      }
    })
  }

  static board(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({page = 1, pageSize = 10, include = null} = {}) => (dispatch) => {
      return dispatch({
        type: constants.ACTION,
        promise: Notifications.mine({
          options: {
            page: {number: page, size: pageSize},
            filter: {notificationType: 'priority'},
            ...include && {include}
          }
        }),
      })
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { data, meta: { totalPages: totalPagesBoard, page: pageBoard }} }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, board: data, totalPagesBoard, pageBoard }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, board: error }
        return {...state, requests, errors }
      }
    })
  }
}

export default new NotificationActionSet()
