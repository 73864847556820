import {Fragment} from 'react'
import React from 'react'

const defaults = {
  units: {
    'm3': {
      display: <Fragment>m<sup>3</sup></Fragment>,
      factor: 1,
      dp: 0
    },
    'mil-m3': {
      display: <Fragment>mil m<sup>3</sup></Fragment>,
      factor: 1e6,
      dp: 3
    },
    'm3/s': {
      display: <Fragment>m<sup>3</sup>/s</Fragment>,
      factor: 86400,
      dp: 2,
    }
  },
  native: 'm3',
  display: 'mil-m3'
}
export default React.createContext(defaults)