import React, {Component} from 'react'
import {connect} from 'react-redux'
import {TokenActions} from 'actionsets'
import {FormContext, CenteredCard, ErrorBanner} from 'components'
import {errorStringsFromError} from 'utils'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import PasswordStrength from 'components/PasswordStrength'

export class AcceptInvitation extends Component {

  state = {}

  constructor(props) {
    super(props)
    TokenActions.bindActions(this)
  }

  get token() {
    return window.location.search.replace(/^.*?token=([^&$]+).*$/, '$1')
  }

  submit = async () => {
    try {
      await this.actions.acceptInvite({...this.state, token: this.token})
      await this.actions.verify()
      this.props.history.push('/')
    } catch(err) {}
  }

  errorFor = key => {
    try {
      return this.props.errors.acceptInvite.meta[key].join(', ')
    } catch (err) {
    }
  }

  render = () =>
    <CenteredCard style={{maxWidth: 493}}>
      <FormContext context={this.state} errorContext={{
        password: this.errorFor('password'),
        passwordConfirmation: this.errorFor('passwordConfirmation'),
      }} onChange={state => this.setState(state)}>
        <CardContent>
          <Typography variant='h6'>Accept your invitation</Typography>
          {this.props.errors.acceptInvite &&
            <ErrorBanner>{errorStringsFromError(this.props.errors.acceptInvite)}</ErrorBanner>}
          <FormGroup>
            <TextField fullWidth member='password' type='password' autoFocus={true}/>
            <PasswordStrength password={this.state.password} minScore={2}/>
            <TextField fullWidth member='passwordConfirmation' type='password' label='Confirm Password'/>
          </FormGroup>
        </CardContent>
        <CardActions>
          <Button variant='contained' color='secondary' fullWidth onClick={this.submit}>Submit</Button>
        </CardActions>
      </FormContext>
    </CenteredCard>
}

export default connect(({tokens}) => tokens)(AcceptInvitation)
