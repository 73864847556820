import { ProtectedJsonAPI } from '.'

export const AccountDeltas = ProtectedJsonAPI.extend(
  '/',
  {
    index:   endpoint => endpoint.path(({irrigatorId}) => irrigatorId ? `irrigators/${irrigatorId}/account_deltas` : `account_deltas`),
    create:  endpoint => endpoint.method('post').path(() => '/account_deltas'),
    update:  endpoint => endpoint.method('put').path(({id}) => `/account_deltas/${id}`),
    destroy: endpoint => endpoint.method('delete').path(({id}) => `/account_deltas/${id}`),
    show:    endpoint => endpoint.path(({id}) => `/account_deltas/${id}`)
  }
)