import { SnackbarActions } from 'actionsets'

export default function wafMiddleware() {
  return (next) => (action) => {
    try{
      if(action.payload.error.status === 403 || action.payload.error.response.status === 403){
        setTimeout(() => next(SnackbarActions.show("You've entered data that is not allowed - please change and try again")), 100)
        next(action)
      }
    }catch(_){ /* pass */}
    return next(action)
  }
}