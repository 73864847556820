import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { WashupEntryActions, SchemeActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext }      from 'components'
import TextField from '@material-ui/core/TextField'
import { Select } from 'components'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import PageContainer from 'components/PageContainer'
import LoadedHeader from 'components/LoadedHeader'
import LoadedContent from 'components/LoadedContent'

import { compose } from 'utils'
import withStyles from 'styles'
import PromiseButton from 'components/PromiseButton'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    WashupEntryActions.bindActions(this)
    SchemeActions.bindActions(this, 'schemes')
    this.onSaveRedirect = '/washup_entries'
  }

  async dependsOn(){
    await Promise.all(
      [
        this.actions.schemes.index(),
        super.dependsOn()
      ]
    )
  }

  get formObject(){
    return {...this.props.washupEntry, schemeId: this.props.schemes.length === 1 && this.props.schemes[0].id, ...this.state.formAttributes}
  }

  handleFileSelected = ({target: { files: { "0": washupFile }}}) => {
    this.handleFormObjectChange({...this.formObject, washupFile, washupFileName: washupFile.name })
  }

  render = () =>
    <PageContainer>
      <LoadedHeader name={this.formObject.name}>
        Upload Washup File
      </LoadedHeader>
      <LoadedContent>
        <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
          {this.renderErrorMessages()}
          {
            this.props.schemes.length > 1 &&
            <Select member='schemeId' label='Scheme' fullWidth>
              {this.props.schemes.map(({id, name}) =>
                <MenuItem key={id} value={id}>{name}</MenuItem>)
              }
            </Select>
          }
          <TextField disabled value={this.formObject.washupFileName || 'Select a file to upload'}/>
          <input
            accept="text/csv"
            className={this.props.classes.uploadInput}
            onChange={this.handleFileSelected}
            id="contained-button-file"
            multiple
            type="file"
          />
          <label htmlFor="contained-button-file">
            <Button className={this.props.classes.uploadButton} color='secondary' component="span" variant="contained">
              Select
            </Button>
          </label>
          <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
        </FormContext>
      </LoadedContent>
    </PageContainer>
}

const styles = theme => ({
  uploadButton: {
    margin: "-6px 0 3px 0"
  },
  uploadInput: {
    display: 'none'
  }
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({washupEntries, schemes}) => ({...schemes, ...washupEntries}))
)(Form)
