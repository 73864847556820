import React, {Component} from 'react'
import {connect} from 'react-redux'
import {NotificationActions, SnackbarActions} from 'actionsets'
import {ErrorBanner, IndexTools, Pagination, SearchCombo} from 'components'
import NotificationIcon from '@material-ui/icons/Message'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import LoadedCard from 'components/LoadedCard'
import HelpButton from 'components/HelpButton'
import Select from 'components/Select'
import DatePicker from 'components/DatePicker'
import Button from '@material-ui/core/Button'
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {compose, errorStringsFromError, userFriendlyDate} from 'utils'
import {connectQueryString} from 'containers/shared'
import withStyles from 'styles'
import Link from '@material-ui/core/Link'
import {APIResource} from '../../services'
import Tooltip from "@material-ui/core/Tooltip";
import ResendIcon from "@material-ui/icons/Refresh";

export class NotificationsList extends Component {
  constructor(props) {
    super(props);
    NotificationActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    anchorEl: null,
    resendId: null,
    appOnlyResend: false
  }

  get notifications() {
    return this.props[this.props.notificationsKey]
  }

  get totalPages() {
    return this.props[this.props.totalPagesKey]
  }

  get errors() {
    const {errorsKeys} = this.props
    let errors = []

    errorsKeys.forEach(key => {
      if (this.props.errors[key]) {
        errors = errors.concat(this.props.errors[key])
      }
    })

    return errors
  }

  linkToAction = notification => async () => {
    // Admin can't set notification as read by clicking on it
    if (!notification.read && !this.props.isAdmin)
      await this.actions.update({...notification, read: true}, {include: 'user'})
    if (notification.linkTo)
      this.props.history.push('/' + notification.linkTo)
  }

  handleDownload = url => event => {
    window.open(`${APIResource.DEFAULT_BASE}${url}`, "_blank")
    event.stopPropagation()
  }

  // Disabled for now. Needs testing when re-enabled
  editNotification = id => event => {
    this.props.history.push(`/notifications/${id}/edit`)
    event.stopPropagation()
  }

  // Disabled for now. Needs testing when re-enabled
  deleteNotification = id => event => {
    this.actions.destroy({id})
      .then(this.loadNotifications)
      .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  handleShowResendMenu = (resendId, notificationType) => event => {
    this.setState({
      anchorEl: event.currentTarget,
      resendId,
      appOnlyResend: notificationType === 'priority'
    })
    event.stopPropagation()
    event.preventDefault()
  }

  handleResendNotification = (id, delivery) => event => {
    this.actions.resend({id}, {delivery})
      .then(this.loadNotifications)
      .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    this.setState({anchorEl: undefined})
    event.stopPropagation()
  }

  handleCloseResendMenu = () => {
    this.setState({anchorEl: null, resendId: null})
  }

  renderNotificationListItem = (notification) => {
    const {
      id, title, message, createdAt, read, typeDisplayName, user: {email}, notificationType, notificationFileUrlPath,
      notificationFileName, linkTo
    } = notification
    const {classes, isAdmin} = this.props

    return (
      <ListItem button onClick={this.linkToAction(notification)} key={id} className={this.props.classes({read})}>
        {isAdmin && this.renderResendTooltip(id, notificationType)}
        {!isAdmin &&
          <ListItemIcon>
            <NotificationIcon/>
          </ListItemIcon>
        }
        <ListItemText
          className={isAdmin ? classes.notificationText : ''}
          primary={
            <div className={classes.notificationTextPrimary}>
              <span className={classes.notificationContent}>
                {isAdmin ? `To ${email}: ` : null} {title} ({userFriendlyDate(createdAt)})
              </span>
              <span className={classes.notificationTypeContent}>
                {typeDisplayName + (notificationType === 'priority' ? ' (priority)' : '')}
              </span>
            </div>
          }
          secondary={
            <span className={classes.notificationTextSecondary}>
              {message + (linkTo ? ' →' : '')}
              {notificationFileUrlPath != null &&
                <>
                  <br/><Link onClick={this.handleDownload(notificationFileUrlPath)}>{notificationFileName}</Link>
                </>
              }
            </span>
          }
        />
      </ListItem>
    )
  }

  renderAdminHeader = () =>
    <>
      <HelpButton>
        In-app notifications are reactive and display the current value for a
        record, rather than the value at the time the notification was created
      </HelpButton>
      <Button color="primary"
              variant="contained"
              onClick={() => this.props.history.push('/notifications/new')}
      >Add</Button>
    </>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  renderSearch = () =>
    <IndexTools>
      <SearchCombo>
        <Select member='user_id' label='User' fullWidth>
          <MenuItem value={''}><em>All</em></MenuItem>
          {this.props.users.sort((a, b) => a.name > b.name).map(user =>
            <MenuItem key={user.id} value={user.id}>{`${user.name} (${user.email})`}</MenuItem>
          )}
        </Select>
        <div>
          <DatePicker label='Start Date' member='created_at.start'/>
          <DatePicker label='End Date' member='created_at.end'/>
        </div>
        <Select member='type' label='Type' fullWidth>
          <MenuItem value={''}><em>All</em></MenuItem>
          <MenuItem value={'FightingHillGorgeAdjustment'}>Fighting Hill Gorge Adjustment</MenuItem>
          <MenuItem value={'ReleaseConfirmation'}>Release Confirmation</MenuItem>
          <MenuItem value={'ReleaseRequestConfirmation'}>Release Request Confirmation</MenuItem>
          <MenuItem value={'ReleaseRequest'}>Release Request</MenuItem>
          <MenuItem value={'ReleaseReminder'}>Release Reminder</MenuItem>
          <MenuItem value={'System'}>System</MenuItem>
        </Select>
      </SearchCombo>
      {this.renderPagination()}
    </IndexTools>

  renderMenu = () =>
    <Menu
      anchorEl={this.state.anchorEl}
      open={Boolean(this.state.anchorEl)}
      onClose={this.handleCloseResendMenu}
    >
      <MenuItem onClick={this.handleResendNotification(this.state.resendId, ['app'])}>In App</MenuItem>
      <MenuItem disabled={this.state.appOnlyResend}
                onClick={this.handleResendNotification(this.state.resendId, ['email'])}>Email</MenuItem>
      <MenuItem disabled={this.state.appOnlyResend}
                onClick={this.handleResendNotification(this.state.resendId, ['app', 'email'])}>Both</MenuItem>
    </Menu>

  renderResendTooltip = (id, notificationType) =>
    <Tooltip title='Resend notification'>
      <div className={this.props.classes.listItemIcon}>
        <ListItemIcon onClick={this.handleShowResendMenu(id, notificationType)}>
          <NotificationIcon className={this.props.classes.notificationIcon}/>
        </ListItemIcon>
        <ListItemIcon>
          <ResendIcon className={this.props.classes.resendIcon}/>
        </ListItemIcon>
      </div>
    </Tooltip>

  renderPagination = () =>
    <Pagination page={this.props.page}
                totalPages={this.totalPages || 0}
                style={{}}
                linkStyle={{}}
    />

  render = () => {
    const {isAdmin} = this.props
    return (
      <PageContainer>
        <ActionHeader title={this.props.title}>
          {isAdmin && this.renderAdminHeader()}
        </ActionHeader>
        {this.renderErrorMessages()}
        {isAdmin && this.renderSearch()}
        {!isAdmin && this.renderPagination()}
        {isAdmin && this.renderMenu()}
        <LoadedCard>
          <MuiList dense>
            {this.notifications.map(this.renderNotificationListItem)}
          </MuiList>
        </LoadedCard>
        {this.renderPagination()}
      </PageContainer>
    )
  }
}

const styles = {
  read: {
    opacity: 0.6
  },
  notificationTextPrimary: {
    display: 'flex',
    flexDirection: 'row'
  },
  notificationTextSecondary: {
    whiteSpace: 'pre-wrap',
  },
  notificationContent: {
    flex: 1
  },
  notificationTypeContent: {
    color: "#283888",
    fontSize: "0.9em",
    textAlign: "right",
    marginLeft: 50,
    marginRight: -15
  },
  resendIcon: {
    position: 'absolute',
    top: 0,
    opacity: 0,
    transition: 'opacity 200ms',
    zIndex: 1,
  },
  notificationIcon: {
    position: 'absolute',
    top: 0,
    opacity: 1,
    transition: 'opacity 200ms',
    zIndex: 2,
  },
  listItemIcon: {
    position: 'relative',
    display: 'inline-block',
    '&:hover': {
      '& $resendIcon': {
        opacity: 1
      },
      '& $notificationIcon': {
        opacity: 0
      }
    }
  },
  notificationText: {
    marginLeft: 26
  }
}

export default compose(
  withStyles(styles),
  connectQueryString('notifications'),
  connect(({notifications, users: {users}}) => ({...notifications, users}))
)(NotificationsList)
