import React, {Component} from 'react'
import withStyles from 'styles'
import {PageContext} from './PageContainer'

class RightSideContent extends Component {
  render = () => {
    const {classes, className, ...props} = this.props
    return (
      <PageContext.Consumer>
        {({rightRef}) =>
          <div {...props} className={classes('root', className)} ref={rightRef}/>
        }
      </PageContext.Consumer>
    )
  }
}

const styles = {
  root: {
    '@media(min-width: 1366px)': {
      position: 'absolute',
      width: 430,
      left: 'calc(80vw - 360px)',
    },
  }
}

export default withStyles(styles)(RightSideContent)