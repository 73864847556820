import React, {Component} from 'react'
import {connect} from 'react-redux'
import {TokenActions} from 'actionsets'
import {FormContext, CenteredCard, ErrorBanner} from 'components'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import {errorStringsFromError} from 'utils'
import {PasswordPageTitles} from '../../constants'
import PasswordStrength from 'components/PasswordStrength'
import * as API from 'api'
import {Link} from 'react-router-dom'

const ResetPage = (pathName, withID = false) => class extends Component {

  state = {}

  constructor(props) {
    super(props)
    TokenActions.bindActions(this)
  }

  get token() {
    return window.location.search.replace(/^.*?token=([^$&]+).*/, '$1')
  }

  componentDidMount = async () => {
    if (pathName === 'reset') {
      try {
        await API.Tokens.resetTokenValid({token: this.token})
      } catch (err) {
        this.setState({
          error: <p>Password reset link has expired or been invalidated. Please visit <Link to="/tokens/forgot">forgot
            password</Link> to generate a new one.</p>
        })
        throw err
      }
    }
  }

  pageTitle = PasswordPageTitles[pathName.toUpperCase()] || "Reset your password"

  submit = async () => {
    await this.actions[pathName]({
      ...this.state, ...(withID ?
          {id: this.props.currentUser.id} :
          {token: this.token}
      )
    }).catch(() => { /* no-op to prevent uncaught runtime error */ })
    await this.actions.verify()
    this.props.history.push('/')
  }

  errorFor = key => {
    try {
      return this.props.errors.reset.meta[key].join(', ')
    } catch (err) {
    }
  }

  render = () =>
    <CenteredCard style={{maxWidth: 469}}>
      <FormContext context={this.state} errorContext={{
        password: this.errorFor('password'),
        passwordConfirmation: this.errorFor('passwordConfirmation'),
      }} onChange={state => this.setState(state)}
                   onSubmit={this.submit}
      >
        <CardContent>
          <Typography variant='h6'>{this.pageTitle}</Typography>
          {(this.props.errors.reset || this.state.error) &&
            <ErrorBanner>{this.state.error}{errorStringsFromError(this.props.errors.reset)}</ErrorBanner>}
          <FormGroup>
            {pathName === 'update' &&
              <TextField fullWidth member='oldPassword' type='password' label='Current Password'/>
            }
            <TextField fullWidth member='password' type='password'/>
            <PasswordStrength password={this.state.password} minScore={2}/>
            <TextField fullWidth member='passwordConfirmation' type='password'/>
          </FormGroup>
        </CardContent>
        <CardActions>
          <Button variant='contained' color='secondary' fullWidth type="submit">Submit</Button>
        </CardActions>
      </FormContext>
    </CenteredCard>
}

export const Reset = ResetPage('reset')
export const Update = ResetPage('update', true)
export default connect(({tokens}) => tokens)(Reset)
