export function compact(collection){
  return collection.filter(x => x)
}

export function flatten(collection){
  return collection.reduce(function(prev, inner) {
    inner = Array.isArray(inner) ? inner : [inner]
    const  multiLevel = (inner).some(Array.isArray);
    return prev.concat(multiLevel ? flatten(inner) : inner);
  },[]);
}

export function unique(collection){
  return collection.filter((x, i, a) => a.indexOf(x) === i)
}

export function mergeArrays(array1, array2, equalityFunction = (a,b) => a === b) {
  const result = array1.map(a => {
    const b = array2.find(b => equalityFunction(a,b))
    return b === undefined ? a : b
  })
  array2.forEach(b => {
    if (!result.find(a => equalityFunction(a, b))) {
      result.push(b)
    }
  })
  return result
}

export function collectionPresent(collection){
  return collection != null && !!collection.length
}

export function any(collection){
  return collection != null && collectionPresent(Object.values(collection).filter(x => x))
}

export function groupCollectionBy(collection, ...by){
  const grouped = {}
  collection.forEach(item => {
    const key = by.map(byValue => `${item[byValue]}`).join('::')
    grouped[key] = grouped[key] || []
    grouped[key].push(item)
  })
  return grouped
}

export function deepMerge(obj1, obj2) {
  if (!isObject(obj1) || !isObject(obj2))
    return coalesce(obj2, obj1)

  const newObj = {...obj1}
  Object.keys(obj2).forEach(key => {
    newObj[key] = deepMerge(obj1[key], obj2[key])
  });

  return newObj
}

function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item) && item !== null);
}

function coalesce(...items) {
  for (let item of items) {
    if (item !== null && item !== undefined)
      return item
  }
  return items[items.length-1]
}
