import 'url-polyfill'

class AuthorizationProxy{

  set store(value){
    this._store = value
  }

  get store(){
    this._store = this._store || require('store').default
    return this._store
  }

  get userId(){
    return this.user.id
  }

  get user(){
    const user = this.store.getState().tokens.currentUser
    return user
  }

  get admin(){
    return (this.user.type === 'admin')
  }

  get member(){
    return (this.user.type !== 'admin')
  }

  get operator(){
    return (this.user.type === 'operator')
  }

  get regulator(){
    return (this.user.type === 'regulator')
  }

  get irrigator(){
    return (!!this.user.irrigatorId)
  }

  get viewer() {
    return (this.user.type === 'viewer')
  }

  get contractedWaterViewer() {
    return (this.user.contractedWaterViewer)
  }

  hasOneOf(...roles){
    return roles.some(role => this[role])
  }
}

export const Authorization = new AuthorizationProxy()

export const authorizedLink = (urlString) => {
  let url = new URL(urlString, window.location.origin)
  url.searchParams.append('Authorization', global.localStorage.auth)
  return url.toString()
}
