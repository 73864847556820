import React, {Component} from 'react'
import withStyles from 'styles'
import {Bar, defaults} from 'react-chartjs-2'
import {humanize} from 'utils'

class ContractedVsAvailable extends Component {
  get data(){
    let accounts = this.props.irrigator.accounts || []
    accounts = [...accounts, {
      accountType: 'Total',
      contractedBalance: accounts.reduce((sum, account) => sum + account.contractedBalance, 0),
      availableBalance: accounts.reduce((sum, account) => sum + account.availableBalance, 0),
      seasonRelease: accounts.reduce((sum, account) => sum + account.seasonRelease, 0)
    }]
    return {
      labels: accounts.map(({accountType}) => humanize(accountType)),
      datasets: [{
        label: 'Contracted',
        stack: 'contracted',
        backgroundColor: '#07b151',
        borderWidth: 0,
        data: accounts.map(({contractedBalance}) => milm3(contractedBalance))
      }, {
        label: 'Available',
        stack: 'available',
        backgroundColor: '#2d2b74',
        borderWidth: 0,
        data: accounts.map(({availableBalance}) => milm3(availableBalance))
      }, {
        label: 'Released',
        stack: 'available',
        backgroundColor: '#FA0057',
        borderWidth: 0,
        data: accounts.map(({seasonRelease}) => milm3(seasonRelease || 0))
      }]
    }
  }

  get options() {
    return {
      layout: {
        padding: {
          top: 0,
        }
      },
      legend: {
        labels: {
          boxWidth: 15,
          padding: 30,
        },
      },
      scales: {
        xAxes: [{
          gridLines: {
            color: '#BFBFBF',
            tickMarkLength: 8,
            borderDash: [4]
          },
          ticks: {
            autoSkip: false,
            minRotation: 90,
            padding: 7,
          }
        }],
        yAxes: [{
          gridLines: {
            zeroLineColor: '#000',
            color: '#BFBFBF',
            tickMarkLength: 7,
            borderDash: [4]
          },
          scaleLabel: {
            display: 'true',
            labelString: 'million m³',
            fontFamily: 'Roboto'
          },
          ticks: {
            padding: 7,
            maxTicksLimit: 5,
            min: 0
          }
        }]
      },
    }
  }

  get plugins() {
    return [{
      beforeInit: function(chart, options) {
        chart.legend.afterFit = function() {
          this.height = this.height + 23;
        };
      }
    }]
  }

  render = () => {
    defaults.global.defaultFontColor = '#000'
    defaults.global.defaultFontFamily = 'Roboto'
    defaults.global.defaultFontSize = 16
    return (
      <div className={this.props.classes.root}>
        <Bar data={this.data} options={this.options} height={330} plugins={this.plugins} />
      </div>
    )
  }
}

const milm3 = m3 => (m3 / 1e6).toFixed(3)

const styles = {
  root: {
    maxWidth: 480
  }
}

export default withStyles(styles)(ContractedVsAvailable)