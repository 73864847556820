import React, { Component } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'
import { Route } from 'containers'
import { withRouter } from 'react-router-dom'
import { Authorization  } from 'utils'

export class Routes extends Component{

  get passwordExpiredRedirect(){
    return this.props.needChangePassword ? `/tokens/update` : '/'
  }

  get defaultRedirect(){
    return '/notifications/board'
  }

  render = () => this.props.needChangePassword ?
    this.renderPasswordExpiredRoutes() :
    this.renderRoutes()

  renderPasswordExpiredRoutes = () =>
    <Switch>
      <Route exact path="/irrigator_daily_entries/new"           component={Containers.IrrigatorDailyEntry.Form}/>
      <Route exact path="/irrigator_daily_entries/:id/edit"      component={Containers.IrrigatorDailyEntry.Form}/>
      <Route exact path="/tokens/reset"                          component={Containers.Token.Reset}/>
      <Route exact path="/tokens/update"                         component={Containers.Token.Update}/>
      <Route component={Containers.Token.Update}/>
    </Switch>

  renderRoutes = () =>
    <Switch>
      <Route exact path="/notifications"                         component={Containers.Notification.List}/>
      <Route exact path="/notifications/new"                     component={Containers.Notification.Form} requires={Authorization.admin}/>
      <Route exact path="/notifications/board"                   component={Containers.Notification.Board}/>
      <Route exact path="/schemes/:id?/request_approval"         component={Containers.IrrigatorDailyEntry.RequestApproval} requires={Authorization.hasOneOf('admin','operator','viewer')}/>
      <Route exact path="/schemes/:id?/stored_water"             component={Containers.Scheme.WaterComponentsView} requires={Authorization.hasOneOf('admin','viewer','regulator')}/>
      <Route exact path="/schemes/:id?/contracted_water"         component={Containers.SchemeSeason.ContractedWater} requires={Authorization.hasOneOf('admin', 'contractedWaterViewer')}/>
      <Route exact path="/notifications/mine"                    component={Containers.Notification.Mine}/>
      <Route exact path="/irrigator_daily_entries"               component={Containers.IrrigatorDailyEntry.Form} requires={Authorization.hasOneOf('admin','viewer','irrigator')}/>
      <Route exact path="/irrigators/:irrigatorId/daily_entries" component={Containers.IrrigatorDailyEntry.Form} requires={Authorization.hasOneOf('admin','viewer','irrigator')}/>
      <Route exact path="/irrigators/:irrigatorId/accounts"      component={Containers.Irrigator.AccountView} requires={Authorization.hasOneOf('admin','viewer','irrigator')}/>
      <Route exact path="/irrigators/accounts"                   component={Containers.Irrigator.AccountView} requires={Authorization.hasOneOf('admin','viewer','irrigator')}/>
      <Route exact path="/audits"                                component={Containers.Audit.List} requires={Authorization.hasOneOf('admin','viewer')}/>
      <Route exact path="/transactions"                          component={Containers.Transaction.Show} requires={Authorization.hasOneOf('admin','viewer')}/>
      <Route exact path="/schemes/:id?/transactions"             component={Containers.Transaction.Show} requires={Authorization.hasOneOf('admin','viewer')}/>
      <Route exact path="/accounts"                              component={Containers.Account.List} requires={Authorization.admin}/>
      <Route exact path="/accounts/new"                          component={Containers.Account.Form} requires={Authorization.admin}/>
      <Route exact path="/accounts/:id/edit"                     component={Containers.Account.Form} requires={Authorization.admin}/>
      <Route exact path="/irrigators"                            component={Containers.Irrigator.List} requires={Authorization.admin}/>
      <Route exact path="/irrigators/new"                        component={Containers.Irrigator.Form} requires={Authorization.admin}/>
      <Route exact path="/irrigators/:id/edit"                   component={Containers.Irrigator.Form} requires={Authorization.admin}/>
      <Route exact path="/schemes"                               component={Containers.Scheme.List} requires={Authorization.admin}/>
      <Route exact path="/schemes/new"                           component={Containers.Scheme.Form} requires={Authorization.admin}/>
      <Route exact path="/schemes/:id"                           component={({match}) => <Redirect to={`/schemes/${match.params.id}/edit`}/>}/>
      <Route exact path="/schemes/:id/edit"                      component={Containers.Scheme.Form} requires={Authorization.admin}/>
      <Route exact path="/scheme_seasons/:id/edit"               component={Containers.SchemeSeason.Form} requires={Authorization.admin}/>
      <Route exact path="/profile"                               component={Containers.User.Profile}/>
      <Route exact path="/users"                                 component={Containers.User.List} requires={Authorization.admin}/>
      <Route exact path="/users/new"                             component={Containers.User.Form} requires={Authorization.admin}/>
      <Route exact path="/washup_entries"                        component={Containers.WashupEntry.List} requires={Authorization.admin}/>
      <Route exact path="/washup_entries/new"                    component={Containers.WashupEntry.Form} requires={Authorization.admin}/>
      <Route exact path="/users/:id/edit"                        component={Containers.User.Form}/>
      <Route exact path="/tokens/sign_in"                        component={Containers.Token.SignIn}/>
      <Route exact path="/tokens/forgot"                         component={Containers.Token.Forgot}/>
      <Route exact path="/tokens/reset"                          component={Containers.Token.Reset}/>
      <Route exact path="/tokens/accept_invitation"              component={Containers.Token.AcceptInvitation}/>
      <Route exact path="/tokens/confirm"                        component={Containers.Token.Confirm}/>
      <Route exact path="/tokens/resend_confirmation"            component={Containers.Token.ResendConfirmation}/>
      <Route exact path="/"                                      component={() =>  <Redirect to={this.defaultRedirect}/>}/>
      <Route exact path="/not_found"                             component={Containers.Status.NotFound} />
      <Route exact path="/timed_out"                             component={Containers.Status.TimedOut} />
      <Route exact path="/inactive"                              component={Containers.Status.Inactive} />
      <Route exact path="/confirmation_resent"                   component={Containers.Status.ConfirmationResent} />
      <Route exact path="/unauthorized"                          component={Containers.Status.Unauthorized} />
      <Route exact path="/apidocs"                               component={Containers.Docs.ApiDocs} requires={Authorization.admin}/>
      <Route component={Containers.Status.NotFound} />
    </Switch>

}

export default withRouter(Routes)
