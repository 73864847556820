import React, {Component} from 'react'
import withStyles from 'styles'
import IconButton from '@material-ui/core/IconButton'
import ExpandIcon from '@material-ui/icons/ExpandMore'
import Tooltip from '@material-ui/core/Tooltip'

class ExpandButton extends Component {
  render = () => {
    const {className, classes, expanded, table, ...props} = this.props
    return (
      <Tooltip title={`${expanded ? 'Collapse' : 'Expand'} this section`}>
        <IconButton {...props} className={classes({expandButton: true, expanded, header: !table}, className)}>
          <ExpandIcon/>
        </IconButton>
      </Tooltip>
    )
  }
}

const styles = ({palette}) => ({
  expandButton: {
    '&$expanded': {
      transform: 'rotate(180deg)'
    },
    transition: 'transform 500ms',
  },
  expanded: {},
  header: {
    border: `2px solid ${palette.primary.main}`,
    marginRight: 21,
    color: palette.primary.main,
    marginTop: -2,
  }
})

export default withStyles(styles, {name: 'ExpandButton'})(ExpandButton)
