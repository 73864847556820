import AccountActions from './AccountActionSet'
import AccountDeltaActions from './AccountDeltaActionSet'
import AuditActions from './AuditActionSet'
import ContractedEntitlementActions from './ContractedEntitlementActionSet'
import IrrigatorActions from './IrrigatorActionSet'
import IrrigatorDailyEntryActions from './IrrigatorDailyEntryActionSet'
import NotificationActions from './NotificationActionSet'
import ResetActions from './ResetActionSet'
import SchemeActions from './SchemeActionSet'
import SchemeDeltaActions from './SchemeDeltaActionSet'
import SchemeSeasonActions from './SchemeSeasonActionSet'
import SnackbarActions from './SnackbarActionSet'
import TokenActions from './TokenActionSet'
import UserActions from './UserActionSet'
import VersionActions from './VersionActionSet'
import WashupEntryActions from './WashupEntryActionSet'

export const reducers = {
  accountDeltas:          AccountDeltaActions.reducers,
  accounts:               AccountActions.reducers,
  audits:                 AuditActions.reducers,
  contractedEntitlements: ContractedEntitlementActions.reducers,
  irrigatorDailyEntries:  IrrigatorDailyEntryActions.reducers,
  irrigators:             IrrigatorActions.reducers,
  notifications:          NotificationActions.reducers,
  schemeDeltas:           SchemeDeltaActions.reducers,
  schemeSeasons:          SchemeSeasonActions.reducers,
  schemes:                SchemeActions.reducers,
  snackbar:               SnackbarActions.reducers,
  tokens:                 TokenActions.reducers,
  users:                  UserActions.reducers,
  version:                VersionActions.reducers,
  washupEntries:          WashupEntryActions.reducers,
}

export {
  AccountActions,
  AccountDeltaActions,
  AuditActions,
  ContractedEntitlementActions,
  IrrigatorActions,
  IrrigatorDailyEntryActions,
  NotificationActions,
  ResetActions,
  SchemeActions,
  SchemeDeltaActions,
  SnackbarActions,
  TokenActions,
  UserActions,
  VersionActions,
  WashupEntryActions,
  SchemeSeasonActions,
}
