import React, {PureComponent} from 'react'
import withStyles from 'styles'
import {Bar, defaults} from 'react-chartjs-2'
import {humanize} from 'utils'

class AvailableBalance extends PureComponent {
  get data() {
    let {dailySummaries = []} = this.props
    return {
      labels: dailySummaries.map(({date}) => date),
      datasets: [{
        label: 'Available',
        yAxisID: 'left-y-axis',
        stack: 'available',
        backgroundColor: '#66b0ff',
        borderWidth: 0,
        data: dailySummaries.map(({balance, projectedRelease}) =>
          (!projectedRelease && projectedRelease !== 0 && milm3(balance)))
      }, ...this.accounts.map((account, i) => {
        return {
          label: `Credit - ${humanize(account.accountType)}`,
          stack: 'credit',
          borderWidth: 0,
          backgroundColor: creditColors[i],
          data: dailySummaries.map(summary => milm3(summary.accounts.find(({accountId}) => `${accountId}` === `${account.id}`).credit))
        }
      }), {
        label: 'Released',
        yAxisID: 'right-y-axis',
        stack: 'available',
        backgroundColor: '#FA0057',
        borderWidth: 0,
        data: dailySummaries.map(({release}) => -milm3(release))
      }, ...this.accounts.map((account, i) => {
        return {
          label: `Debit - ${humanize(account.accountType)}`,
          yAxisID: 'right-y-axis',
          stack: 'credit',
          borderWidth: 0,
          backgroundColor: debitColors[i],
          data: dailySummaries.map(summary => -milm3(summary.accounts.find(({accountId}) => `${accountId}` === `${account.id}`).debit))
        }
      }), {
        label: 'Projected',
        yAxisID: 'left-y-axis',
        stack: 'available',
        backgroundColor: '#ABC',
        borderWidth: 0,
        data: dailySummaries.map(({balance, projectedRelease}) =>
          (projectedRelease || projectedRelease === 0) && milm3(balance - projectedRelease))
      }, {
        label: 'Requested',
        yAxisID: 'right-y-axis',
        stack: 'available',
        backgroundColor: '#678',
        borderWidth: 0,
        data: dailySummaries.map(({requested}) => -milm3(requested))
      }]
    }
  }

  get accounts() {
    let {dailySummaries = []} = this.props
    const accounts = {}
    dailySummaries.forEach(summary => {
      summary.accounts.forEach(({accountId: id, accountType}) => {
        accounts[id] = {id, accountType}
      })
    })
    return Object.values(accounts)
  }

  get maxBalance() {
    const result = (Math.max(...(this.props.dailySummaries || []).map(({balance}) => balance)) / 1e6)
    return result ?? 0
  }

  get maxCredit() {
    const result = Math.max(...(this.props.dailySummaries || []).map(({balance, accounts}) =>
      Math.max(balance, accounts.reduce((sum, {credit}) => sum + credit, 0))
    )) / 1e6
    return result ?? 0
  }

  get maxDebit() {
    const debits = (this.props.dailySummaries || []).map(({accounts}) =>
      accounts.reduce((sum, {debit}) => sum + debit, 0)
    ).filter(v => v)

    const result = debits.length ? +(Math.min(
        Math.max(...debits),
        4 * debits.reduce((sum, v) => sum + v, 0) / debits.length
    ) / 1e6) : 0

    return result ?? 0
  }

  get maxRelease() {
    const result = Math.max(...(this.props.dailySummaries || []).map(({release, requested}) =>
        Math.max(release, requested)
    )) / 1e6
    return result ?? 0
  }

  get yAxisLength() {
    // Add .01 to prevent zero length
    // Add 1 % to make bars not touching each other
    return Math.ceil((Math.max(this.maxBalance, this.maxCredit) + this.maxDebit + this.maxRelease + .01) * 1.01)
  }

  get options() {
    return {
      layout: {
        padding: {
          top: 0,
        }
      },
      legend: {
        labels: {
          boxWidth: 15,
          padding: 30,
        },
      },
      tooltips: {
        mode: 'index',
        position: 'nearest',
      },
      scales: {
        xAxes: [{
          type: 'time',
          offset: true,
          time: {
            unit: (this.props.dailySummaries || []).length > 31 ? 'month' : 'day'
          },
          gridLines: {
            color: '#BFBFBF',
            tickMarkLength: 7,
            borderDash: [4],
          },
          ticks: {
            minRotation: 90,
            padding: 7,
            source: 'auto',
            maxTicksLimit: 15
          }
        }],
        yAxes: [{
          id: 'left-y-axis',
          gridLines: {
            zeroLineColor: '#000',
            color: '#BFBFBF',
            tickMarkLength: 7,
            borderDash: [4]
          },
          scaleLabel: {
            display: 'true',
            labelString: 'Available + Credit (million m³)',
            fontFamily: 'Roboto'
          },
          ticks: {
            max: this.yAxisLength,
            min: 0,
            padding: 7,
            beginAtZero: true,
            callback: value => Number(+value).toFixed(1)
          },
        }, {
          id: 'right-y-axis',
          position: 'right',
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: 'true',
            labelString: 'Released + Debit (million m³)',
            fontFamily: 'Roboto'
          },
          ticks: {
            max: 0,
            min: -this.yAxisLength,
            beginAtZero: true,
            callback: value => Number(-value).toFixed(1)
          }
        }]
      },
    }
  }

  get plugins() {
    return [{
      beforeInit: function (chart) {
        chart.legend.afterFit = function () {
          this.height = this.height + 23;
        };
      }
    }]
  }

  render = () => {
    defaults.global.defaultFontColor = '#000'
    defaults.global.defaultFontFamily = 'Roboto'
    defaults.global.defaultFontSize = 16
    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.wrapper}>
          <Bar data={this.data} options={this.options} height={280} plugins={this.plugins}/>
        </div>
      </div>
    )
  }
}

const creditColors = [
  '#0066cc',
  '#337B46',
  '#A3CE00',
  '#7F1399',
]

const debitColors = [
  '#ff669c',
  '#FA9C00',
  '#FBEC00',
  '#AC504A',
]

const milm3 = m3 => (m3 / 1e6).toFixed(3)

const styles = {
  root: {
    width: '100%',
    overflow: 'auto'
  },
  wrapper: {
    minWidth: 550,
  }
}

export default withStyles(styles)(AvailableBalance)
