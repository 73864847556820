import React, {Component} from 'react'
import withStyles from 'styles'
import {connect} from 'react-redux'
import {Snackbar} from '@material-ui/core'
import ColorSnackbarContent from '../../components/ColorSnackbarContent'
import SystemNotificationTypes from 'constants/SystemNotificationTypes'
import ListItemText from '@material-ui/core/ListItemText'
import Link from '@material-ui/core/Link'
import {NotificationActions} from '../../actionsets'
import {APIResource} from '../../services'

export class PriorityNotifications extends Component {
  constructor(props) {
    super(props)
    NotificationActions.bindActions(this)
  }

  get latestUnreadPriorityNotification() {
    return this.props.unreadNotifications.find(n => n.notificationType === SystemNotificationTypes.priority)
  }

  closeNotification = notification => async () => {
    // set notification as read
    if (!notification.read) {
      await this.actions.update({id: notification.id, read: true}, {include: 'user'})
    }
  }

  render = () => {
    const {classes} = this.props
    const notification = this.latestUnreadPriorityNotification

    if (!notification) return null

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.snackbar}
        open={!notification.read}
      >
        <ColorSnackbarContent
          onClose={this.closeNotification(notification)}
          variant="default"
          message={
            <ListItemText
              disableTypography={true}
              primary={<strong>{notification.title}</strong>}
              secondary={
                <>
                  <p className={classes.notificationMessage}>{notification.message}</p>
                  {notification.notificationFileUrlPath != null &&
                    <Link href={`${APIResource.DEFAULT_BASE}${notification.notificationFileUrlPath}`}>
                      {notification.notificationFileName}
                    </Link>
                  }
                </>
              }
            />
          }
        />
      </Snackbar>
    )
  }
}

const styles = {
  snackbar: {
    paddingTop: '4em'
  },
  notificationMessage: {
    whiteSpace: 'pre-wrap',
  },
}

export default connect(({notifications}) => notifications)(withStyles(styles)(PriorityNotifications))
