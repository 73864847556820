import React, { Component, Fragment } from 'react'
import withStyles from 'styles'
import Typography from '@material-ui/core/Typography'
import Balances from 'components/irrigator/Balances'
import Timestamp from 'components/Timestamp'

export class BalancesSummary extends Component {

  static defaultProps = {
    irrigators: []
  }

  render = () => {
    return (
      <div className={this.props.classes.root}>
        <Typography variant="h6">
          Account Balances
          <Timestamp>{this.props.irrigators[0]}</Timestamp>
        </Typography>
        {this.props.irrigators.map(irrigator =>
            irrigator.accounts.length ?
            <Fragment key={irrigator.id}>
              <Typography variant="subtitle1">{irrigator.name}</Typography>
              <Balances irrigator={irrigator} className={this.props.classes.irrigatorBalances}/>
            </Fragment> : null
        )}
      </div>
    )
  }
}

const styles = () => ({
  root: {
    marginBottom: 40,
  },
  irrigatorBalances: {
    marginBottom: 10,
  }
})

export default withStyles(styles)(BalancesSummary)
