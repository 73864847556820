import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AccountDeltaActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import ActionHeader from 'components/ActionHeader'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import LoadedCard from 'components/LoadedCard'
import { compose, errorStringsFromError, Authorization } from 'utils'
import { connectQueryString } from 'containers/shared'
import Table, {TableHead, TableBody, TableRow, TableExpander, TableCell} from 'components/CollapsibleTable'
import withStyles from 'styles'
import ExpandButton from 'components/ExpandButton'
import Collapse from '@material-ui/core/Collapse'
import Volume from 'components/units/Volume'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import AccountTransactionDialog from './AccountTransactionDialog'
import IndexToolbar from 'components/index_view/IndexToolbar'
import Select from 'components/Select'
import {humanize} from 'utils'
import moment from 'moment'
import DataList, {DataValue} from 'components/DataList'
import SearchCombo from 'components/index_view/SearchCombo'
import MenuItem from '@material-ui/core/MenuItem'
import ExportButton from 'components/index_view/ExportButton'
import DatePicker from 'components/DatePicker'
import HelpButton from 'components/HelpButton'

export class AccountTransactions extends Component{

  constructor(props){
    super(props)
    AccountDeltaActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1,
    expanded: {},
  }

  dependsOn(){
    return this.loadAccountTransactions()
  }

  componentDidUpdate = (prevProps) => {
    if(
        this.props.irrigators.some(({id}, i) => id !== (prevProps.irrigators[i] || {}).id) ||
        this.props.irrigators.length !== prevProps.irrigators.length ||
        this.props.query !== prevProps.query
      ){
      this.loadAccountTransactions()
    }
  }

  loadAccountTransactions = () => {
    if (this.props.irrigators.filter(({id}) => id).length > 0) {
      return this.actions.index({
        page: this.props.page,
        order: '-transacted_at',
        filter: {
          irrigator: {id: this.props.irrigators.map(({id}) => id)},
          ...this.props.filter,
        },
        ...this.fetchParams
      })
    }
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  getExpanded = delta => {
    return !!this.state.expanded[delta.id]
  }

  handleToggleExpanded = delta => () => {
    this.setState({expanded: {...this.state.expanded, [delta.id]: !this.state.expanded[delta.id]}})
  }

  handleDeleteAccountTransaction = id => event => {
    this.actions.destroy({id})
                .then(this.loadAccountTransactions)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    this.props.onChange && this.props.onChange()
    event.stopPropagation()
  }

  get fetchParams(){
    return {include: 'irrigator,account,deltaTransaction.user'}
  }

  get accountDeltas(){
    return this.props.accountDeltas
  }

  get accountTypes() {
    const types = {};
    (this.props.irrigators || []).forEach(irrigator => {
      (irrigator.accounts || []).forEach(({accountType}) => types[accountType] = true)
    })
    return Object.keys(types)
  }

  get expanded(){
    return this.props.queryParams.expanded === 'true'
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  handleToggleCollapse = () =>
    this.props.onQueryParamsChange({...this.props.filter, expanded: !this.expanded || undefined})

  handleAddAccountTransaction = () =>
    this.setState({activeAccountDelta: {}})

  handleSaveAccountDelta =  async accountDelta => {
    const saveOp = (accountDelta.id ?
      this.actions.update(accountDelta, this.fetchParams) :
      this.actions.create(accountDelta, this.fetchParams))
    saveOp.then(() => {
      this.setState({activeAccountDelta: undefined})
      this.loadAccountTransactions()
      this.props.onChange && this.props.onChange()
    })
    return saveOp
  }

  handleEditDelta = activeAccountDelta => () =>
    this.setState({activeAccountDelta})

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  renderAccountDeltaRow = ({irrigator: { name: irrigatorName }, account: {accountType}, deltaTransaction: { transactedAt, user: { id: wasUserInput, name } = {} }, availableBalance, contractedBalance, cumulativeAvailableBalance, cumulativeContractedBalance, notes, id}, index) =>
    <Fragment key={id}>
      <TableRow>
        <TableCell mobileHeading>
          {moment(transactedAt).format('DD/MM/YYYY')}
        </TableCell>
        {this.props.irrigators.length !== 1 &&
          <TableCell mobileLabel={"Irrigator"}>
            {irrigatorName}
          </TableCell>
        }
        <TableCell mobileLabel={"Account"}>
          {humanize(accountType)}
        </TableCell>
        <TableCell mobileLabel={"Sub Account"}>
          {parseInt(availableBalance) || null ? 'Available' : 'Contract'}
        </TableCell>
        <TableCell mobileLabel={"Debit"}>
          {availableBalance + contractedBalance < 0 &&
          <Volume>
            {parseInt(availableBalance) || parseInt(contractedBalance)}
          </Volume>
          }
        </TableCell>
        <TableCell mobileLabel={"Credit"}>
          {availableBalance + contractedBalance > 0 &&
            <Volume>
              {parseInt(availableBalance) || parseInt(contractedBalance)}
            </Volume>
          }
        </TableCell>
          <TableCell mobileLabel={"New Balance"}>
            <Volume>
              {availableBalance ? parseInt(cumulativeAvailableBalance) : parseInt(cumulativeContractedBalance)}
            </Volume>
          </TableCell>
        <TableCell mobileLabel={"Notes"}>
          {notes || '(none)'}
        </TableCell>
        <TableCell mobileHeading style={{padding: 0, width: 1}}>
          <ExpandButton table onClick={this.handleToggleExpanded({id})} expanded={this.getExpanded({id})}/>
        </TableCell>
      </TableRow>
      <TableExpander expanded={this.getExpanded({id})}>
        <div style={{display: 'flex'}}>
          <DataList className={this.props.classes.dataDetails}>
            <DataValue title='Effective Time'>{moment(transactedAt).format('h:mm:ss a')}</DataValue>
            <DataValue title='Approved By'>{name || 'System'}</DataValue>
          </DataList>
            {
              Authorization.admin && wasUserInput &&
              <IconButton onClick={this.handleDeleteAccountTransaction(this.accountDeltas[index].id)} color='primary'><DeleteIcon/></IconButton>
            }
            {
              Authorization.admin && wasUserInput &&
              <IconButton onClick={this.handleEditDelta(this.accountDeltas[index])} color='primary'><EditIcon/></IconButton>
            }
        </div>
      </TableExpander>
    </Fragment>

  render = () =>
    <div className={this.props.className}>
      <ActionHeader title="Accounts" variant="h6" startAdornment={
        <ExpandButton onClick={this.handleToggleCollapse} expanded={this.expanded}/>
      }>
        <HelpButton>
          Stored Water Transactions (excludes Releases occurring today)
        </HelpButton>
        <ExportButton resource='account_deltas' params={{irrigator_id: this.props.irrigators.map(({id}) => id)}}/>
        {
          Authorization.admin &&
          <Button color="primary" variant="contained" onClick={this.handleAddAccountTransaction}>Add</Button>
        }
      </ActionHeader>
      {this.renderErrorMessages()}
      <Collapse in={this.expanded}>
        <div className={this.props.classes.contentWrapper}>
          <IndexToolbar>
            <SearchCombo>
              {this.props.irrigators.length !== 1 &&
                <Select member='irrigator.id' label='Irrigator' fullWidth>
                  <MenuItem value={''}><em>All</em></MenuItem>
                  {this.props.irrigators.map(irrigator =>
                    <MenuItem key={irrigator.id} value={irrigator.id}>{irrigator.name}</MenuItem>
                  )}
                </Select>
              }
              <Select member='account.accountType' label='Account' fullWidth>
                <MenuItem value={''}><em>All</em></MenuItem>
                {this.accountTypes.map(type =>
                  <MenuItem key={type} value={type}>{humanize(type)}</MenuItem>
                )}
              </Select>
              <Select member='subAccount' label='Sub Account' fullWidth>
                <MenuItem value={''}><em>All</em></MenuItem>
                <MenuItem value={'available'}>Available</MenuItem>
                <MenuItem value={'contract'}>Contract</MenuItem>
              </Select>
              <DatePicker member='deltaTransaction.transactedAt.start' label='Effective From' />
              <DatePicker member='deltaTransaction.transactedAt.end' label='Effective To' />
            </SearchCombo>
            <Pagination totalPages={this.props.totalPages}/>
          </IndexToolbar>
          <LoadedCard>
            {this.props.accountDeltas &&
                  <Table className={this.props.classes.table} breakpoint={540}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Effective</TableCell>
                        {this.props.irrigators.length !== 1 &&
                          <TableCell>Irrigator</TableCell>
                        }
                        <TableCell>Account</TableCell>
                        <TableCell>Sub Account</TableCell>
                        <TableCell>Debit</TableCell>
                        <TableCell>Credit</TableCell>
                        <TableCell>New Balance</TableCell>
                        <TableCell>Notes</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.accountDeltas.map(this.renderAccountDeltaRow)}
                    </TableBody>
                  </Table>
                  }
          </LoadedCard>
          {
            this.state.activeAccountDelta &&
            <AccountTransactionDialog
              irrigators={this.props.irrigators}
              accountDelta={this.state.activeAccountDelta}
              onCancel={() => this.setState({activeAccountDelta: null})}
              onSave={this.handleSaveAccountDelta}
            />
          }
        </div>
      </Collapse>
    </div>
}

const styles = ({palette}) => ({
  contentWrapper: {
    paddingBottom: 40,
  },
  table: {
    '& > thead > tr > th, & > tbody > tr > td': {
      paddingLeft: 8,
      paddingRight: 8,
      fontSize: '0.9rem',
      '@media(min-width: 1440px)': {
        fontSize: '1rem'
      }
    }
  },
  dataDetails: {
    flex: 1,
  }
})

export default compose(
  Dependent({loader: true}),
  connectQueryString({nameSpace: 'accountDeltas', customQueryParams: ['expanded']}),
  withStyles(styles),
  connect(({accountDeltas}) => accountDeltas)
)(AccountTransactions)
