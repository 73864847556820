import React, {Component} from 'react'
import withStyles from 'styles'
import {Line, defaults} from 'react-chartjs-2'
import moment from 'moment'

class LakeLevelComponents extends Component {
  get data(){
    let {dailySummaries = []} = this.props
    return {
      labels: dailySummaries.map(({date}) => date),
      datasets: [{
        label: 'Accessible Stored Water',
        backgroundColor: '#07b151',
        borderColor: '#07b151',
        fill: false,
        data: dailySummaries.map(({storedWater}) => milm3(storedWater))
      }, {
        label: 'Lake Storage',
        backgroundColor: '#2d2b74',
        borderColor: '#2d2b74',
        fill: false,
        data: dailySummaries.map(({lakeLevel}) => milm3(lakeLevel))
      }]
    }
  }

  get endOfMonth() {
    let {dailySummaries = []} = this.props
    const lastDate = moment((dailySummaries[dailySummaries.length - 1] || {}).date)
    return lastDate.endOf('month').format('YYYY-MM-DD')
  }

  get options() {
    return {
      layout: {
        padding: {
          top: 0,
        }
      },
      legend: {
        labels: {
          boxWidth: 15,
          padding: 30,
        },
      },
      scales: {
        xAxes: [{
          type: 'time',
          time: {
            unit: 'day'
          },
          gridLines: {
            color: '#BFBFBF',
            tickMarkLength: 7,
            borderDash: [4]
          },
          ticks: {
            max: this.endOfMonth,
            minRotation: 90,
            padding: 7,
            source: 'auto',
            maxTicksLimit: 15,
          }
        }],
        yAxes: [{
          id: 'left-y-axis',
          gridLines: {
            zeroLineColor: '#000',
            color: '#BFBFBF',
            tickMarkLength: 7,
            borderDash: [4]
          },
          scaleLabel: {
            display: 'true',
            labelString: 'million m³',
            fontFamily: 'Roboto'
          },
          ticks: {
            min: 0,
            max: 140,
            padding: 7,
          },
        }]
      },
    }
  }

  get plugins() {
    return [{
      beforeInit: function(chart, options) {
        chart.legend.afterFit = function() {
          this.height = this.height + 23;
        };
      }
    }]
  }

  render = () => {
    defaults.global.defaultFontColor = '#000'
    defaults.global.defaultFontFamily = 'Roboto'
    defaults.global.defaultFontSize = 16
    return (
      <div className={this.props.classes.root}>
        <Line data={this.data} options={this.options} height={330} plugins={this.plugins} />
      </div>
    )
  }
}

const milm3 = m3 => (m3 === null || m3 === undefined) ? m3 : (m3 / 1e6).toFixed(3)

const styles = {
  root: {
    maxWidth: 480
  }
}

export default withStyles(styles)(LakeLevelComponents)
