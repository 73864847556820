import React, {Component} from 'react'
import Button from '@material-ui/core/Button'
import {ErrorBanner, FormContext} from 'components'
import * as API from 'api'
import VolumeField from 'components/units/VolumeField'
import LabeledSelect from 'components/LabeledSelect';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DatePicker from 'components/DatePicker'
import PromiseButton from 'components/PromiseButton'
import {errorStringsFromError} from 'utils'
import moment from 'moment'

export class SchemeTransactionDialog extends Component{

  static getDerivedStateFromProps(props, state){
    if (props.schemeDelta !== state.schemeDelta) {
      return {
        ...state,
        schemeDelta: props.schemeDelta,
        ...props.schemeDelta && {
          id:             props.schemeDelta.id,
          schemeId:       props.schemeDelta.scheme?.id,
          transactionId:  props.schemeDelta.deltaTransaction?.id,
          notes:          props.schemeDelta.notes,
          volume:         Math.abs(props.schemeDelta.storedWater || props.schemeDelta.warehouseStoredWater),
          transactedAt:   props.schemeDelta.deltaTransaction?.transactedAt,
          direction:      [
            props.schemeDelta.storedWater > 0 && '+asw',
            props.schemeDelta.storedWater < 0 && '-asw',
            props.schemeDelta.warehouseStoredWater > 0 && '+wsw',
            props.schemeDelta.warehouseStoredWater < 0 && '-wsw',
          ].filter(d => d).join(',') || '-asw,+wsw'
        },
        error: undefined
      }
    }
    return null
  }

  componentDidMount = async () => {
    const { data: schemes } = await API.Schemes.index()

    // If no scheme is set in state and only one scheme is returned from API, set it as default
    let schemeId = this.state.schemeId
    if (!schemeId && schemes?.length === 1) schemeId = schemes[0].id

    this.setState({schemes, schemeId})
  }

  state = { schemes: [] }

  handleSave = async () => {
    this.setState({error: undefined})
    try{
      await this.props.onSave({
        id: this.state.id,
        deltaTransactionAttributes: {
          id: this.state.transactionId,
          schemeId: this.state.schemeId,
          transactedAt: moment(this.state.transactedAt).format('YYYY-MM-DD')
        },
        ...this.state.direction.includes('+wsw') && { warehouseStoredWater: this.state.volume },
        ...this.state.direction.includes('-wsw') && { warehouseStoredWater: -this.state.volume },
        ...this.state.direction.includes('+asw') && { storedWater: this.state.volume },
        ...this.state.direction.includes('-asw') && { storedWater: -this.state.volume },
        notes: this.state.notes
      })
    }
    catch(error){
      this.setState({error})
      throw error
    }
  }

  get errorContext(){
    const {meta} = this.state.error || {}
    if(meta){
      return Object.entries(meta).reduce((acc, [name, value]) => {acc[name] = value.join(', '); return acc},{})
    }
    return null
  }

  handleChange = state =>
    this.setState(state)

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.state.error)}
    </ErrorBanner>

  render = () =>
      <Dialog open={!!this.props.schemeDelta}>
        <DialogTitle>{this.props.schemeDelta.id ? 'Edit' : 'New'} Scheme Transaction</DialogTitle>
        <FormContext onSubmit={this.handleSave} context={this.state} errorContext={this.errorContext} onChange={this.handleChange}>
          <DialogContent>
            {this.renderErrorMessages()}
            <DatePicker errorMember='deltaTransaction.transactedAt' fullWidth label='Effective Date' member='transactedAt'/>
            <LabeledSelect fullWidth label='Scheme' errorMember='scheme' member='schemeId'>
              {
                this.state.schemes.map(scheme => <MenuItem key={scheme.id} value={scheme.id}>{scheme.name}</MenuItem>)
              }
            </LabeledSelect>
            <LabeledSelect fullWidth label='Direction' member='direction'>
              <MenuItem value='+asw,-wsw'>From WSW to ASW</MenuItem>
              <MenuItem value='-asw,+wsw'>From ASW to WSW</MenuItem>
              <MenuItem value='-asw'>Debit ASW</MenuItem>
              <MenuItem value='+asw'>Credit ASW</MenuItem>
              <MenuItem value='-wsw'>Debit WSW</MenuItem>
              <MenuItem value='+wsw'>Credit WSW</MenuItem>
            </LabeledSelect>
            <TextField member='notes' fullWidth/>
            <VolumeField label='Volume' member='volume' fullWidth/>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onCancel} variant='contained' color='primary'>Cancel</Button>
            <PromiseButton type='submit' color='secondary' variant='contained'>Save</PromiseButton>
          </DialogActions>
        </FormContext>
      </Dialog>
}

export default SchemeTransactionDialog
