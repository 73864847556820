import CrudActionSetBase from './CrudActionSetBase'
import {Irrigators} from 'api'

export class AccountActionSet extends CrudActionSetBase('account'){
  static indexForIrrigator(creator, reducer, constants){

    constants.defineRequestConstants()
    creator(({irrigatorId, clearExisting}={}) => (dispatch) => {
      return dispatch({
        type: constants.ACTION,
        promise: Irrigators.accounts({id: irrigatorId}),
        payload: { clearExisting }
      })
    })

    reducer({
      [constants.REQUEST]: (state, { requestPayload: { page, clearExisting }, request }) => {
        return {...state, page, ...clearExisting && {accounts:[]}, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { data, meta: { totalPages, page }} }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, accounts: data, totalPages, page }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, index: error }
        return {...state, requests, errors }
      }
    })
  }
}
export default new AccountActionSet()