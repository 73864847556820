import React, {Component} from 'react'
import withStyles from 'styles'
import moment from 'moment'

export class Footer extends Component {
  state = {mounted: false}

  componentDidMount(){
    setTimeout(() => {
      this.setState({mounted: true})
    })
  }

  render = () => {
    const {classes, ...props} = this.props
    return (
      <div className={classes({footer: true, transition: !this.state.mounted})} {...props}>
        Copyright © {moment().year()} Manawa Energy
      </div>
    )
  }

}

const styles = ({palette}) => ({
  footer: {
    transition: 'transform 500ms',
    transitionDelay: '200ms',
    flex: 0,
    flexBasis: 'auto',
    height: 80,
    lineHeight: '80px',
    paddingLeft: 40,
    paddingRight: 40,
    fontSize: '0.85rem',
    color: '#fff',
    background: `linear-gradient(to right, ${palette.primary.main}, ${palette.secondary.dark})`,
    borderTop: '1px solid rgba(255,255,255,0.3)',
  },
  transition: {
    transform: 'translateY(100%)',
  }
})

export default withStyles(styles)(Footer)