import React, {Component} from 'react'
import withStyles from 'styles'
import {Bar, defaults} from 'react-chartjs-2'
import moment from "moment/moment";

const COLORS = {
  contractedWater: '#2d2b74',
  contractedReleaseWater: '#FA0057',
  contractedEntitlement: '#07b151',
}

const LABELS = {
  contractedWater: 'Contracted Water',
  contractedReleaseWater: 'SW Releases excl. RRIA Entitlement.',
  contractedEntitlement: 'RRIA Entitlement',
  milm3: 'million m³',
}

class ContractedWater extends Component {
  get data() {
    let {dailySummaries = []} = this.props
    return {
      labels: dailySummaries.map(({date}) => date),
      datasets: [{
        type: 'line',
        lineTension: 0,
        label: LABELS.contractedWater,
        borderColor: COLORS.contractedWater,
        backgroundColor: COLORS.contractedWater,
        pointRadius: 0,
        fill: false,
        data: dailySummaries.map(({contractedWater}) => milm3(contractedWater))
      }, {
        type: 'line',
        lineTension: 0,
        label: LABELS.contractedReleaseWater,
        borderColor: COLORS.contractedReleaseWater,
        backgroundColor: COLORS.contractedReleaseWater,
        pointRadius: 0,
        fill: false,
        data: dailySummaries.map(({contractedReleaseWater}) => milm3(contractedReleaseWater))
      }, {
        label: LABELS.contractedEntitlement,
        borderColor: COLORS.contractedEntitlement,
        backgroundColor: COLORS.contractedEntitlement,
        pointRadius: 0,
        fill: false,
        data: dailySummaries.map(({contractedEntitlement}) => milm3(contractedEntitlement))
      }]
    }
  }

  get endOfSeason() {
    return this.props.schemeSeason.endsOn
  }

  get options() {
    return {
      layout: {
        padding: {
          top: 0,
        }
      },
      legend: {
        labels: {
          boxWidth: 15,
          padding: 30,
        },
      },
      tooltips: {
        mode: 'index',
        position: 'nearest',
      },
      scales: {
        xAxes: [{
          type: 'time',
          time: {
            unit: 'day',
          },
          gridLines: {
            color: '#BFBFBF',
            tickMarkLength: 7,
            borderDash: [4]
          },
          ticks: {
            minRotation: 90,
            padding: 7,
            source: 'auto',
            max: this.endOfSeason,
            maxTicksLimit: 15,
          }
        }],
        yAxes: [{
          id: 'left-y-axis',
          gridLines: {
            zeroLineColor: '#000',
            color: '#BFBFBF',
            tickMarkLength: 7,
            borderDash: [4]
          },
          scaleLabel: {
            display: 'true',
            labelString: LABELS.milm3,
            fontFamily: 'Roboto'
          },
          ticks: {
            min: 0,
            padding: 7,
          },
        }]
      },
    }
  }

  get plugins() {
    return [{
      beforeInit: function (chart) {
        chart.legend.afterFit = function () {
          this.height = this.height + 23;
        };
      }
    }]
  }

  render = () => {
    const {classes, dailySummaries} = this.props
    let lastDay = dailySummaries?.at(-1)
    defaults.global.defaultFontColor = '#000'
    defaults.global.defaultFontFamily = 'Roboto'
    defaults.global.defaultFontSize = 16
    return (
      <div className={this.props.classes.root}>
        {lastDay &&
          <div>
            <p>At {moment(lastDay.date).format('ll')}:</p>
            <div className={classes('figureItemBox', 'figureItemBoxCw')}/>
            <b>{LABELS.contractedWater}</b>: {milm3(lastDay.contractedWater)} {LABELS.milm3}<br/>
            <div className={classes('figureItemBox', 'figureItemBoxCrw')}/>
            <b>{LABELS.contractedReleaseWater}</b>: {milm3(lastDay.contractedReleaseWater)} {LABELS.milm3}<br/>
            <div className={classes('figureItemBox', 'figureItemBoxCe')}/>
            <b>{LABELS.contractedEntitlement}</b>: {milm3(lastDay.contractedEntitlement)} {LABELS.milm3}
          </div>
        }
        <Bar data={this.data} options={this.options} height={200} plugins={this.plugins}/>
      </div>
    )
  }
}

const milm3 = m3 => (m3 === null || m3 === undefined) ? m3 : (m3 / 1e6).toFixed(3)

const styles = {
  figureItemBox: {
    width: '.75em',
    height: '.75em',
    display: 'inline-block',
    marginRight: '.5em',
  },
  figureItemBoxCw: {
    backgroundColor: COLORS.contractedWater
  },
  figureItemBoxCrw: {
    backgroundColor: COLORS.contractedReleaseWater
  },
  figureItemBoxCe: {
    backgroundColor: COLORS.contractedEntitlement
  }
}

export default withStyles(styles)(ContractedWater)