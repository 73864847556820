import React, {Component} from 'react'
import {connect} from 'react-redux'
import {AccountActions, IrrigatorActions} from 'actionsets'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import Dependent from 'containers/shared/Dependent'
import {FormContext} from 'components'
import {Select} from 'components'
import MenuItem from '@material-ui/core/MenuItem'
import PageContainer from 'components/PageContainer'
import LoadedHeader from 'components/LoadedHeader'
import LoadedContent from 'components/LoadedContent'

import {compose} from 'utils'
import withStyles from 'styles'
import PromiseButton from 'components/PromiseButton'
import PrioritiesTable from 'components/PrioritiesTable'

export class Form extends InstanceFormMixin(Component) {

  constructor(props) {
    super(props)
    AccountActions.bindActions(this)
    IrrigatorActions.bindActions(this, 'irrigators')
  }

  fetchParams = {
    include: 'priorities'
  }

  get formObject() {
    return {...this.props.account, ...this.state.formAttributes}
  }

  get irrigators() {
    return this.props.irrigators
  }

  async dependsOn() {
    await Promise.all(
      [
        this.actions.irrigators.index(),
        super.dependsOn()
      ]
    )
  }

  renderIrrigatorNames = ({id, name}) =>
    <MenuItem key={id} value={id}>{name}</MenuItem>

  render = () =>
    <PageContainer>
      <LoadedHeader name={this.formObject.name}>
        {this.editMode ? `Edit Account` : 'New Account'}
      </LoadedHeader>
      <LoadedContent>
        <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange}
                     onSubmit={this.save}>
          {this.renderErrorMessages()}
          <Select member='accountType'>
            <MenuItem value='ctop'>CTOP</MenuItem>
            <MenuItem value='principal_volume'>Principal Volume</MenuItem>
            <MenuItem value='additional_water'>Additional Water</MenuItem>
            <MenuItem value='refill'>Refill</MenuItem>
            <MenuItem value='special_restore'>Special Restore</MenuItem>
            <MenuItem value='overdraft'>Overdraft</MenuItem>
            <MenuItem value='rria_transfer'>RRIA Transfer</MenuItem>
          </Select>
          <Select member='irrigatorId' label='Irrigator'>
            {this.irrigators.map(this.renderIrrigatorNames)}
          </Select>
          <PrioritiesTable member='priorities' errors={this.errorContext}/>
          <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
        </FormContext>
      </LoadedContent>
    </PageContainer>
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
}

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({accounts, irrigators}) => ({...irrigators, ...accounts})),
)(Form)
