import React, {Component} from 'react'
import Table, {TableBody, TableCell, TableHead, TableRow} from 'components/CollapsibleTable'
import TextField from '@material-ui/core/TextField'
import DatePicker from 'components/DatePicker'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import LoadedCard from 'components/LoadedCard'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import FormContext from 'components/FormContext'

class PrioritiesTable extends Component {
  handleAddPriority = () => {
    this.handleChange([...(this.props.value || []), {}])
  }

  handleDeletePriority = (priority) => () => {
    if (priority.id) {
      this.handleChange(this.props.value.map((p) => p === priority ? {...p, _destroy: true} : p))
    } else {
      this.handleChange(this.props.value.filter((p) => p !== priority))
    }
  }

  handleChange = (value) => {
    this.props.onChange({target: {value}})
  }

  get errorContext() {
    const errors = {}
    Object.keys(this.props.errors || {}).forEach(key => {
      if (key.startsWith(this.props.name)) {
        errors[key.slice(this.props.name.length)] = this.props.errors[key]
      }
    })
    return errors
  }

  render = () => {
    const {value} = this.props
    return (
      <FormContext context={value || []} onChange={this.handleChange} errorContext={this.errorContext}>
        <LoadedCard>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Priority
                </TableCell>
                <TableCell>
                  Effective From
                </TableCell>
                <TableCell style={{width: 1}}/>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(value) && value.map((priority, i) => (
                (!priority._destroy && (
                  <TableRow key={`tableRow_${i}`}>
                    <TableCell>
                      <TextField member={`[${i}].priority`}/>
                    </TableCell>
                    <TableCell>
                      <DatePicker member={`[${i}].effectiveOn`} label="Effective From"/>
                    </TableCell>
                    <TableCell style={{padding: 0, width: 1}}>
                      <IconButton onClick={this.handleDeletePriority(priority)}>
                        <DeleteIcon/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ))}
            </TableBody>
          </Table>
        </LoadedCard>
        <Button color='primary' onClick={this.handleAddPriority}><AddIcon/> Add Priority</Button>
      </FormContext>
    )
  }
}

export default PrioritiesTable
