import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import MuiTable from '@material-ui/core/Table';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse'
import withStyles from 'styles'
import {withContext} from 'utils/Context'

const StyleContext = React.createContext({})

class Table extends Component{

  state = {
    collapsed: false
  }

  static defaultProps = {
    breakpoint: 600
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize)
  }

  refHandler = (reactNode) => {
    this.props.ref && this.props.ref(reactNode)
    this.domNode = reactNode && ReactDOM.findDOMNode(reactNode)
    this.handleResize()
  }

  handleResize = () => {
    if (this.domNode && this.props.breakpoint < this.domNode.offsetWidth + 50) {
      this.setState({
        collapsed: false
      })
    }
    if (this.domNode && (this.props.breakpoint > this.domNode.offsetWidth || this.domNode.offsetWidth > window.innerWidth - 40)) {
      this.setState({
        collapsed: true
      })
    }
  }


  render = () => {
    const {breakpoint, secondary = false, className, classes, ...props} = this.props
    const {collapsed = false} = this.state
    return (
      <StyleContext.Provider value={{classes}}>
        <MuiTable {...props} ref={this.refHandler} className={[className, classes({table: true, secondary, collapsed})].join(' ')} />
      </StyleContext.Provider>
    )
  }
}

export const styles = theme => ({
  table: {
    '& > tbody > tr': {
      borderTop: '1px solid rgba(180,180,180,0.3)',
      '&$details': {
        borderTop: 'none',
      },
      '& > td + td': {
        borderLeft: '1px solid rgba(180,180,180,0.3)',
      },
      '& > td': {
        fontSize: '1rem',
        fontWeight: 'normal',
        borderBottom: 'none',
        padding: '8px 18px',
        '@media print': {
          padding: 8,
        },
      },
      '@media print': {
        backgroundColor: 'transparent',
        borderTop: '1px solid #EEE',
        height: '15px'
      }
    },
    '& > thead > tr': {
      '& > th + th': {
        borderLeft: '1px solid rgba(180,180,180,0.3)',
      },
      '& > th': {
        fontSize: '1.125rem',
        color: '#000',
        padding: '9px 18px',
      },
    },
    '&$collapsed': {
      width: '100',
      '& > tbody': {
        display: 'block',
        width: '100%',
        '& > tr': {
          width: '100%',
          height: 'inherit',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          padding: '10px 10px 20px 10px',
          boxSizing: 'border-box',
          '& > td': {
            display: 'block',
            border: 'none',
            width: '100%',
            textAlign: 'right',
            boxSizing: 'border-box',
            padding: '10px 10px 0px',
            order: 100,
            '&[data-mobile-label]::before': {
              content: "attr(data-mobile-label)':'",
              display: 'inline-block',
              float: 'left'
            },
            '&$mobileHeading': {
              order: 1,
              '&:first-child': {
                fontSize: '1.125rem',
                flex: 1,
                textAlign: 'left',
                fontWeight: 'bold',
              },
              width: 'auto !important',
              flexGrow: 0,
              paddingLeft: '10px !important',
              paddingRight: '10px !important',
            }
          },
        },
      },
      '& thead': {
        display: 'none'
      }
    }
  },
  collapsed: {},
  actionsCell: {
    textAlign: 'center'
  },
  numericCell: {
    textAlign: 'right'
  },
  details: {
    height: 'auto !important',
    padding: '0 !important',
    '& > td': {
      position: 'relative',
      padding: '0 !important',
      backgroundColor: '#FAFAFA',
      textAlign: 'left !important'
    }
  },
  mobileHeading: {},
  secondary: {
    '&$collapsed': {
      width: '100%',
      marginLeft: 0,
      marginRight: 0,
    },
    '&:not($collapsed)': {
      marginBottom: 20,
      '& > thead > tr': {
        height: 35,
        '& > th': {
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.87)'
        }
      },
      '& > tbody > tr': {
        height: 28,
        '& > td': {
          paddingTop: 0,
          paddingBottom: 0,
        },
      }
    },
    '& > tbody > tr': {
      backgroundColor: 'transparent',
      borderTop: 'none',
    }
  },
  dontPrint: {
    '@media print': {
      display: 'none'
    }
  },
  onlyPrint: {
    '@media screen': {
      display: 'none'
    }
  }
})

export class TableHead extends MuiTableHead{}
export class TableBody extends MuiTableBody{}
export const TableRow = withContext(StyleContext)(class extends Component{
  render = () => {
    const {classes, ...props} = this.props
    return (
      <MuiTableRow {...props}/>
    )
  }
})
export const TableExpander = withContext(StyleContext)(class extends Component{
  render = () => {
    const {classes, children, expanded, ...props} = this.props
    return (
      <MuiTableRow {...props} className={classes.details}>
        <MuiTableCell colSpan={100}>
          <Collapse in={expanded}>
            <div style={{borderTop: '1px solid rgba(180,180,180,0.3)', padding: '15px 10px'}}>
              {children}
            </div>
          </Collapse>
        </MuiTableCell>
      </MuiTableRow>
    )
  }
})
export const TableCell = withContext(StyleContext)(class extends Component{
  static propTypes = {
    mobileHeading: PropTypes.bool,
    mobileLabel: PropTypes.string,
  }

  render = () => {
    const {className, classes, dontPrint = false, onlyPrint = false, mobileHeading = false, mobileLabel, ...props} = this.props
    return (
      <MuiTableCell {...props} className={[className, classes({mobileHeading, dontPrint, onlyPrint})].join(' ')} data-mobile-label={mobileLabel} />
    )
  }
})


export default withStyles(styles)(Table)
