import React, { Component, Fragment } from 'react'
import { Prompt } from 'react-router'
import { connect } from 'react-redux'
import { connectQueryString } from 'containers/shared'
import { IrrigatorDailyEntryActions, IrrigatorActions, AccountActions } from 'actionsets'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Select from 'components/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PromiseButton from 'components/PromiseButton'
import Collapse from '@material-ui/core/Collapse'
import AccountSummary from 'components/AccountSummary'
import VolumeField from 'components/units/VolumeField'
import Volume from 'components/units/Volume'
import { compose, Authorization } from 'utils'
import withStyles from 'styles'
import PageContainer from 'components/PageContainer'
import CalendarForm from 'components/CalendarForm'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import ActionHeader from 'components/ActionHeader'
import ExpandButton from 'components/ExpandButton'
import HelpButton from 'components/HelpButton'
import ExportButton from 'components/index_view/ExportButton'

export class Form extends Component {

  constructor(props){
    super(props)
    IrrigatorDailyEntryActions.bindActions(this)
    IrrigatorActions.bindActions(this, 'irrigators')
    AccountActions.bindActions(this, 'accounts')
  }

  initialState = {
    waterTake: {
      dateContexts: [],
    },
    releaseRequest: {
      dateContexts: [],
    },
    showWaterTake: true,
    showReleaseRequest: true,
  }

  state = this.initialState

  componentDidMount = () => {
    this.actions.irrigators.index({include: 'scheme.schemeSeasons'})

    this.loadAccounts()
    this.handleEntryDate()
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.irrigatorId !== prevProps.match.params.irrigatorId && !this.props.currentUser.irrigatorId) {
      this.setState(this.initialState)
      this.loadAccounts()
    }

    if (this.props.query !== prevProps.query) {
      this.handleEntryDate()
    }
  }

  handleEntryDate = () => {
    const {entryDate, ...queryParams} = this.props.queryParams
    if (entryDate) {
      if (moment(entryDate).isBefore(moment(), 'd')) {
        this.props.onQueryParamsChange({...queryParams, waterTakeDate: entryDate})
        this.setState({showWaterTake: true, showReleaseRequest: false})
      }
      else {
        this.props.onQueryParamsChange({...queryParams, releaseRequestDate: entryDate})
        this.setState({showWaterTake: false, showReleaseRequest: true})
      }
    }
  }

  get errorContext() {
    const errors = this.props.errors.bulkUpdate
    return errors && errors.meta
  }

  loadAccounts = () => {
    if (this.irrigatorId) {
      this.actions.accounts.indexForIrrigator({irrigatorId: this.irrigatorId, clearExisting: true})
    }
  }

  get irrigatorId() {
    return this.props.currentUser.irrigatorId || this.props.match.params.irrigatorId
  }

  get irrigator() {
    return this.props.irrigators.find(irrigator => irrigator.id === this.irrigatorId) || {scheme: {schemeSeasons: []}}
  }

  releaseRequestOpen = entry => {
    if (!entry.schemeSeasonOpen)
      return false

    const date = moment(entry.entryDate)
    const tomorrow = moment().add(1, 'd')
    return date.isAfter(tomorrow, 'd') || (date.isSame(tomorrow, 'd') && moment().hour() < 19)
  }

  toggleCollapse = name => () => {
    this.setState({[`show${name}`]: !this.state[`show${name}`]})
  }

  handleRequestDates = name => async(entryDates, {clear}={}) => {
    const result = await this.actions.indexForIrrigator({
      irrigatorId: this.irrigatorId,
      pageSize: entryDates.length,
      filter: {entryDate: entryDates}
    })
    const dateContexts = clear ? [] : [...this.state[name].dateContexts]
    entryDates.forEach(entryDate => {
      dateContexts.push(result['data'].find(d => moment(entryDate).isSame(d.entryDate, 'd'))
                        || {entryDate: moment(entryDate).format('YYYY-MM-DD'), canEnterWaterTake: true})
    })
    this.setState({[name]: {...this.state[name], dateContexts}})
  }

  handleDateSelected = name => selectedDate => {
    this.props.onQueryParamsChange({...this.props.queryParams, [`${name}Date`]: moment(selectedDate).format('YYYY-MM-DD')})
  }

  getSelectedDate = (name, defaultValue) => {
    const date = this.props.queryParams[`${name}Date`]
    return date ? moment(date) : defaultValue
  }

  handleChange = name => dateContexts => {
    this.dirty = true
    this.setState({[name]: {...this.state[name], dateContexts}})
  }

  handleChangeIrrigator = ({target: {value}}) => {
    this.props.history.replace(`/irrigators/${value}/daily_entries`)
  }

  handleSave = name => async() => {
    await this.actions.bulkUpdate({
      irrigatorId: this.irrigatorId,
      dailyEntries: this.state[name].dateContexts.map(context => {
        const {entryDate, waterTake, normalConsentedWater, requestedRelease, canEnterWaterTake} = context
        return {
          entryDate,
          ...name === 'waterTake' && {...canEnterWaterTake && {waterTake, normalConsentedWater}, canEnterWaterTake},
          ...name === 'releaseRequest' && this.releaseRequestOpen(context) && {requestedRelease}
        }
      })
    })
    this.dirty = false
    await this.handleRequestDates(name)(this.state[name].dateContexts.map(c => c.entryDate), {clear: true})
  }

  renderWaterTakeSummary = context => {
    const {classes} = this.props
    const highlightActual = !context.waterTake && context.waterTake !== 0 && moment(context.entryDate).isBefore(moment().subtract(1, 'd'), 'd')
    const highlightNormalConsented = !context.normalConsentedWater && context.normalConsentedWater !== 0 && moment(context.entryDate).isBefore(moment().subtract(1, 'd'), 'd')
    return (
      <dl className={classes.waterTakeSummary}>
        <dt className={classes({highlightBlank: highlightNormalConsented})}>Normal Consented</dt>
        <dd className={classes({highlightBlank: highlightNormalConsented})}><Volume>{context.normalConsentedWater}</Volume></dd>
        <dt className={classes({highlightBlank: highlightActual})}>Actual Take</dt>
        <dd className={classes({highlightBlank: highlightActual})}><Volume>{context.waterTake}</Volume></dd>
      </dl>
    )
  }

  handleLeavePage = location => {
    if (location.pathname !== this.props.location.pathname)
      return 'You have unsaved changes, are you sure you want to leave'
    else
      return true
  }

  renderReleaseRequestSummary = context =>
    <dl className={this.props.classes.waterTakeSummary}>
      <dt>Requested</dt>
      <dd><Volume>{context.requestedRelease}</Volume></dd>
      <dt>Approved</dt>
      <dd><Volume>{context.confirmedRelease}</Volume></dd>
    </dl>

  render = () =>
    <Fragment>
      <Prompt when={!!this.dirty} message={this.handleLeavePage}/>

      {this.irrigatorId && <AccountSummary accounts={this.props.accounts} />}
      <PageContainer>
        <ActionHeader variant='h5' title='Water Log'>
          {this.irrigatorId &&
            <ExportButton resource={`irrigators/${this.irrigatorId}/irrigator_daily_entries`} />
          }
        </ActionHeader>

        {!Authorization.irrigator &&
          <Select value={this.irrigatorId || ''} onChange={this.handleChangeIrrigator} label='Irrigator'>
            {this.props.irrigators.map(irrigator =>
              <MenuItem key={irrigator.id} value={irrigator.id}>{irrigator.name}</MenuItem>
            )}
          </Select>
        }

        {this.irrigatorId && this.irrigator.scheme &&
          <Fragment>
            <ActionHeader className={this.props.classes.sectionHeader} title="Water Takes" variant="h6" startAdornment={
              <ExpandButton onClick={this.toggleCollapse('WaterTake')} expanded={this.state.showWaterTake}/>
            }>
              <HelpButton>
                Actual Take should INCLUDE any Stored Water taken on the day, but Normal Consented Water should EXCLUDE any Stored Water requested for the day.
                All quantities apply to the midnight-to-midnight period of the day in question.
              </HelpButton>
            </ActionHeader>
            <Collapse in={this.state.showWaterTake}>
              <CalendarForm selectedDate={this.getSelectedDate('waterTake', moment().subtract(1, 'd'))}
                            dateField="entryDate"
                            onDateSelected={this.handleDateSelected('waterTake')}
                            onChange={this.handleChange('waterTake')} dateContexts={this.state.waterTake.dateContexts}
                            onRequestDates={this.handleRequestDates('waterTake')}
                            dateSummary={this.renderWaterTakeSummary}
                            maxDate={moment().subtract(1, 'd')}
                            key={this.irrigatorId}
                            errorContext={this.errorContext}
                            seasons={this.irrigator.scheme.schemeSeasons}
              >
                {context =>
                  <div className={this.props.classes.columns}>
                    <div>
                      {Authorization.admin &&
                        <Typography><Checkbox member='canEnterWaterTake' style={{marginLeft: -14}} type='checkbox'/>Editable</Typography>
                      }
                      <VolumeField member='normalConsentedWater' label='Normal Consented' fullWidth disabled={!context.canEnterWaterTake || Authorization.viewer}/>
                      { context?.entryDate && this.errorContext && this.errorContext[context?.entryDate] && this.errorContext[context?.entryDate]['normalConsentedWater'] && (
                        <Typography style={{color: '#FA0057', fontSize: '0.8rem'}}>{this.errorContext[context?.entryDate]['normalConsentedWater']}</Typography>
                      )}
                      <VolumeField member='waterTake' label='Actual Take' fullWidth disabled={!context.canEnterWaterTake || Authorization.viewer}/>
                      { context?.entryDate && this.errorContext && this.errorContext[context?.entryDate] && this.errorContext[context?.entryDate]['waterTake'] && (
                        <Typography style={{color: '#FA0057', fontSize: '0.8rem'}}>{this.errorContext[context?.entryDate]['waterTake']}</Typography>
                      )}
                    </div>
                    <div>
                      <dl className={this.props.classes.dataDetails}>
                        <dt>Release Request</dt>
                        <dd><Volume>{context.requestedRelease}</Volume></dd>
                        <dt>Approved Request</dt>
                        <dd><Volume>{context.confirmedRelease}</Volume></dd>
                        <dt>Actual Release</dt>
                        <dd><Volume>{context.actualRelease}</Volume></dd>
                        <dt>Status</dt>
                        <dd><Tooltip title={context.releaseStatusText || ''}><span>{context.releaseStatus || '–'}</span></Tooltip></dd>
                      </dl>
                    </div>
                  </div>
                }
              </CalendarForm>
              {!Authorization.viewer &&
              <PromiseButton variant='contained' color='secondary' className={this.props.classes.saveButton}
                             onClick={this.handleSave('waterTake')}>Save</PromiseButton>
              }
            </Collapse>
            <ActionHeader className={this.props.classes.sectionHeader} title='Release Requests' variant='h6' startAdornment={
              <ExpandButton onClick={this.toggleCollapse('ReleaseRequest')} expanded={this.state.showReleaseRequest}/>
            }>
              <HelpButton>
                Requested quantities apply for Release Date (midnight-to-midnight period). The cut-off time for requests to be entered or modified is 7 pm the evening prior to Release Date.
                <ul>
                  <li>Status of “Approved” is required before Stored Water may be taken. Status is reviewed by Manawa Energy by 9 pm on the evening prior to release, and may change up to that time.</li>
                  <li>Approved Release may differ from Requested Release if Manawa Energy applies any curtailment.</li>
                  <li>Actual Release may differ from Confirmed Release resulting in further curtailment due to insufficient stored water being released from the station.</li>
                </ul>
              </HelpButton>
            </ActionHeader>
            <Collapse in={this.state.showReleaseRequest}>
              <CalendarForm selectedDate={this.getSelectedDate('releaseRequest', moment().add(1, 'd'))}
                            dateField="entryDate"
                            onDateSelected={this.handleDateSelected('releaseRequest')}
                            onChange={this.handleChange('releaseRequest')}
                            dateContexts={this.state.releaseRequest.dateContexts}
                            onRequestDates={this.handleRequestDates('releaseRequest')}
                            dateSummary={this.renderReleaseRequestSummary}
                            minDate={moment()}
                            maxDate={moment().add(7, 'd')}
                            key={this.irrigatorId}
                            errorContext={this.errorContext}
                            seasons={this.irrigator.scheme.schemeSeasons}
              >
                {context =>
                  <div className={this.props.classes.columns}>
                    <div>
                      <VolumeField member='requestedRelease' label='Release Request' fullWidth disabled={!this.releaseRequestOpen(context) || Authorization.viewer}/>
                    </div>
                    <div>
                      <dl className={this.props.classes.dataDetails}>
                        <dt>Approved Request</dt>
                        <dd><Volume>{context.confirmedRelease}</Volume></dd>
                        <dt>Status</dt>
                        <dd><Tooltip title={context.releaseStatusText || ''}><span>{context.releaseStatus || '–'}</span></Tooltip></dd>
                        <HelpButton>
                          <ul>
                            <li><b>C</b> = Confirmed (Stored Water may be taken)</li>
                            <li><b>PC</b> = Provisionally Confirmed (but status may change up to 9 pm on day prior to release)</li>
                            <li><b>D</b> = Declined (No Stored Water may be taken)</li>
                            <li><b>PD</b> = Provisionally Declined (but status may change up to 9 pm on day prior to release)</li>
                            <li><b>NYA</b> = Not Yet Assessed by Manawa Energy</li>
                          </ul>
                        </HelpButton>
                      </dl>
                    </div>
                  </div>
                }
              </CalendarForm>
              {!Authorization.viewer &&
              <PromiseButton variant='contained' color='secondary' className={this.props.classes.saveButton}
                             onClick={this.handleSave('releaseRequest')}>Save</PromiseButton>
              }
            </Collapse>
          </Fragment>
        }
      </PageContainer>
    </Fragment>
}

const styles = ({palette}) => ({
  waterTakeSummary: {
    margin: 0,
    '& dt': {
      fontSize: '0.75rem',
      fontWeight: 'bold',
    },
    '& dd': {
      margin: '0 0 5px',
      '&:last-child': {
        margin: 0
      }
    }
  },
  dataDetails: {
    margin: '10px 10px 0 0',
    marginTop: 20,
    '& dt': {
      fontSize: '0.8rem',
    },
    '& dd': {
      margin: '0 0 5px',
      '&:last-child': {
        margin: 0
      }
    },
    '@media(min-width: 600px)': {
      textAlign: 'right',
      marginTop: 10,
    }
  },
  expandButton: {
    border: `2px solid ${palette.primary.main}`,
    color: palette.primary.main,
    '&$expanded': {
      transform: 'rotate(180deg)'
    },
    transition: 'transform 500ms',
    position: 'absolute',
    left: 0,
    top: 'calc(50% - 26px)'
  },
  expanded: {},
  sectionHeader: {
    marginTop: 40,
    marginBottom: 26,
    position: 'relative',
  },
  saveButton: {
    float: 'right',
    margin: '10px 50px',
    width: 'auto',
    '@media(min-width: 900px)': {
      marginRight: 10,
    },
    '@media(min-width: 600px)': {
      width: 250
    }
  },
  columns: {
    '& > div': {
      flex: 1
    },
    '@media(min-width: 600px)': {
      display: 'flex',
    }
  },
  infoButton: {
    float: 'right'
  },
  highlightBlank: {
    color: palette.error.main
  },
})

export default compose(
  withStyles(styles),
  connectQueryString({customQueryParams: ['waterTakeDate', 'releaseRequestDate', 'entryDate']}),
  connect(({tokens, irrigatorDailyEntries, irrigators, accounts}) => ({...accounts, ...irrigators, ...tokens, ...irrigatorDailyEntries})),
)(Form)
