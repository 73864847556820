import React, {Component} from 'react'
import withStyles from 'styles'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import ResizeCard from 'components/ResizeCard'
import FormContext from 'components/FormContext'
import Table, {TableHead, TableBody, TableRow, TableCell} from 'components/CollapsibleTable'
import EditIcon from '@material-ui/icons/Edit'
import ExpandIcon from '@material-ui/icons/ExpandMore'
import ExpandButton from 'components/ExpandButton'
import Volume from 'components/units/Volume'
import VolumeField from 'components/units/VolumeField'
import DataList, {DataValue} from 'components/DataList'
import moment from 'moment'
import {Authorization} from 'utils'
import ConfirmationDialog from 'components/ConfirmationDialog'
import Tooltip from '@material-ui/core/Tooltip'

class RequestConfirmation extends Component {
  state = {
    showDialog: false,
    dialogValues: ''
  }

  handleDialogOpen = () => {
    const dailyEntry = (this.props.irrigator.dailyEntries || [])[0] || {}
    this.setState({
      showDialog: true,
      dialogValues: {
        confirmedAmount: dailyEntry.confirmedRelease,
        requestedAmount: dailyEntry.requestedRelease
      }
    })
  }

  handleDialogConfirm = () => {
    this.props.onChangeConfirmedRequest(this.state.dialogValues)
    this.setState({showDialog: false})
  }

  handleDialogCancel = () => {
    this.setState({showDialog: false})
  }

  render = () => {
    const {classes, irrigator, expanded, onExpand} = this.props
    const dailyEntries = irrigator.dailyEntries || []
    const nothing = !!dailyEntries[0].requestedRelease && Number(dailyEntries[0].confirmedRelease) === 0
    const incomplete = !nothing && Number(dailyEntries[0].requestedRelease) > Number(dailyEntries[0].confirmedRelease)
    const firstEntry = dailyEntries[0] || {}
    return (
      <li key={irrigator.id} className={classes.root}>
        <div className={classes.wrapperInner}>
          <div className={classes.keyInfo}>
            <ExpandButton expanded={expanded} onClick={onExpand} />
            <div className={classes.details}>
              <Typography variant='subtitle1' className={classes.title}>{irrigator.name}&nbsp;(P{irrigator.priority})</Typography>
              <div className={classes.detailsContent}>
                <DataList>
                  <DataValue title='Requested'>
                    <Volume>{firstEntry.requestedRelease}</Volume>
                  </DataValue>
                  <DataValue title='Approved' className={classes({incomplete, nothing})}>
                    <Volume>{firstEntry.confirmedRelease}</Volume>
                  </DataValue>
                  <DataValue title='Status' className={classes({incomplete, nothing})}>
                    <Tooltip title={firstEntry.releaseStatusText || ''}><span>{firstEntry.releaseStatus || '–'}</span></Tooltip>
                  </DataValue>
                </DataList>
                {Authorization.admin &&
                <Tooltip title="Edit">
                  <IconButton className={classes.editIcon} onClick={this.handleDialogOpen}>
                    <EditIcon/>
                  </IconButton>
                </Tooltip>
                }
              </div>
            </div>
          </div>
          <Collapse in={expanded}>
            <div className={classes.tableWrapper}>
              <ResizeCard className={classes.tableCard}>
                <Table breakpoint={300} className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Request</TableCell>
                      <TableCell>Approved</TableCell>
                      <TableCell>Actual</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dailyEntries.map((entry, i) =>
                      i > 0 &&
                      <TableRow key={entry.id}>
                        <TableCell mobileHeading>{moment(entry.entryDate).format('DD MMM')}</TableCell>
                        <TableCell mobileLabel="Request"><Volume>{entry.requestedRelease}</Volume></TableCell>
                        <TableCell mobileLabel="Approved"><Volume>{entry.confirmedRelease}</Volume></TableCell>
                        <TableCell mobileLabel="Actual"><Volume>{entry.actualRelease}</Volume></TableCell>
                        <TableCell mobileLabel="Status"><Tooltip title={entry.releaseStatusText || ''}><span>{entry.releaseStatus || '–'}</span></Tooltip></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </ResizeCard>
              <Button color='primary' onClick={this.props.onMore}><ExpandIcon/> more</Button>
            </div>
          </Collapse>
        </div>
        <ConfirmationDialog title={irrigator.name}
                            onConfirm={this.handleDialogConfirm}
                            onCancel={this.handleDialogCancel}
                            open={this.state.showDialog}
        >
          <FormContext context={this.state.dialogValues || {}} onChange={context => this.setState({dialogValues: context})}>
            <VolumeField member='requestedAmount' label='Requested Release'/>
            <VolumeField member='confirmedAmount' label='Approved Request' max={this.state.dialogValues.requestedAmount}/>
          </FormContext>
        </ConfirmationDialog>
      </li>
    )
  }
}

const styles = ({palette}) => ({
  root: {
    flex: '1 0 auto',
    marginBottom: 33,
    '@media(min-width: 600px)': {
      marginRight: 60,
    }
  },
  wrapperInner: {
    '@media(min-width: 600px)': {
      width: 500,
    },
  },
  keyInfo: {
    display: 'flex',
    alignItems: 'center',
    height: 94,
  },
  title: {
    paddingBottom: 4,
    marginLeft: -2,
  },
  details: {
    width: 320,
  },
  detailsContent: {
    position: 'relative'
  },
  tableWrapper: {
    textAlign: 'center'
  },
  tableCard: {
    margin: '13px 0 2px',
  },
  incomplete: {
    color: palette.warning.main
  },
  nothing: {
    color: palette.error.main
  },
  editIcon: {
    position: 'absolute',
    top: -4,
    right: 25,
    color: palette.primary.main
  }
})

export default withStyles(styles)(RequestConfirmation)
