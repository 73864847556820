import CrudActionSetBase from './CrudActionSetBase'
import {Irrigators, IrrigatorDailyEntries} from 'api'

export class IrrigatorDailyEntryActionSet extends CrudActionSetBase('irrigatorDailyEntry') {
  static indexForIrrigator(creator, reducer, constants){

    constants.defineRequestConstants()
    creator(({irrigatorId, clearExisting, filter=null, page=1, pageSize=undefined}={}) => (dispatch) => {
      return dispatch({
        type: constants.ACTION,
        promise: Irrigators.dailyEntries({id: irrigatorId, options: {
          page: { number: page, size: pageSize },
          ...filter && {filter}
        }}),
        payload: { clearExisting }
      })
    })

    reducer({
      [constants.REQUEST]: (state, { requestPayload: { page, clearExisting }, request }) => {
        return {...state, page, ...clearExisting && {irrigatorDailyEntries:[]}, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { data, meta: { totalPages, page }} }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, irrigatorDailyEntries: data, totalPages, page }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, index: error }
        return {...state, requests, errors }
      }
    })
  }

  static bulkUpdate(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({irrigatorId, dailyEntries}) => {
      return {
        type: constants.ACTION,
        promise:
          irrigatorId ?
            Irrigators.updateDailyEntries({id: irrigatorId, dailyEntries})
            :
            IrrigatorDailyEntries.bulkUpdate({dailyEntries})
      }
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, bulkUpdateForIrrigator: null }}
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests}
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, bulkUpdate: error }
        return {...state, requests, errors }
      }
    })
  }
}
export default new IrrigatorDailyEntryActionSet()