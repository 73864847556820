import msgpack from 'msgpack-lite'
import qs from 'qs'
import ConsoleOutputGroup from './ConsoleOutputGroup'
import RequestQueue from './RequestQueue'
import ResponseCache from './ResponseCache'

/**
 * APIResource allows you to abstract away the boilerplate of defining the interactions between
 * your client and an API.
 * It has support for
 *  - Caching
 *  - Logging
 *  - Ignoring out of order responses
 *  - Stubbing
 *  - Limiting concurrent requests
 *  - Extending/Decorating existing API Resources
 *  - Multi content-type requests
 *  - Custom headers, parameter and results handling
 *  - Simple definition syntax.
 *
 * **Examples:**
 *
 * A basic resource, with a single index endpoint:
 *
 * ```
 * const AuthorsAPI = new APIResource('/authors', { index: () => {} } )
 * AuthorsAPI.index().then(console.log) => [{name: 'John Smith', role: 'Editor'}]
 * ```
 *
 * A more complex resource, with an index, create, update, and delete:
 *
 * ```
 * const BlogPostsAPI = new APIResource(
 *   '/posts',
 *     {
 *       index: () => {},
 *       create: endpoint => endpoint.method('post'),
 *       update: endpoint => endpoint.method('put'),
 *       delete: endpoint => endpoint.method('delete')
 *     }
 *   )
 * ```
 *
 * There are a large number of configuration options you can apply to endpoints. For example.
 *
 * ```
 * endpoint.method('get').type('msgpack')     // Change request type (default JSON)
 * endpoint.ignoreOutOfOrder(true)            // If you make multiple requests, ignore responses that come in out of order
 * endpoint.cache(true)                       // Cache all requests to the same endpoint with the same arguments indefinitely
 * endpoint.cache(6000)                       // Cache all requests to the same endpoint with the same arguments for 6 seconds
 * endpoint.cache(cachedEntry => true)        // Pass your own handler which returns true if the cachedEntry should be served
 * endpoint.headers({'Authorization': 'abc'}) // Pass custom headers
 * endpoint.path('clear_all')                 // Define the path of the endpoint relative to the base
 * endpoint.logging(true)                     // Enable or disable endpoint logging (default is true only if NODE_ENV is development)
 * endpoint.maxConcurrent(10)                 // Limit the max number of concurrent outstanding requests we can have in progress for an endpoint
 * endpoint.resultsHandler                    // Pass a custom results handler that parses raw results from the underlying request
 * endpoint.paramsHandler                     // Pass your own params handler that allows you to restructure params as needed for a request
 * endpoint.stub({
 *   ok: false,
 *   body: 'An error happened',
 *   response: {}
 * }) // Stub the endpoint so it does not make an actual XHR request
 * ```
 *
 * ```
 * endpoint.stub(() => {
 *   return {
 *     ok: true,
 *     body: [5,3,2],
 *     response: {}
 *   }
 * }) // Stub the endpoint so it does not make an actual XHR request
 *
 * endpoint.localCache(6000)       // Cache in local storage
 * endpoint.timeout(3000)          // ignore any responses that come back after the timeout period
 * ```
 *
 * You can stub multiple endpoints of an APIResource at once (useful for testing):
 *
 * ```
 * resource = new APIResource(...)
 * resource.stubs = {
 *   index:  { ok: true, body: { stubbbed: 'body'} },
 *   create: { ok: false, body: { message: 'create failed'} },
 * }
 * ```
 *
 * You can also extend an existing APIResource definition.
 * This allows you to reuse an existing definition and change only what is needed.
 * E.g:
 *
 * ```
 * const BlogPostsAPI = new APIResource(
 *   '/posts',
 *   {
 *     index: () => {},
 *     create: endpoint => endpoint.method('post'),
 *     update: endpoint => endpoint.method('put'),
 *     delete: endpoint => endpoint.method('delete')
 *   }
 * )
 *
 * const StubbedBlogPostsAPI = BlogPostsAPI.extend({
 *   index: endpoint => endpoint.stub({ok: true, body: 'I am a stubbed index', response: {} })
 * })
 *
 * StubbedBlogPostsAPI.index().then(console.log)
 * ```
 *
 */

export const encodeFormInputs = (data, downloadForm, prefix = '') => {
  if (!data || typeof data !== 'object')
    return
  Object.keys(data).forEach(key => {
    const value = data[key]
    let nestedKey = Array.isArray(data) ? '' : `${key}`
    nestedKey = prefix ? `${prefix}[${nestedKey}]` : key
    if (typeof value === 'object') {
      encodeFormInputs(value, downloadForm, nestedKey)
    } else {
      let input = document.createElement('input');
      input.value = data[key];
      input.name = nestedKey;
      downloadForm.appendChild(input)
    }
  })
}

export const submitForm = (url, data, method) => {
  const downloadForm = document.createElement('form')
  downloadForm.action = url
  downloadForm.method = method || 'post'
  encodeFormInputs(data, downloadForm)
  window.document.body.appendChild(downloadForm)
  downloadForm.submit();
  setTimeout(() => {
    window.document.body.removeChild(downloadForm)
  })
}

export class APIResource {

  static DEFAULT_API_RESOURCE_OPTIONS = {
    headersHandlers: [() => {
      return {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }],
    method: 'get',
    base: null,
    path: "",
    type: 'json',
    logging: false,
    ignoreOutOfOrder: false,
    cache: false,
    localCache: false,
    stub: null,
    useFormData: false,
    useForm: false,
    maxConcurrent: 5,
    timeout: 0,
    paramsHandlers: [params => (params || {})],
    resultsHandlers: [({body}) => body],
    errorHandlers: []
  }

  static DEFAULT_API_BASE = process.env.REACT_APP_API_BASE
  static DEFAULT_BASE = this.DEFAULT_API_BASE.replace(/\/api$/, '')
  static apiResourceSequence = 0

  constructor(base, routes, {extend = {}, apiBase = APIResource.DEFAULT_API_BASE, decorators = []} = {}) {
    this.apiBase = apiBase
    this.base = base
    this.routeOptions = {}
    this.requestQueues = {}
    this.decorators = decorators
    this.responseCache = new ResponseCache(APIResource.apiResourceSequence += 1)
    this.routeNames = Object.keys(routes).concat(Object.keys(extend))
    this.routeNames = this.routeNames.filter((x, i, a) => a.indexOf(x) === i)
    this.routeNames.forEach(routeName => {
      // Extract the endpoint definition builder from the API Resource definition object
      const invokeEndpointDefinition = routes[routeName] || (() => {
      })
      // Build the base set of options from the defaults, and any extended resources
      const options = {...APIResource.DEFAULT_API_RESOURCE_OPTIONS, ...(extend[routeName] || {})}
      // Build the definition
      const proxy = this.buildEndpointProxy(options, ...Object.keys(options))
      this.decorators.forEach(decorator => decorator(proxy))
      invokeEndpointDefinition(proxy)

      // Save it
      this.routeOptions[routeName] = options

      // Add a method to invoke the endpoint to the instance
      this[routeName] = inputParams => this.sendRequest(
        routeName,
        options.paramsHandlers.reduce((params, handler) => handler(params), inputParams),
        options.headersHandlers.reduce((headers, handler) => handler(headers, inputParams), {}),
        inputParams,
        options
      )
        .then(res => options.resultsHandlers.reduce((results, handler) => handler(results), res))
        .catch(err => {
          err = options.errorHandlers.reduce((errors, handler) => handler(errors), err)
          throw err
        })
    })
  }

  /**
   * Build the proxy used for defining endpoint options
   */
  buildEndpointProxy = (options, ...optionNames) => {
    const proxy = {}
    optionNames.forEach(name => proxy[name] = arg => {
      options[name] = arg
      return proxy
    })
    proxy.resultsHandler = (resultsHandler, {overwrite = false} = {}) => {
      if (overwrite)
        options.resultsHandlers = [resultsHandler]
      else
        options.resultsHandlers = [...options.resultsHandlers, resultsHandler]
      return proxy
    }
    proxy.paramsHandler = (paramsHandler, {overwrite = false} = {}) => {
      if (overwrite) {
        options.paramsHandlers = [paramsHandler]
      } else {
        options.paramsHandlers = [...options.paramsHandlers, paramsHandler]
      }
      return proxy
    }
    proxy.errorHandler = (errorHandler, {overwrite = false} = {}) => {
      if (overwrite)
        options.errorHandlers = [errorHandler]
      else
        options.errorHandlers = [...options.errorHandlers, errorHandler]
      return proxy
    }
    proxy.headers = (headersHandler, {overwrite = false} = {}) => {
      if (overwrite)
        options.headersHandlers = [headersHandler]
      else
        options.headersHandlers = [...options.headersHandlers, headersHandler]
      return proxy
    }
    return proxy
  }

  /**
   * Set multiple stubs on an API Resource at once
   */
  set stubs(stubDefinitions) {
    Object.entries(stubDefinitions).forEach(([routeName, stubDefinition]) => {
      this.routeOptions[routeName].stub = stubDefinition
    })
  }

  /**
   * Build a new APIResource definition based of an existing definition
   */
  extend = (base, routes) => new APIResource(base || this.base, routes, {
    extend: this.routeOptions,
    decorators: this.decorators
  })

  /*
   * Encode a query string for parameter passing for GET requests
   */
  getQueryString = params => {
    return params ? qs.stringify(params, {arrayFormat: 'brackets'}) : params
  }

  /*
   * Return and build if needed a request queue for a particular endpoint
   */
  getRequestQueue = endpoint => {
    return this.requestQueues[endpoint] = this.requestQueues[endpoint] || new RequestQueue(endpoint)
  }

  /**
   * Return a cached response if it exists and matches caching criteria
   */
  getCachedResponse = (routeName, params, cache, local) => {
    let cacheEntry, localCacheEntry
    if (cache)
      cacheEntry = this.responseCache.get(routeName, params)
    if (!cacheEntry && local)
      localCacheEntry = this.responseCache.get(routeName, params, {localStorage: true})
    if (!(cacheEntry || localCacheEntry)) return null

    const shouldServe = (cache, entry) => (
      cache &&
      entry && (
        (cache === true) ||
        (typeof cache === 'number' && entry.createdAt > (+new Date() - cache)) ||
        (typeof cache === 'function' && cache({entry, params}))
      )
    )

    if (shouldServe(cache, cacheEntry))
      return cacheEntry.value
    if (shouldServe(local, localCacheEntry))
      return localCacheEntry.value
  }

  /*
   * Save a response into the cache
   */
  setCachedResponse = (routeName, params, body, session, local) => {
    if (session)
      this.responseCache.set(routeName, params, body)
    if (local)
      this.responseCache.set(routeName, params, body, {localStorage: true})
  }

  buildFetch = (url, requestOptions, type, timeout) => new Promise((resolve, reject) => {
    let timedout = false
    if (type === 'msgpack') {
      requestOptions.headers['Accept'] = 'application/msgpack'
    }
    fetch(url, requestOptions).then(response => !timedout && resolve(response)).catch(reject)
    if (timeout) {
      setTimeout(_ => {
        timedout = true
        reject('Request timed out')
      }, timeout)
    }
  }).then(response => {
    const {ok} = response
    const buildResponseStruct = body => ({ok, response, body})
    switch (type) {
      case 'msgpack':
        return response.arrayBuffer().then(body => {
          try {
            return msgpack.decode(new Uint8Array(body))
          } catch (err) {
          }
        }).then(buildResponseStruct)
      default:
        return response.json().then(buildResponseStruct,
          () => {
            return {
              ok,
              response,
              body: {errors: [{title: 'Error', message: 'Could not parse response', status: response.status}]}
            }
          })
    }
  })

  encodePayloadAsJSON = (payload) => {
    return JSON.stringify(payload).replace(/[\u007F-\uFFFF]/g, function (c) {
      return "\\u" + ("0000" + c.charCodeAt(0).toString(16)).substr(-4);
    })
  }

  buildFormData(params, formData, prefix = '') {
    const isArray = Array.isArray(params)
    Object.keys(params).forEach(key => {
      const value = params[key]
      const nestedKey = prefix ? `${prefix}[${isArray ? '' : key}]` : key
      if (value !== undefined && (typeof value !== 'object' || (value && value.constructor) === File)) {
        formData.append(nestedKey, value)
      } else if (typeof value === 'object' && value) {
        this.buildFormData(value, formData, nestedKey)
      }
    })
    return formData
  }

  encodeFormDataInputs = (data, formData, prefix = '') => {
    Object.keys(data).forEach(key => {
      const value = data[key]
      const nestedKey = prefix ? `${prefix}[${key}]` : key
      if (typeof value === 'object' && value.constructor !== File) {
        this.encodeFormDataInputs(value, formData, nestedKey)
      } else {
        formData.append(nestedKey, data[key])
      }
    })
  }

  submitForm = submitForm
  encodeFormInputs = encodeFormInputs

  encodePayloadAsForm = (payload) => {
    return this.buildFormData(payload, new FormData())
  }

  /*
   * Actually perform a requests to an endpoint
   */
  sendRequest = (routeName, params, headers, rawParams, options) => {
    const {
      method, base, path, type, logging,
      maxConcurrent, ignoreOutOfOrder, cache,
      localCache, stub, timeout, useFormData, useForm
    } = options

    let requestPath = String(typeof path === 'function' ? path(rawParams) : path)
    const urlBase = String(!!base ? base : this.base)
    let url = `${this.apiBase}/${urlBase.replace(/^\//, '')}${urlBase ? '/' : ''}${requestPath.replace(/^\//, '')}`.replace(/\/$/, '')

    const requestOptions = {method, headers}
    const requestQueue = this.getRequestQueue(routeName)

    if (method === 'get' && params) {
      url = `${url}?${this.getQueryString(params)}`.replace(/\?$/, '')
    } else if (params && method !== 'get') {
      if (useFormData) {
        requestOptions.body = this.encodePayloadAsForm(params)
        delete requestOptions.headers['Content-Type']
      } else if (useForm) {
        this.submitForm(url, params, method)
        return Promise.resolve({ok: true, body: {meta: {}}})
      } else {
        requestOptions.body = this.encodePayloadAsJSON(params)
      }
    }

    const logGroup = new ConsoleOutputGroup(`${method.toUpperCase()}: ${url}`)
    logGroup.push(['%cParams:', 'color: #FF5100;', params])
    logGroup.push(['%cType:', 'color: #FF5100;', type])
    logGroup.push(['%cHeaders:', 'color: #FF5100;', headers])
    logGroup.push(['%cMethod:', 'color: #FF5100;', method])

    if (requestQueue.length >= maxConcurrent) {
      return Promise.reject(
        `Request would exceed max concurrent requests of ${maxConcurrent} for endpoint ${this.base}:${routeName}`
      )
    }

    let getResponse, cachedResponse
    const queuedRequest = requestQueue.queue()

    if (cache || localCache) {
      cachedResponse = this.getCachedResponse(routeName, params, cache, localCache)
    }

    if (stub) {
      getResponse = Promise.resolve(typeof stub === 'function' ? stub() : stub)
    } else if (cachedResponse) {
      getResponse = Promise.resolve(cachedResponse)
    } else {
      getResponse = this.buildFetch(url, requestOptions, type, timeout).then(decoded => {
        if (!cachedResponse && (cache || localCache)) {
          this.setCachedResponse(routeName, params, decoded, cache, localCache)
        }
        return decoded
      })
    }

    return getResponse.catch(err => {
      requestQueue.complete(queuedRequest)
      throw err
    }).then(({ok, response, body} = {ok: false, response: {}, body: null}) => {
      logGroup.push(['%cFrom Cache:', 'color: #FF5100;', !!cachedResponse])
      logGroup.push(ok ? ['%cResponse:', 'color: green;', body] : ['%cResponse:', 'color: red;', body])

      const responseWasInOrder = requestQueue.complete(queuedRequest)

      if (logging)
        logGroup.finish()

      if (!responseWasInOrder && ignoreOutOfOrder)
        throw new Error(
          `Response received out of order for endpoint ${this.base}:${routeName}. ` +
          `Expecting a sequence number > ${requestQueue.processedSequence} but received ${queuedRequest.sequence}`
        )
      if (!ok) {
        const error = {ok, response, body}
        throw error
      }

      return {ok, response, body}
    })
  }
}

export default APIResource
