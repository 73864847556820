import React, { Component }                from 'react'
import Select                              from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { uuid }                            from 'utils'
import MenuItem from '@material-ui/core/MenuItem';

export class LabeledSelect extends Component{

  constructor(props){
    super(props)
    this.htmlElmId = uuid()
  }

  get children(){
    if(this.props.options){
      return Object.entries(this.props.options).map(([value, label], index) =>
        <MenuItem key={index} value={value}>{label}</MenuItem>
      )
    }else{
      return this.props.children
    }
  }

  render = () => {
    const {fullWidth, label, error, children, options, helperText, required, ...props } = this.props
    return (
      <FormControl fullWidth={!!fullWidth} error={!!error} required={required}>
        <InputLabel htmlFor={this.htmlElmId}>{label}</InputLabel>
        <Select
          inputProps={{id: this.htmlElmId}}
          {...props}
          value={props.value || ''}
        >
          {this.children}
        </Select>
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }

}

export default LabeledSelect