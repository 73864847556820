import CrudActionSetBase from './CrudActionSetBase'
import {Irrigators} from 'api'

export class IrrigatorActionSet extends CrudActionSetBase('irrigator'){
  static dailySummaries(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((id,{dates}={}) => {
      return {
        type: constants.ACTION,
        promise: Irrigators.dailySummaries({id, options: {dates}})
      }
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, dailySummaries: [], requests: [...state.requests, request ], errors: {...state.errors, dailySummaries: null }}
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, dailySummaries: data }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, dailySummaries: error }
        return {...state, requests, errors }
      }
    })
  }
}
export default new IrrigatorActionSet()