import React, {Component} from 'react'
import {connect} from 'react-redux'
import {AccountActions, SnackbarActions} from 'actionsets'
import {Pagination, ErrorBanner} from 'components'
import Dependent from 'containers/shared/Dependent'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import Button from '@material-ui/core/Button'
import LoadedCard from 'components/LoadedCard'
import AccountIcon from '@material-ui/icons/AccountBox'
import {compose, errorStringsFromError, humanize} from 'utils'
import {connectQueryString} from 'containers/shared'
import Table, {TableHead, TableBody, TableRow, TableCell} from 'components/CollapsibleTable'
import Volume from 'components/units/Volume'
import Tooltip from '@material-ui/core/Tooltip'
import withStyles from 'styles'

export class List extends Component {

  constructor(props) {
    super(props)
    AccountActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  dependsOn() {
    return this.loadAccounts()
  }

  loadAccounts = () => {
    return this.actions.index({
      page: this.props.page,
      include: 'irrigator'
    })
  }

  dependenciesMet() {
    return this.props.requests.length === 0
  }

  showAccount = id => () => {
    this.props.history.push(`/accounts/${id}`)
  }

  editAccount = id => event => {
    this.props.history.push(`/accounts/${id}/edit`)
    event.stopPropagation()
  }

  deleteAccount = id => event => {
    this.actions.destroy({id})
      .then(this.loadAccounts)
      .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get accounts() {
    return this.props.accounts
  }

  handlePageChange = async page => {
    await this.props.onPageChange(page)
    this.loadAccounts()
  }

  get errors() {
    let errors = []
    if (this.props.errors.index) {
      errors = errors.concat(this.props.errors.index)
    }
    if (this.props.errors.destroy) {
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <PageContainer>
      <ActionHeader title="Accounts">
        <Button color="primary" variant="contained"
                onClick={() => this.props.history.push('/accounts/new')}>Add</Button>
      </ActionHeader>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageChange={this.handlePageChange}
                  style={{}} linkStyle={{}}/>
      <LoadedCard>
        {this.props.accounts &&
          <Table className={this.props.classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Irrigator</TableCell>
                <TableCell>Available Balance</TableCell>
                <TableCell>Contracted Balance</TableCell>
                <TableCell>Priority</TableCell>
                <TableCell>Account Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.accounts.map(account =>
                <Tooltip key={`tooltip_${account.id}`} title="Edit account">
                  <TableRow className={this.props.classes.row} onClick={this.editAccount(account.id)} key={account.id}>
                    <TableCell mobileHeading><AccountIcon/> {account.irrigator && account.irrigator.name}</TableCell>
                    <TableCell mobileLabel="Available Balance"><Volume>{account.availableBalance}</Volume></TableCell>
                    <TableCell mobileLabel="Contracted Balance"><Volume>{account.contractedBalance}</Volume></TableCell>
                    <TableCell mobileLabel="Priority">{account.priority}</TableCell>
                    <TableCell mobileLabel="Account Type">{humanize(account.accountType)}</TableCell>
                  </TableRow>
                </Tooltip>
              )}
            </TableBody>
          </Table>
        }
      </LoadedCard>
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageChange={this.handlePageChange}
                  style={{}} linkStyle={{}}/>
    </PageContainer>
}

const styles = {
  table: {
    '& svg': {
      color: 'rgba(0, 0, 0, 0.54)',
      verticalAlign: 'middle'
    },
    '& td,th': {
      borderLeft: 'none !important'
    },
    '& > thead > tr': {
      height: 'auto',
      backgroundColor: '#fff',
      '& > th': {
        paddingBottom: '7px',
        fontSize: '0.75rem'
      }
    },
  },
  row: {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.08)',
      cursor: 'pointer'
    }
  }
}

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connectQueryString('accounts'),
  connect(({accounts}) => accounts)
)(List)
