import { ProtectedJsonAPI } from '.'

export const Irrigators = ProtectedJsonAPI.extend(
  '/irrigators',
  {
    index:   endpoint => endpoint,
    create:  endpoint => endpoint.method('post'),
    update:  endpoint => endpoint.method('put').path(({id}) => id),
    destroy: endpoint => endpoint.method('delete').path(({id}) => id),
    show:    endpoint => endpoint.path(({id}) => id),
    dailySummaries: endpoint => endpoint.path(({id}) => `${id}/daily_summaries`),
    dailyEntries: endpoint => endpoint.path(({id}) => `${id}/irrigator_daily_entries`),
    updateDailyEntries: endpoint => endpoint.method('put').path(({id}) => `${id}/irrigator_daily_entries`),
    accounts: endpoint => endpoint.path(({id}) => `${id}/accounts`)
  }
)