import React, {Component} from 'react'
import withStyles from 'styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import {Link, withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {NotificationActions} from 'actionsets'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import {fade} from '@material-ui/core/styles/colorManipulator'
import {TransitionGroup} from 'react-transition-group'
import Collapse from '@material-ui/core/Collapse'
import SystemNotificationTypes from 'constants/SystemNotificationTypes'

export class NotificationsPopup extends Component {

  constructor(props) {
    super(props)
    NotificationActions.bindActions(this)
    this.state = {notifications: []}
  }

  get notifications() {
    return this.state.notifications
  }

  get regularNotifications() {
    return this.notifications.filter(n => n.notificationType !== SystemNotificationTypes.priority)
  }

  componentDidMount = () => {
    this._mounted = true
    this.pollForNotifications(0)
  }

  componentWillUnmount = () => {
    this._mounted = false
  }

  pollForNotifications = (wait = 30 * 1000) => {
    window.setTimeout(async () => {
      if (this._mounted) {
        await this.loadNotifications()
        this.pollForNotifications()
      }
    }, wait)
  }

  loadNotifications = async () => {
    try {
      const {data: notifications} = await this.actions.unread()
      this.setState({notifications})
    } catch (e) {
      // TODO pause notification polling and resume when user authorizes again
      // do not hang on uncaught error
    }
  }

  componentDidUpdate = prevProps => {
    if (this.props.show && !prevProps.show) {
      this.loadNotifications()
    }
    if (this.props.unreadNotifications !== prevProps.unreadNotifications) {
      this.setState({ notifications: this.props.unreadNotifications })
    }
  }

  linkToAction = notification => () => {
    // set notification as read
    if (!notification.read) {
      this.actions.update({id: notification.id, read: true}, {include: 'user'})
      notification.read = true
    }

    // remove notification from state
    this.setState({notifications: this.notifications.filter(n => n !== notification)})

    // redirect to link if exists
    if (notification.linkTo) {
      this.props.history.push(`/${notification.linkTo}`)
    }
  }

  render = () => {
    const {classes, show} = this.props
    return (
      <div className={classes({wrapper: true, hidden: !show})} onClick={this.props.onClose}>
        <div className={classes.popup} onClick={e=> e.stopPropagation()}>
          <List className={classes.notificationList}>
            <TransitionGroup>
              {this.regularNotifications.map(notification =>
                <Collapse key={notification.id}>
                  <ListItem
                    key={notification.id}
                    button
                    classes={{root: classes({notification: true, read: notification.read})}}
                    onClick={this.linkToAction(notification)}
                  >
                    <ListItemText
                      primary={notification.title}
                      secondary={notification.message + (notification.linkTo ? ' →' : '')}
                      classes={{root: classes.notificationText, primary: classes.title, secondary: classes.message}}
                    />
                    <span className={classes.date}>{moment(notification.createdAt).format('DD/MM/YY')}</span>
                  </ListItem>
                </Collapse>
              )}
            </TransitionGroup>
            {this.notifications.length === 0 &&
              <Typography className={classes.emptyMessage} align='center'>Your inbox is empty</Typography>
            }
            {(this.notifications.length > 0 && this.regularNotifications.length === 0) &&
              <Typography className={classes.emptyMessage} align='center'>Please read important notification</Typography>
            }
          </List>
          <div className={classes.footer}>
            <Link to="/notifications/mine" className={classes.more} onClick={this.props.onClose}>All notifications</Link>
          </div>
        </div>
      </div>
    )
  }
}

const styles = ({palette}) => ({
  wrapper: {
    position: 'fixed',
    top: 50,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1200,
    background: 'transparent',
    overflow: 'hidden',
    '&.hidden': {
      pointerEvents: 'none',
      '& $popup': {
        transform: 'translate(0, -100%)',
        boxShadow: 'none'
      },
    }
  },
  popup: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: palette.secondary.main,
    overflow: 'hidden',
    '@media(min-width: 600px)': {
      width: 342,
      height: 410,
      borderLeft: '1px solid rgba(255,255,255,0.5)',
      borderBottom: '1px solid rgba(255,255,255,0.5)',
    },
    transition: 'transform 225ms, box-shadow 225ms',
    boxShadow: '13px 10px 50px 18px rgba(0,0,0,0.1)',
  },
  notificationList: {
    flex: '1 1 auto',
    overflowY: 'auto'
  },
  notification: {
    '&:last-child': {
      borderBottom: '1px solid rgba(255,255,255,0.3)'
    },
    position: 'relative'
  },
  notificationText: {
    padding: 0,
  },
  title: {
    'span&': {
      color: '#FFF',
      fontWeight: 'bold',
      fontSize: '1.125rem',
      margin: '5px 60px 5px 0',
      lineHeight: 1.1,
    },
  },
  message: {
    color: '#FFF',
    fontSize: '1rem',
    margin: 0,
    lineHeight: 1.2,
    paddingBottom: 3,
  },
  more: {
    color: '#fff !important',
    textDecoration: 'underline !important'
  },
  date: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: 'white',
    position: 'absolute',
    top: 15,
    right: 10,
  },
  read: {
    '& $notificationText, $date': {
      opacity: 0.7
    },
    '& span$title, $message, $date': {
      color: '#eee',
    },
  },
  footer: {
    flex: '0 0 40px',
    marginTop: -15,
    paddingTop: 15,
    padding: '5px',
    display: 'flex',
    justifyContent: 'center',
    background: `linear-gradient(to bottom, ${fade(palette.secondary.main, 0)}, ${palette.secondary.main} 15px)`,
    zIndex: 100,
  },
  emptyMessage: {
    color: 'white',
    padding: 20,
  }
})

export default connect(({notifications}) => notifications)(withRouter(withStyles(styles)(NotificationsPopup)))
