import React, {Component} from 'react'
import withStyles from 'styles'
import {connect} from 'react-redux'
import {compose} from 'utils'
import PageContainer from 'components/PageContainer'
import Typography from '@material-ui/core/Typography'
import {SchemeView, Dependent, connectQueryString} from 'containers/shared'
import RightSideContent from 'components/RightSideContent'
import WaterLevel from 'components/scheme/WaterLevel'
import {SchemeActions} from 'actionsets'
import LakeLevelComponents from 'components/charts/LakeLevelComponents'
import moment from 'moment'
import DailyStoredWaterComponents from 'components/charts/DailyStoredWaterComponents'
import Select from 'components/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Info from 'containers/schemes/Info'
import * as API from 'api'

class StoredWaterView extends Component {
  state = {
    availableExpanded: true,
    infoDateContexts: []
  }

  constructor(props) {
    super(props)
    SchemeActions.bindActions(this)
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.scheme.id !== this.props.scheme.id) {
      this.loadData()
    }
  }

  dependsOn = () => {
    if (this.props.scheme.id)
      return this.loadData()
  }

  dependenciesMet = () => {
    return this.props.dailySummaries && this.props.dailySummaries.length > 0
  }

  loadData = () => {
    if (this.props.scheme.id) {
      return Promise.all([
        this.actions.dailySummaries(this.props.scheme.id, {dates: this.dateRange})
      ])
    }
  }

  get month() {
    return this.props.queryParams.month || moment().month()
  }

  get year() {
    return this.props.queryParams.year || moment().year()
  }

  get infoDate() {
    return this.props.queryParams.infoDate ? moment(this.props.queryParams.infoDate) : moment().add(-1, 'days')
  }

  get dateRange() {
    const {month, year} = this
    const date = moment({year, month})
    return {start: date.startOf('month').format(), end: date.endOf('month').format()}
  }

  get months() {
    return this.year < moment().year() ? moment.months() : moment.months().slice(0, moment().month() + 1)
  }

  get years() {
    if (!this._years) {
      this._years = []
      for (let i = moment().year(); i >= 2013; i--) {
        this._years.push(i)
      }
    }
    return this._years
  }

  get selectedDateContext() {
    return this.state.infoDateContexts.find(context => this.infoDate.isSame(context.entryDate, 'd')) || {}
  }

  handleRequestInfoDates = async (entryDates, {clear} = {}) => {
    const result = await API.Schemes.dailyChange({
      id: this.props.scheme.id,
      options: {date: entryDates}
    })
    const infoDateContexts = clear ? [] : [...this.state.infoDateContexts]
    entryDates.forEach(entryDate => {
      infoDateContexts.push(result['data'].find(d => moment(entryDate).isSame(d.entryDate, 'd'))
        || {entryDate: moment(entryDate).format('YYYY-MM-DD')})
    })
    this.setState({infoDateContexts})
  }

  handleInfoDateChange = newDate => {
    this.props.onQueryParamsChange({...this.props.queryParams, infoDate: newDate.format('YYYY-MM-DD')})
  }

  handleDateChange = part => ({target: {value}}) => {
    const newState = {...this.props.queryParams, [part]: value}
    if (part === 'year' && `${value}` === `${moment().year()}` && this.month > moment().month())
      newState.month = moment().month()

    this.props.onQueryParamsChange(newState, () => {
      this.actions.dailySummaries(this.props.scheme.id, {dates: this.dateRange})
    })
  }

  renderChartDate = () => {
    const {month, year} = this
    return (
      <span className={this.props.classes.chartDate}>
        ({moment({month, year}).format('MMM YYYY')})
      </span>
    )
  }


  render = () => {
    const {classes, schemeSelect} = this.props
    const {month, year, infoDate} = this
    return (
      <PageContainer>
        <Typography variant='h5'>{this.props.scheme.name} Stored Water</Typography>
        {schemeSelect}
        {this.props.scheme.id &&
          <div className={classes.content}>
            <RightSideContent>
              <div className={classes.section}>
                <Typography variant='subtitle1'>
                  Lake Storage {this.renderChartDate()}
                </Typography>
                <LakeLevelComponents dailySummaries={this.props.dailySummaries}/>
              </div>
              <div className={classes.section}>
                <WaterLevel store='accessible' scheme={this.selectedDateContext}/>
                <WaterLevel store='warehouse' scheme={this.selectedDateContext}/>
              </div>
            </RightSideContent>

            <div className={classes.section}>
              <Typography variant='subtitle1' className={classes.subTitle}>
                Daily Stored Water Components {this.renderChartDate()}
              </Typography>
              <Select label='Year' value={year} onChange={this.handleDateChange('year')}>
                {this.years.map(year =>
                  <MenuItem key={year} value={year}>{year}</MenuItem>
                )}
              </Select>
              <Select label='Month' value={month} onChange={this.handleDateChange('month')}>
                {this.months.map((month, i) =>
                  <MenuItem key={i} value={i}>{month}</MenuItem>
                )}
              </Select>
              <DailyStoredWaterComponents dailySummaries={this.props.dailySummaries}/>
            </div>

            <div className={classes.section}>
              <Info scheme={this.props.scheme} date={infoDate} onDateChange={this.handleInfoDateChange}
                    onRequestDates={this.handleRequestInfoDates} dateContexts={this.state.infoDateContexts}/>
            </div>
          </div>
        }
      </PageContainer>
    )
  }
}

const styles = {
  content: {
    paddingTop: 20,
    '& > $section': {
      marginBottom: 35,
    }
  },
  schemeSelect: {
    maxWidth: 400
  },
  section: {
    marginBottom: 50,
  },
  subTitle: {
    marginBottom: 20,
  },
  chartDate: {
    whiteSpace: 'nowrap'
  }
}

export default compose(
  Dependent,
  SchemeView({path: 'stored_water', include: 'schemeSeasons'}),
  withStyles(styles),
  connectQueryString({customQueryParams: ['year', 'month', 'infoDate']}),
  connect(({schemes}) => schemes)
)(StoredWaterView)
