import React, {PureComponent} from 'react'
import withStyles from 'styles'
import {Bar, defaults} from 'react-chartjs-2'
import {humanize} from 'utils'
import moment from 'moment'

const CHART_MAX_VOLUME = 8.64 // (mil m3)

class DailyStoredWaterComponents extends PureComponent {
  get data(){
    let {dailySummaries = []} = this.props
    return {
      labels: dailySummaries.map(({date}) => date),
      datasets: [{
        type: 'line',
        lineTension: 0,
        label: 'Accessible',
        yAxisID: 'left-y-axis',
        backgroundColor: '#111',
        borderColor: '#111',
        fill: false,
        borderWidth: 0,
        data: dailySummaries.map(({storedWaterDelta}) => milm3(storedWaterDelta))
      }, {
        type: 'line',
        lineTension: 0,
        label: 'Stored Water Released',
        yAxisID: 'left-y-axis',
        backgroundColor: '#FA0057',
        borderColor: '#FA0057',
        fill: false,
        borderWidth: 0,
        data: dailySummaries.map(({totalRelease}) => milm3(totalRelease))
      }, {
        type: 'line',
        lineTension: 0,
        label: 'Inflows',
        yAxisID: 'left-y-axis',
        backgroundColor: '#A3CE00',
        borderColor: '#A3CE00',
        fill: false,
        borderWidth: 0,
        data: dailySummaries.map(({inflow}) => milm3(inflow))
      }, ...this.irrigators.map((irrigator, i) => ({
        type: 'bar',
        yAxisID: 'left-y-axis',
        label: `URW - ${humanize(irrigator.name)}`,
        stack: 'urw',
        borderWidth: 0,
        categoryPercentage: 0.99,
        barPercentage: 1.0,
        backgroundColor: irrigatorColors[i],
        data: dailySummaries.map(summary => milm3(summary.irrigators.find(({irrigatorId}) => `${irrigatorId}` === `${irrigator.id}`).unusedRegisterWater))
      })), {
        type: 'bar',
        yAxisID: 'left-y-axis',
        label: 'Surplus Flow Stored Water',
        stack: 'urw',
        borderWidth: 0,
        categoryPercentage: 0.99,
        barPercentage: 1.0,
        backgroundColor: '#8f8f8f',
        data: dailySummaries.map(({surplusFlowStoredWater}) => milm3(surplusFlowStoredWater, 8640000))
      }]
    }
  }

  get irrigators() {
    let {dailySummaries = []} = this.props
    const irrigators = {}
    dailySummaries.forEach(summary => {
      summary.irrigators.forEach(({irrigatorId: id, name}) => {
        irrigators[id] = {id, name}
      })
    })
    return Object.values(irrigators)
  }

  get max() {
    return CHART_MAX_VOLUME
  }

  get min() {
    const result = Math.floor(Math.min(...(this.props.dailySummaries || []).map(({storedWaterDelta}) =>
      storedWaterDelta
    ), this.max * -5e4) / 1e6)
    return (!result || result === -Infinity) ? 0 : result
  }

  get maxFlow() {
    return this.max / 0.0864
  }

  get minFlow() {
    return this.min / 0.0864
  }

  get endOfMonth() {
    let {dailySummaries = []} = this.props
    const lastDate = moment((dailySummaries[dailySummaries.length - 1] || {}).date)
    return lastDate.endOf('month').format('YYYY-MM-DD')
  }

  get options() {
    return {
      layout: {
        padding: {
          top: 0,
        }
      },
      legend: {
        labels: {
          boxWidth: 15,
          padding: 30,
        },
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          label: (tooltipItem, data) => {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
              label += ': '
            }

            label += tooltipItem.yLabel
            if (tooltipItem.yLabel !== 0)
              label += ' mil m³'

            return label;
          }
        }
      },
      scales: {
        xAxes: [{
          type: 'time',
          time: {
            unit: 'day'
          },
          gridLines: {
            color: '#BFBFBF',
            tickMarkLength: 7,
            borderDash: [4]
          },
          ticks: {
            max: this.endOfMonth,
            minRotation: 90,
            padding: 7,
            source: 'auto',
            maxTicksLimit: 15
          }
        }],
        yAxes: [{
          id: 'left-y-axis',
          barPercentage: 1.30,
          gridLines: {
            zeroLineColor: '#000',
            color: '#BFBFBF',
            tickMarkLength: 7,
            borderDash: [4]
          },
          scaleLabel: {
            display: 'true',
            labelString: 'million m³',
            fontFamily: 'Roboto'
          },
          ticks: {
            padding: 7,
            max: this.max,
            min: this.min,
            callback: function(value, index, values) {
              if (1 * value.toPrecision(2) === value)
                return value
              else
                return ''
            }
          },
        },{
          id: 'right-y-axis',
          position: 'right',
          gridLines: {
            display: false
          },
          scaleLabel: {
            display: 'true',
            labelString: 'm³/s',
            fontFamily: 'Roboto'
          },
          ticks: {
            max: this.maxFlow,
            min: this.minFlow,
            callback: function(value, index, values) {
              if (1 * value.toPrecision(2) === value)
                return value
              else
                return ''
            }
          },
        }]
      },
    }
  }

  get plugins() {
    return [{
      beforeInit: function(chart, options) {
        chart.legend.afterFit = function() {
          this.height = this.height + 23;
        };
      }
    }]
  }

  render = () => {
    defaults.global.defaultFontColor = '#000'
    defaults.global.defaultFontFamily = 'Roboto'
    defaults.global.defaultFontSize = 16
    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.wrapper}>
          <Bar data={this.data} options={this.options} height={280} plugins={this.plugins} />
        </div>
      </div>
    )
  }
}

const irrigatorColors = [
  '#7F1399',
  '#0066cc',
  '#80c1ff',
  '#337B46',
  '#FBEC00',
  '#FA9C00',
  '#ff669c',
  '#AC504A',
]

const milm3 = m3 => (m3 === null || m3 === undefined) ? m3 : (m3 / 1e6).toFixed(3)

const styles = {
  root: {
    width: '100%',
    overflow: 'auto'
  },
  wrapper: {
   minWidth: 550,
 }
}

export default withStyles(styles)(DailyStoredWaterComponents)
