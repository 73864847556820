import React, {Component} from 'react'
import withStyles from 'styles'
import {compose} from 'utils'
import Card from '@material-ui/core/Card'
import ActionHeader from 'components/ActionHeader'
import Typography from '@material-ui/core/Typography'
import DatePicker from 'components/DatePicker'
import moment from 'moment'
import Table, {TableHead, TableBody, TableRow, TableCell} from 'components/CollapsibleTable'
import CalendarForm from 'components/CalendarForm'
import Volume from 'components/units/Volume'
import DataList, {DataValue} from 'components/DataList'
import {humanize} from 'utils'
import ExportButton from 'components/index_view/ExportButton'
import LinkButton from 'components/LinkButton'
import LimitingFactors from 'components/charts/LimitingFactors'
import {fade} from '@material-ui/core/styles/colorManipulator'

class Info extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dateContexts: [],
      exportDates: {
        start: moment(this.props.date).startOf('M'),
        end: moment(this.props.date).endOf('M')
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.date !== prevProps.date) {
      this.setState({
        exportDates: {
          start: moment(this.selectedDate).startOf('M'),
          end: moment(this.selectedDate).endOf('M')
        }
      })
    }
  }

  handleDateSelected = selectedDate => {
    this.props.onDateChange(moment(selectedDate))
  }

  handleExportDateChange = name => ({target: {value}}) => {
    this.setState({exportDates: {...this.state.exportDates, [name]: moment(value)}})
  }

  get selectedDate() {
    return this.props.date
  }

  get exportDateParams() {
    const {start, end} = this.state.exportDates
    return {start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD')}
  }

  handleRequestDates = async(entryDates, {clear}={}) => {
    this.props.onRequestDates(entryDates, {clear})
  }

  getUntaken = irrigatorEntry => {
    if (irrigatorEntry.normalConsentedWater > irrigatorEntry.waterTake)
      return irrigatorEntry.normalConsentedWater - irrigatorEntry.waterTake
  }

  sum = (array, attribute) => {
    return (array || []).reduce((sum, item) => sum + (item[attribute] || 0), 0)
  }

  renderSummary = context =>
    <dl className={this.props.classes('summary', {missingData: context.irrigatorDataOutstanding})}>
      <dt>Accessible</dt>
      <dd><Volume>{context.totalStoredWater}</Volume></dd>
      <dt>Change</dt>
      <dd className={this.props.classes({negative: context.storedWaterChange < 0})}>
        <Volume>{context.storedWaterChange}</Volume>
      </dd>
    </dl>

  renderIrrigator = () => {
    const {classes} = this.props
    return (
        <div className={classes.expandPanel}>
          <div className={classes.expandPanelInner}>
            <ExportButton
              resource={`irrigator_daily_entries`}
              params={{view: 'release_view'}}
              filter={{entryDate: this.exportDateParams}}
            />
            <div className={classes.expandForm}>
              <DatePicker
                label='Start Date'
                value={this.state.exportDates.start}
                onChange={this.handleExportDateChange('start')}
                style={{width: 132}}
              />
              <DatePicker
                label='End Date'
                value={this.state.exportDates.end}
                onChange={this.handleExportDateChange('end')}
                style={{width: 132}}
              />
            </div>
          </div>
        </div>
    )
  }

  render = () => {
    const {classes, scheme} = this.props
    return (
      <div className={classes.root}>
        <ActionHeader variant='subtitle1' title="Scheme Information">
          <div className={classes.expandPanel}>
            <div className={classes.expandPanelInner}>
              <ExportButton resource={`schemes/${scheme.id}/daily_change`} params={{date: this.exportDateParams}}/>
              <div className={classes.expandForm}>
                <DatePicker label='Start Date' value={this.state.exportDates.start}
                            onChange={this.handleExportDateChange('start')} style={{width: 132}}/>
                <DatePicker label='End Date' value={this.state.exportDates.end}
                            onChange={this.handleExportDateChange('end')} style={{width: 132}}/>
              </div>
            </div>
          </div>
        </ActionHeader>
        <Typography className={classes.dataStatus} paragraph>
          All irrigator data entered up to and including:
          &nbsp;
          <LinkButton onClick={() => this.handleDateSelected(scheme.dataEnteredUpto || moment().add(-1, 'day'))}>
            {
            scheme.dataEnteredUpto ?
              moment(scheme.dataEnteredUpto).format('DD/MM/YYYY') :
              moment().add(-1, 'day').format('DD/MM/YYYY')
            }
          </LinkButton>

        </Typography>
        <DatePicker label='Date' value={this.selectedDate} maxDate={moment().add(-1, 'days')}
                    onChange={({target: {value}}) => this.handleDateSelected(value)} />

        <CalendarForm selectedDate={this.selectedDate}
                      dateField="entryDate"
                      onDateSelected={this.handleDateSelected}
                      onRequestDates={this.handleRequestDates}
                      dateContexts={this.props.dateContexts}
                      dateSummary={this.renderSummary}
                      maxDate={moment().add(-1, 'days')}
                      classes={{body: classes.dayBody}}
                      key={this.props.scheme.id}
                      seasons={this.props.scheme.schemeSeasons}
        >
          {context =>
            <div className={classes.dailyEntry}>
              <ActionHeader variant='body1' title='Registered Consent Holders' className={classes.dataHeading}>
                {this.renderIrrigator()}
              </ActionHeader>
              <Card className={classes.subCard}>
                <Table className={classes.table} breakpoint={550}>
                  <TableHead>
                    <TableRow style={{height: 1}}>
                      <TableCell rowSpan={2}>Irrigator</TableCell>
                      <TableCell rowSpan={2}>Normal<br/>Consented</TableCell>
                      <TableCell rowSpan={2}>Released</TableCell>
                      <TableCell rowSpan={2}>Take</TableCell>
                      <TableCell colSpan={2}>Untaken Register Water (URW)</TableCell>
                    </TableRow>
                    <TableRow style={{height: 1}}>
                      <TableCell>Flow</TableCell>
                      <TableCell>Volume</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(context.irrigatorEntries || []).map(irrigatorEntry =>
                      <TableRow key={irrigatorEntry.id}>
                        <TableCell mobileHeading>{irrigatorEntry.irrigator.code}</TableCell>
                        <TableCell mobileLabel='Normal Consented'><Volume display='m3/s'>{irrigatorEntry.normalConsentedWater}</Volume></TableCell>
                        <TableCell mobileLabel='Released'><Volume display='m3/s'>{irrigatorEntry.actualRelease}</Volume></TableCell>
                        <TableCell mobileLabel='Take'><Volume display='m3/s'>{irrigatorEntry.waterTake}</Volume></TableCell>
                        <TableCell><Volume display='m3/s'>{this.getUntaken(irrigatorEntry)}</Volume></TableCell>
                        <TableCell mobileLabel='Untaken'><Volume>{this.getUntaken(irrigatorEntry)}</Volume></TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell variant="footer" mobileHeading>Total</TableCell>
                      <TableCell variant="footer" mobileLabel='Normal Consented'>
                        <Volume display='m3/s'>{this.sum(context.irrigatorEntries, 'normalConsentedWater')}</Volume>
                      </TableCell>
                      <TableCell variant="footer" mobileLabel='Released'>
                        <Volume display='m3/s'>{this.sum(context.irrigatorEntries, 'actualRelease')}</Volume>
                      </TableCell>
                      <TableCell variant="footer" mobileLabel='Take'>
                        <Volume display='m3/s'>{this.sum(context.irrigatorEntries, 'waterTake')}</Volume>
                      </TableCell>
                      <TableCell variant="footer">
                        <Volume display='m3/s'>{context.urw}</Volume>
                      </TableCell>
                      <TableCell variant="footer" mobileLabel='Untaken'>
                        <Volume>{context.urw}</Volume>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
              <Typography variant='body1' className={classes.dataHeading}>Rakaia River</Typography>
              <DataList>
                <DataValue title='WCO Min Gorge Flow'>
                  <Volume display='m3/s'>{context.wco}</Volume>
                </DataValue>
                <DataValue title='Rakaia Irrigation Restriction Flow'>
                  <Volume display='m3/s'>{context.riverFlow}</Volume>
                </DataValue>
                <DataValue title='WCO Max Abstraction (excl. SW Releases)' className={classes({limit: context.limitFactor in {wco_high: 1, wco_low: 1}})}>
                  <Volume display='m3/s'>{context.wcoMax}</Volume>
                </DataValue>
              </DataList>

              <Typography variant='body1' className={classes.dataHeading}>Accessible Stored Water (ASW)</Typography>
              <DataList>
                <DataValue title='Lake Inflow' className={classes({limit: context.limitFactor === 'inflow'})}>
                  <Volume display='m3/s'>{context.inflow}</Volume>
                  <Volume>{context.inflow}</Volume>
                </DataValue>
                <DataValue title='Current Lake Storage' className={classes({limit: context.limitFactor === 'lake'})}>
                  <Volume>{context.lakeLevel}</Volume>
                </DataValue>
                <DataValue title='Untaken Register Water (URW)'>
                  <Volume display='m3/s'>{context.urw}</Volume>
                  <Volume>{context.urw}</Volume>
                </DataValue>
                <DataValue title='Surplus Flow Stored Water (SFSW)'>
                  <Volume display='m3/s'>{context.sfsw}</Volume>
                  <Volume>{context.sfsw}</Volume>
                </DataValue>
                <DataValue title='SW Releases'>
                  <Volume display='m3/s'>{context.releases}</Volume>
                  <Volume>{context.releases}</Volume>
                </DataValue>
                <DataValue title='Net ASW Transfers'>
                  <Volume>{context.warehouseTransactions}</Volume>
                </DataValue>
              </DataList>
              <DataList className={classes.highlight}>
                <DataValue title='ASW Total Daily Change'>
                  <Volume display='m3/s'>{context.storedWaterChange}</Volume>
                  <Volume>{context.storedWaterChange}</Volume>
                </DataValue>
                <DataValue title='Limited By'>{humanize(context.limitFactor || '–')}</DataValue>
              </DataList>

              <Typography variant='body1' className={classes.dataHeading}>Limiting Factors on Stored Water Increase</Typography>
              <LimitingFactors dailyChange={context}/>

              <Typography variant='body1' className={classes.dataHeading}>Warehouse Stored Water (WSW)</Typography>
              <DataList>
                <DataValue title='Net WSW Transfers'>
                  <Volume>{context.warehouseTransactions}</Volume>
                </DataValue>
              </DataList>

              <Typography variant='body1' className={classes.dataHeading}>
                Stored Water Balances (End of Day)
              </Typography>
              <DataList>
                <DataValue title='Accessible Stored Water'>
                  <Volume>{context.totalStoredWater}</Volume>
                </DataValue>
                <DataValue title='Warehouse Stored Water'>
                  <Volume>{context.totalWarehouseStoredWater}</Volume>
                </DataValue>
              </DataList>
            </div>
          }
        </CalendarForm>
      </div>
    )
  }
}

const styles = ({palette}) => ({
  root: {

  },
  summary: {
    margin: 0,
    '& dt': {
      fontSize: '0.75rem',
      fontWeight: 'bold',
    },
    '& dd': {
      margin: '0 0 5px',
      '&:last-child': {
        margin: 0
      }
    }
  },
  missingData: {
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0, right: 0, bottom: 0, left: 0,
      backgroundColor: palette.error.main,
      opacity: 0.1,
      pointerEvents: 'none',
      zIndex: 1,
    }
  },
  dataHeading: {
    color: palette.secondary.dark,
    marginTop: 15,
    '&:first-child': {
      marginTop: 10,
    }
  },
  subCard: {
    borderColor: palette.secondary.dark,
    backgroundColor: '#F2F2F2',
    marginBottom: 35,
    marginRight: 0,
  },
  table: {
    '& > thead > tr > th, & > tbody > tr > td': {
      paddingLeft: 8,
      paddingRight: 8,
      fontSize: '0.9rem',
      '@media(min-width: 1440px)': {
        fontSize: '1rem'
      }
    }
  },
  limit: {
    color: palette.error.main
  },
  negative: {
    color: palette.error.main
  },
  dailyEntry: {
    '& dl > div': {
      minWidth: '100%',
      '@media(min-width: 600px)': {
        minWidth: '50%',
      },
      '@media(min-width: 1000px)': {
        minWidth: '33.333333%',
        maxWidth: '33.333333%'
      },
    }
  },
  highlight: {
    backgroundColor: fade(palette.secondary.main, 0.1),
    margin: '-4px -4px 0',
    padding: '4px 4px 0',
  },
  dayBody: {
    padding: 9,
  },
  expandPanel: {
    position: 'relative',
    width: 48,
    height: 48,
  },
  expandPanelInner: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 48,
    height: 48,
    transition: '300ms',
    padding: 10,
    margin: -10,
    boxSizing: 'content-box',
    zIndex: 100,
    overflow: 'hidden',
    '& button': {
      position: 'absolute',
      top: 10,
      right: 10,
      pointerEvents: 'none',
      marginRight: 0,
    },
    '&:hover': {
      width: 200,
      height: 140,
      background: '#fff',
      boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      '& button': {
        pointerEvents: 'auto'
      },
      '& $expandForm': {
        filter: 'opacity(100%)'
      }
    }
  },
  expandForm: {
    margin: '10px 0 0 10px',
    boxSizing: 'border-box',
    width: 'calc(100% - 58px)',
    overflow: 'hidden',
    filter: 'opacity(0%)',
    transition: 'filter 300ms',
  },
  dataStatus: {
    fontSize: '0.85rem',
    fontWeight: 500
  }
})

export default compose(
  withStyles(styles),
)(Info)
