import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {SchemeDeltaActions, SnackbarActions} from 'actionsets'
import {Pagination, ErrorBanner} from 'components'
import Dependent from 'containers/shared/Dependent'
import ActionHeader from 'components/ActionHeader'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import LoadedCard from 'components/LoadedCard'
import ExportButton from 'components/index_view/ExportButton'
import {Authorization, compose, errorStringsFromError} from 'utils'
import {connectQueryString} from 'containers/shared'
import Table, {TableHead, TableBody, TableExpander, TableRow, TableCell} from 'components/CollapsibleTable'
import withStyles from 'styles'
import ExpandButton from 'components/ExpandButton'
import Collapse from '@material-ui/core/Collapse'
import Volume from 'components/units/Volume'
import SchemeTransactionDialog from './SchemeTransactionDialog'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import MenuItem from '@material-ui/core/MenuItem'
import Select from 'components/Select'
import IndexToolbar from 'components/index_view/IndexToolbar'
import DataList, {DataValue} from 'components/DataList'
import SearchCombo from 'components/index_view/SearchCombo'
import moment from 'moment'
import DatePicker from 'components/DatePicker'

export class SchemeTransactions extends Component {

  constructor(props) {
    super(props)
    SchemeDeltaActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1,
    activeSchemeDelta: undefined,
    expanded: {}
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.scheme.id !== prevProps.scheme.id || this.props.query !== prevProps.query) {
      this.loadSchemeTransactions()
    }
  }

  dependsOn() {
    return this.loadSchemeTransactions()
  }

  loadSchemeTransactions = () => {
    if (this.props.scheme.id) {
      return this.actions.index({
        page: this.props.page,
        filter: {
          ...this.props.filter,
          scheme: {id: this.props.scheme.id}
        },
        order: '-transacted_at',
        ...this.fetchParams
      })
    }
  }

  dependenciesMet() {
    return this.props.requests.length === 0
  }

  handleDeleteSchemeTransaction = id => event => {
    this.actions.destroy({id})
      .then(this.loadSchemeTransactions)
      .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get fetchParams() {
    return {
      include: 'scheme,deltaTransaction.user'
    }
  }

  get expanded() {
    return this.props.queryParams.expanded === 'true'
  }

  get errors() {
    let errors = []
    if (this.props.errors.index) {
      errors = errors.concat(this.props.errors.index)
    }
    if (this.props.errors.destroy) {
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  get schemeDeltasByDate() {
    const {schemeDeltas} = this.props
    const grouped = {}
    schemeDeltas.forEach(delta => {
      const key = moment(delta.deltaTransaction.transactedAt).format('YYYY-MM-DD')
      grouped[key] = grouped[key] || []
      grouped[key].push(delta)
    })
    return Object.keys(grouped).map(key => {
      const items = grouped[key]
      return {
        date: moment(key),
        warehouseStoredWater: items.reduce((total, {warehouseStoredWater}) => total + (warehouseStoredWater || 0), 0),
        storedWater: items.reduce((total, {storedWater}) => total + (storedWater || 0), 0),
        notes: items.map(({notes}) => notes).filter(note => note).join(', '),
        items
      }
    })
  }

  getExpanded = date => {
    return !!this.state.expanded[date]
  }

  handleToggleExpanded = date => () => {
    this.setState({expanded: {...this.state.expanded, [date]: !this.state.expanded[date]}})
  }

  handleAddSchemeTransaction = () =>
    this.setState({activeSchemeDelta: {}})

  handleToggleCollapse = () =>
    this.props.onQueryParamsChange({...this.props.filter, expanded: !this.expanded || undefined})

  handleSaveSchemeDelta = async schemeDelta => {
    const saveOp = (schemeDelta.id ?
      this.actions.update(schemeDelta, this.fetchParams) :
      this.actions.create(schemeDelta, this.fetchParams))
    saveOp.then(() => {
      this.setState({activeSchemeDelta: undefined})
      this.loadSchemeTransactions()
    })
    return saveOp
  }

  handleEditDelta = activeSchemeDelta => () =>
    this.setState({activeSchemeDelta})

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  renderSchemeDeltaRow = ({date, warehouseStoredWater, storedWater, notes, items}) =>
    <Fragment key={date}>
      <TableRow>
        <TableCell mobileHeading>
          {date.format('DD/MM/YYYY')}
        </TableCell>
        <TableCell mobileLabel={"Accessible Debit"}>
          {parseInt(storedWater) < 0 &&
            <Volume>
              {parseInt(storedWater)}
            </Volume>
          }
        </TableCell>
        <TableCell mobileLabel={"Accessible Credit"}>
          {parseInt(storedWater) > 0 &&
            <Volume>
              {parseInt(storedWater)}
            </Volume>
          }
        </TableCell>
        <TableCell mobileLabel={"Warehouse Debit"}>
          {parseInt(warehouseStoredWater) < 0 &&
            <Volume>
              {parseInt(warehouseStoredWater)}
            </Volume>
          }
        </TableCell>
        <TableCell mobileLabel={"Warehouse Credit"}>
          {parseInt(warehouseStoredWater) > 0 &&
            <Volume>
              {parseInt(warehouseStoredWater)}
            </Volume>
          }
        </TableCell>
        <TableCell mobileLabel={"Notes"}>
          {notes || '(none)'}
        </TableCell>
        <TableCell style={{padding: 0, width: 1}} mobileHeading>
          <ExpandButton table onClick={this.handleToggleExpanded(date)} expanded={this.getExpanded(date)}/>
        </TableCell>
      </TableRow>
      <TableExpander expanded={this.getExpanded(date)}>
        <div>
          {items.map(({
                        id,
                        notes,
                        storedWater,
                        warehouseStoredWater,
                        deltaTransaction: {transactedAt, user: {id: wasUserInput, name} = {}}
                      }) =>
            <div key={`div_exp_${id}`} style={{display: 'flex'}}>
              <DataList className={this.props.classes.dataDetails}>
                <DataValue title='Effective Time'>{moment(transactedAt).format('h:mm:ss a')}</DataValue>
                <DataValue title='Approved By'>{name || 'System'}</DataValue>
                {items.length > 1 &&
                  <Fragment>
                    <DataValue title='Accessible'><Volume>{storedWater}</Volume></DataValue>
                    <DataValue title='Warehouse'><Volume>{warehouseStoredWater}</Volume></DataValue>
                    <DataValue title='Notes'>{notes}</DataValue>
                  </Fragment>
                }
              </DataList>
              <div style={{width: 96}}>
                {
                  Authorization.admin && wasUserInput &&
                  <Fragment>
                    <IconButton onClick={this.handleDeleteSchemeTransaction(id)}
                                color='primary'><DeleteIcon/></IconButton>
                    <IconButton onClick={this.handleEditDelta(this.props.schemeDeltas.find(delta => delta.id === id))}
                                color='primary'><EditIcon/></IconButton>
                  </Fragment>
                }
              </div>
            </div>
          )}
        </div>
      </TableExpander>
    </Fragment>

  render = () =>
    <div className={this.props.className}>
      <ActionHeader title="Scheme" variant="h6" startAdornment={
        <ExpandButton onClick={this.handleToggleCollapse} expanded={this.expanded}/>
      }>
        <ExportButton resource='scheme_deltas' params={{scheme_id: this.props.scheme.id}}/>
        {Authorization.admin &&
          <Button color="primary" variant="contained" onClick={this.handleAddSchemeTransaction}>Add</Button>
        }
      </ActionHeader>
      {this.renderErrorMessages()}
      <Collapse in={this.expanded}>
        <div className={this.props.classes.contentWrapper}>
          <IndexToolbar>
            <SearchCombo>
              <Select member='transactionType' fullWidth>
                <MenuItem>All</MenuItem>
                <MenuItem value='warehouse'>Warehouse Transactions</MenuItem>
                <MenuItem value='stored_water'>Stored Water Transactions</MenuItem>
              </Select>
              <DatePicker member='deltaTransaction.transactedAt.start' label='Effective From'/>
              <DatePicker member='deltaTransaction.transactedAt.end' label='Effective To'/>
            </SearchCombo>
            <Pagination totalPages={this.props.totalPages}/>
          </IndexToolbar>
          <LoadedCard>
            {this.props.schemeDeltas &&
              <Table className={this.props.classes.table}>
                <TableHead>
                  <TableRow style={{height: 1}}>
                    <TableCell rowSpan={2}>Effective</TableCell>
                    <TableCell colSpan={2}>Accessible</TableCell>
                    <TableCell colSpan={2}>Warehouse</TableCell>
                    <TableCell rowSpan={2}>Notes</TableCell>
                    <TableCell rowSpan={2}/>
                  </TableRow>
                  <TableRow style={{height: 1}}>
                    <TableCell>Debit</TableCell>
                    <TableCell>Credit</TableCell>
                    <TableCell>Debit</TableCell>
                    <TableCell>Credit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.schemeDeltasByDate.map(this.renderSchemeDeltaRow)}
                </TableBody>
              </Table>
            }
          </LoadedCard>
          {
            this.state.activeSchemeDelta &&
            <SchemeTransactionDialog
              schemeDelta={this.state.activeSchemeDelta}
              onCancel={() => this.setState({activeSchemeDelta: null})}
              onSave={this.handleSaveSchemeDelta}
            />
          }
        </div>
      </Collapse>
    </div>
}

const styles = {
  table: {
    '& > thead > tr > th, & > tbody > tr > td': {
      paddingLeft: 8,
      paddingRight: 8,
      fontSize: '0.9rem',
      '@media(min-width: 1440px)': {
        fontSize: '1rem'
      }
    }
  },
  contentWrapper: {
    paddingBottom: 20,
  },
  dataDetails: {
    flex: 1,
  }
}

export default compose(
  Dependent({loader: true}),
  connectQueryString({nameSpace: 'schemeDeltas', customQueryParams: ['expanded']}),
  withStyles(styles),
  connect(({schemeDeltas}) => schemeDeltas)
)(SchemeTransactions)
