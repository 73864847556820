import React, {Component} from 'react'
import {connect} from 'react-redux'
import {NotificationActions} from 'actionsets'
import Dependent from 'containers/shared/Dependent'
import {compose} from 'utils'
import {connectQueryString} from 'containers/shared'
import NotificationsList from './NotificationsList'

export class Mine extends Component {

  constructor(props) {
    super(props)
    NotificationActions.bindActions(this)
  }

  dependsOn() {
    return this.loadNotifications()
  }

  dependenciesMet() {
    return this.props.requests.length === 0
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.query !== this.props.query) {
      this.loadNotifications()
    }
  }

  loadNotifications = () => {
    return this.actions.mine({
      page: this.props.page,
      include: 'user'
    })
  }

  render = () =>
    <NotificationsList
      title='My Notifications'
      totalPagesKey='totalPagesMine'
      notificationsKey='mine'
      errorsKeys={['mine']}
    >
    </NotificationsList>
}

export default compose(
  Dependent({loader: true}),
  connectQueryString('notifications'),
  connect(({notifications}) => notifications)
)(Mine)
