import React, {Component} from 'react'
import withStyles from 'styles'
import {connect} from 'react-redux'
import {connectQueryString} from 'containers/shared'
import {SchemeView} from 'containers/shared'
import {IrrigatorActions, IrrigatorDailyEntryActions} from 'actionsets'
import {Dependent} from 'containers/shared'
import PageContainer from 'components/PageContainer'
import RequestConfirmation from 'components/irrigator/RequestConfirmation'
import Typography from '@material-ui/core/Typography'
import PromiseButton from 'components/PromiseButton'
import VolumeField from 'components/units/VolumeField'
import {FormContext} from 'components'
import DatePicker from 'components/DatePicker'
import moment from 'moment'
import LoadedContent from 'components/LoadedContent'
import {Authorization, unique, compose, basicComparison} from 'utils'
import ApprovalHistory from 'components/scheme/ApprovalHistory'
import ActionHeader from 'components/ActionHeader'
import HelpButton from 'components/HelpButton'

class RequestApproval extends Component {
  state = {
    context: {},
    confirmedRequests: {},
    requestedReleases: {},
    expandedIrrigators: [1],
  }

  constructor(props) {
    super(props)
    IrrigatorActions.bindActions(this, 'irrigators')
    IrrigatorDailyEntryActions.bindActions(this)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!moment(prevProps.queryParams.date || moment().add(1, 'd')).isSame(this.props.queryParams.date || moment().add(1, 'd'), 'd')) {
      this.loadData()
    }

    if (this.props.irrigatorDailyEntries !== prevProps.irrigatorDailyEntries) {
      const {context} = this.state
      const {irrigatorDailyEntries} = this.props
      const currentEntries = irrigatorDailyEntries.filter(entry => moment(entry.entryDate).isSame(this.date, 'd'))

      const confirmedRequests = {}
      const requestedReleases = {}
      currentEntries.forEach(entry => {
        confirmedRequests[entry.irrigator.id] = entry.confirmedRelease
        requestedReleases[entry.irrigator.id] = entry.requestedRelease
      })

      this.setState({
        context: {
          ...context,
          requestedRelease: this.totalRequestedRelease,
          confirmedRelease: this.totalConfirmedRelease
        },
        confirmedRequests,
        requestedReleases
      }, () => {
        const {context} = this.state
        if (currentEntries.every(entry => !entry.confirmedRelease && entry.confirmedRelease !== 0)) {
          this.setState({context: {...context, confirmedRelease: context.requestedRelease}}, () => {
            this.distributeConfirmedRequest()
          })
        }
      })
    }

    if (prevProps.scheme.id !== this.props.scheme.id) {
      this.loadData()
    }
  }


  get totalRequestedRelease() {
    const {irrigatorDailyEntries: entries} = this.props
    const {date} = this
    const currentEntries = entries.filter(entry => moment(entry.entryDate).isSame(date, 'd'))
    return currentEntries.reduce((sum, entry) => sum + entry.requestedRelease, 0)
  }

  get totalConfirmedRelease() {
    const {irrigatorDailyEntries: entries} = this.props
    const {date} = this
    const currentEntries = entries.filter(entry => moment(entry.entryDate).isSame(date, 'd'))
    return currentEntries.reduce((sum, entry) => sum + entry.confirmedRelease, 0)
  }

  distributeConfirmedRequest() {
    let remaining = Number(this.formContext.confirmedRelease)
    if (isNaN(remaining) || remaining < 0)
      return

    let irrigators = this.formContext.irrigators
    let priorities = unique(irrigators.map(irrigator => irrigator.priority))
    const confirmedRequests = {}
    priorities.sort().forEach(priority => {
      const priorityIrrigators = irrigators.filter(irrigator => irrigator.priority === priority)
      const priorityRequest = priorityIrrigators.reduce((sum, irrigator) => sum + (irrigator.dailyEntries[0].requestedRelease || 0), 0)
      const priorityAmount = Math.min(remaining, priorityRequest || 0)
      priorityIrrigators.forEach(irrigator => {
        const ratio = irrigator.dailyEntries[0].requestedRelease / priorityRequest
        confirmedRequests[irrigator.id] = Math.floor(Math.round(priorityAmount * ratio) / 1e3) * 1e3
      })
      remaining -= priorityAmount
    })
    this.setState({confirmedRequests})
  }

  loadData = async({
    append=false,
    clearExisting=!append,
    endDate=this.date,
    days=4,
    startDate=moment(endDate).subtract(days-1, 'd'),
    irrigator: {id: irrigatorId}={}
  } = {}) => {
    if (this.props.scheme.id) {
      await Promise.all([
        this.actions.irrigators.index({
          pageSize: 1000,
          include: 'priorities',
          filter: {
            scheme: {id: this.props.scheme.id},
            createdAt: {end: this.date.format()}
          }
        }),
        this.actions.index({
          pageSize: 1000,
          include: 'irrigator',
          order: '-entryDate',
          clearExisting: clearExisting,
          append,
          fields: {irrigators: ''},
          filter: {
            scheme: {id: this.props.scheme.id},
            entryDate: {start: startDate.format(), end: endDate.format()},
            ...(irrigatorId && {irrigatorId})
          }
        })
      ])
    }
  }

  dependsOn = () => {
    return this.loadData()
  }

  dependenciesMet = () => {
    return this.props.requests.length === 0
  }

  get formContext() {
    const context =  {irrigators: this.props.irrigators, ...this.state.context}
    const allDailyEntries = this.props.irrigatorDailyEntries || []
    const irrigators = context.irrigators.map(irrigator => {
      const dailyEntries = allDailyEntries.filter(entry => (entry.irrigator || {}).id === irrigator.id)
      dailyEntries[0] = {
        ...dailyEntries[0],
        confirmedRelease: this.state.confirmedRequests[irrigator.id],
        requestedRelease: this.state.requestedReleases[irrigator.id]
      }
      const priority = irrigator.priorities && irrigator.priorities
        .sort((a, b) => basicComparison(b.effectiveOn, a.effectiveOn))
        .find(p => moment(p.effectiveOn).isSameOrBefore(this.date))
        .priority
      return {...irrigator, dailyEntries, priority}
    })
    return {...context, irrigators}
  }

  get date() {
    return moment(this.props.queryParams.date || moment().add(1, 'd'))
  }

  handleFormContextChange = context => {
    const prevState = this.state
    this.setState({context}, () => {
      if (prevState.context.confirmedRelease !== context.confirmedRelease) {
        this.distributeConfirmedRequest()
      }
    })
  }

  handleMore = irrigator => () => {
    const lastEntry = irrigator.dailyEntries[irrigator.dailyEntries.length - 1]
    return this.loadData({append: true, endDate: moment(lastEntry.entryDate).subtract(1, 'd'), days: 3, irrigator})
  }

  handleMoreHistory = (fromDate) => {
    return this.loadData({append: true, endDate: moment(fromDate), days: 3})
  }

  handleExpandIrrigator = irrigator => () => {
    if (this.getIsIrrigatorExpanded(irrigator))
      this.setState({expandedIrrigators: this.state.expandedIrrigators.filter(id => id !== irrigator.id)})
    else
      this.setState({expandedIrrigators: [...this.state.expandedIrrigators, irrigator.id]})
  }

  handleExpandHistory = () => {
    this.setState({historyExpanded: !this.state.historyExpanded})
  }

  handleChangeConfirmedRequest = irrigator => ({requestedAmount, confirmedAmount}) => {
    let {confirmedRequests, requestedReleases, context} = this.state
    confirmedRequests = {...confirmedRequests, [irrigator.id]: confirmedAmount}
    requestedReleases = {...requestedReleases, [irrigator.id]: requestedAmount}
    context = {
      ...context,
      confirmedRelease: Object.values(confirmedRequests).reduce((sum, value) => sum + Number(value || 0), 0),
      requestedRelease: Object.values(requestedReleases).reduce((sum, value) => sum + Number(value || 0), 0),
    }
    this.setState({confirmedRequests, requestedReleases, context})
  }

  handleSave = async() => {
    const dailyEntries = this.formContext.irrigators.map(i => {
      const {irrigator, entryDate, confirmedRelease, requestedRelease} = i.dailyEntries[0]
      return {irrigator, entryDate, confirmedRelease, requestedRelease}
    }).filter(entry => entry.irrigator)
    await this.actions.bulkUpdate({dailyEntries})
    await this.loadData({clearExisting: false})
  }

  handleDateChange = ({target: {value: date}}) => {
    this.props.onQueryParamsChange({date: moment(date).format('YYYY-MM-DD')})
  }

  getIsIrrigatorExpanded = (irrigator) => {
    return !!this.state.expandedIrrigators.find(id => irrigator.id === id)
  }

  render = () => {
    const context = this.formContext
    const {date} = this
    const {historyExpanded} = this.state
    const {classes, irrigatorDailyEntries, irrigators} = this.props
    return (
      <PageContainer width='xl'>
        <ActionHeader title='Request Approval' variant='h5' classes={{root: classes.titleWrapper}}>
          <HelpButton>
            Request Confirmation required by 9pm.
            The system defaults to a Decline status of zero if a
            Request isn't approved. Approving a quantity less than the total
            Requested Release will result in a curtailment being applied to the
            Irrigators based on proportioned priority.
          </HelpButton>
        </ActionHeader>
        <Typography variant='body1' className={classes.subTitle}>
          {this.props.scheme.id && `${this.props.scheme.name} - `}
          {moment(date).format('ddd Do MMMM YYYY')}
        </Typography>
        {!this.props.scheme.id ?
          this.props.schemeSelect
          :
          <LoadedContent>
            <FormContext context={context} onChange={this.handleFormContextChange} onSubmit={this.handleSave}>
              {this.props.schemeSelect}
              {(Authorization.admin || Authorization.viewer) &&
                <DatePicker onChange={this.handleDateChange} value={date} label='Date'
                            className={classes.input} maxDate={moment().add(1, 'd')}/>
              }
              <div className={classes.totals}>
                <VolumeField member='requestedRelease' disabled/>
                <VolumeField member='confirmedRelease' label='Approved Request'
                             max={context.requestedRelease} disabled={Authorization.viewer}/>
              </div>
              <ul className={classes.irrigatorList}>
                {context.irrigators.sort(({priority: p1}, {priority: p2}) => p1 - p2).map(irrigator =>
                  <RequestConfirmation irrigator={irrigator} key={irrigator.id}
                                       onExpand={this.handleExpandIrrigator(irrigator)}
                                       expanded={this.getIsIrrigatorExpanded(irrigator)}
                                       onMore={this.handleMore(irrigator)}
                                       onChangeConfirmedRequest={this.handleChangeConfirmedRequest(irrigator)}/>
                )}
              </ul>
              <ApprovalHistory className={classes.history} expanded={historyExpanded}
                               onExpand={this.handleExpandHistory} onMore={this.handleMoreHistory}
                               irrigatorDailyEntries={irrigatorDailyEntries} irrigators={irrigators}/>
              {!Authorization.viewer &&
                <PromiseButton variant='contained' color='secondary' className={classes.saveButton} type='submit'>
                  Save
                </PromiseButton>
              }
            </FormContext>
          </LoadedContent>
        }
      </PageContainer>
    )
  }
}

const styles = ({palette}) => ({
  titleWrapper: {
    marginBottom: 10,
    maxWidth: 800
  },
  subTitle: {
    marginBottom: 22,
    '@media(min-width: 600px)': {
      marginBottom: 47,
    },
  },
  input: {
    maxWidth: 400,
  },
  schemeSelect: {
    maxWidth: 400,
  },
  irrigatorList: {
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    'ul&': {
      margin: '30px 0 10px',
      '@media(min-width: 800px)': {
        margin: '66px -60px 53px 0'
      }
    }
  },
  totals: {
    maxWidth: 800
  },
  history: {
    paddingBottom: 40,
  },
  saveButton: {
    width: 400,
    maxWidth: 'calc(100% - 10px)'
  },
})

const mapStateToProps = ({irrigators, irrigatorDailyEntries}) => ({
  ...irrigators,
  ...irrigatorDailyEntries,
  requests: [...irrigators.requests, ...irrigatorDailyEntries.requests]
})

export default compose(
  Dependent,
  SchemeView({path: 'request_approval'}),
  withStyles(styles),
  connectQueryString({customQueryParams: ['date']}),
  connect(mapStateToProps)
)(RequestApproval)
